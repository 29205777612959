import API from "../api/manager";
import config from "../api/config";

const getUsers = (params) => {
  return API({
    method: "GET",
    url: config.urls.user.unverifiedUser + params,
  });
};

const getUserDetails = () => {
  return API({
    method: "GET",
    url: config.urls.user.index + "/details",
  });
};

const verifyAccount = (data) => {
  return API({
    method: "POST",
    url: config.urls.user.verifiedClient,
    data,
  });
};

const getPaymentHistory = (params) => {
  return API({
    method: "GET",
    url: config.urls.user.index + `/payments${params}`,
  });
};

const getUserPayments = (params) => {
  return API({
    method: "GET",
    url: config.urls.user.index + `/payment_history${params}`,
  });
};

const updateUserProfile = (data) => {
  return API({
    method: "POST",
    url: config.urls.user.index + "/modify",
    data,
  });
};

const updatePassword = (data) => {
  return API({
    method: "POST",
    url: config.urls.user.index + "/update_password",
    data,
  });
};

const postPayments = (data) => {
  return API({
    method: "POST",
    url: config.urls.user.payments,
    data,
  });
};

export {
  getUsers,
  verifyAccount,
  getPaymentHistory,
  getUserPayments,
  updateUserProfile,
  getUserDetails,
  postPayments,
  updatePassword,
};
