import { Form, Formik } from "formik";
import React from "react";
import useLoader from "../../../hooks/useLoader";
import { useToast } from "../../../hooks/useToast";
import useUser from "../../../hooks/useUser";
import { updateUserProfile } from "../../../services/userServices";
import {
  getErrorMessage,
  replaceSplCharFromPhone,
} from "../../../_helper/functions";
import { updateProfile } from "../../../_helper/ValidationSchema";
import Button from "../../Common/Button";
import FormFields from "../../Common/FormFields/FormFields";

const inputs = [
  {
    type: "text",
    name: "name",
    placeholder: "Name",
    label: "Name",
  },
  {
    type: "text",
    name: "firm_name",
    placeholder: "Firm Name",
    label: "Firm Name",
  },
  {
    type: "email",
    name: "email",
    disabled: true,
    placeholder: "Email Address",
    label: "Email Address",
  },
  {
    type: "maskField",
    name: "contact_number",
    placeholder: "Contact Number",
    mask: "(999)-999-9999",
  },
  {
    type: "maskField",
    name: "ein",
    placeholder: "Employee Number(EIN)",
    mask: "**-*******",
  },
];

const MyProfileForm = ({ data = {} }) => {
  const toast = useToast();
  const { setToken } = useUser();
  const { setLoader } = useLoader();

  const onFormSubmit = async (values) => {
    try {
      setLoader({ state: true });
      const payload = {
        ...values,
        ein: replaceSplCharFromPhone(values?.ein),
        contact_number: replaceSplCharFromPhone(values?.contact_number),
        role: "client",
      };
      const result = await updateUserProfile(payload);
      if (result?.message) {
        toast("success", result?.message);
        setToken(result?.token);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };
  return (
    <Formik
      initialValues={{
        name: data?.name || "",
        firm_name: data?.firm_name || "",
        email: data?.email || "",
        contact_number: data?.contact_number || "",
        ein: data?.ein || "",
      }}
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={updateProfile}
      validateOnChange
    >
      {({ handleChange, errors, touched, values }) => (
        <Form className="h-full flex flex-col w-full">
          <div className="flex flex-col space-y-4">
            {inputs?.map((input, index) => (
              <div key={index}>
                <FormFields
                  {...{ ...input }}
                  value={values[input?.name]}
                  onChange={handleChange}
                  error={touched[input?.name] && errors[input?.name]}
                />
              </div>
            ))}
          </div>
          <div className="pt-6 flex flex-1 w-full items-end ">
            <Button
              type="submit"
              variant="primary-filled"
              className="px-4 py-2 w-full font-bold"
            >
              Update Profile
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MyProfileForm;
