import { FieldArray, Form, Formik } from "formik";
import React from "react";
import { formatToCurrency } from "../../../_helper/functions";
import Button from "../../Common/Button";
import FormFields from "../../Common/FormFields/FormFields";

const ApproveServeiceModal = ({ state }) => {
  return (
    <div>
      <div className="text-center text-xl font-bold">
        Approve Estimated Serives
      </div>
      <div className="py-2">
        <Formik
          initialValues={{
            services: [],
          }}
          enableReinitialize
          onSubmit={(values) => state.callBack(values)}
          validateOnChange
        >
          {({ values }) => (
            <Form className="h-full flex flex-col">
              <div className="flex flex-col">
                <FieldArray
                  name="services"
                  render={(arrayHelpers) => (
                    <>
                      {state?.services?.map((item, index) => (
                        <div key={index} className="py-2 flex items-center">
                          <FormFields
                            type="checkBox"
                            name="services"
                            id={`service_${index}`}
                            value={item}
                            checked={values.services.includes(item.uid)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                arrayHelpers.push(item.uid);
                              } else {
                                const idx = values.services.indexOf(item.uid);
                                arrayHelpers.remove(idx);
                              }
                            }}
                          />
                          <div className="flex text-sm font-medium pt-1 pl-2">
                            {item?.service_name} -{" "}
                            {`(${item?.estimated_time} ${
                              item?.estimated_time > 1 ? "hrs" : "hr"
                            })`}
                          </div>
                          <div className="flex text-sm font-medium pt-1 pl-2 flex-1 justify-end mt-0.5">
                            {formatToCurrency(item?.estimated_cost || 0)}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                />
              </div>
              <div className="pt-8 flex flex-1 w-full items-end ">
                <Button
                  type="submit"
                  variant="primary-filled"
                  className="px-4 py-2 w-full font-bold"
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ApproveServeiceModal;
