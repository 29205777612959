import API from "../api/manager";
import config from "../api/config";

const getAdmins = (params) => {
  return API({
    method: "GET",
    url: config.urls.user.adminIndex + params,
  });
};

const createAdmin = (data) => {
  return API({
    method: "POST",
    url: config.urls.user.adminIndex,
    data,
  });
};

const updataAdmin = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.user.adminIndex + `/${id}`,
    data,
  });
};

const updateAdminStatus = (id, data) => {
  return API({
    method: "PATCH",
    url: config.urls.user.adminIndex + `/${id}/update_status`,
    data,
  });
};

export { getAdmins, createAdmin, updataAdmin, updateAdminStatus };
