import React, { Fragment } from "react";
import { Dialog as Modal, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";

const Dialog = ({ open = false, onClose = () => {}, children }) => {
  const close = () => {
    setTimeout(() => {
      onClose();
    }, 350);
  };
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Modal
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={() => close()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Modal.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 relative"
                style={{ maxWidth: "70%", minWidth: "28rem" }}
              >
                {/* {children} */}
                <div className="absolute top-4 right-4">
                  <button onClick={() => close()}>
                    <XIcon className="h-6 w-6" />
                  </button>
                </div>
                {children}
              </div>
            </Transition.Child>
          </div>
        </Modal>
      </Transition.Root>
    </div>
  );
};

export default Dialog;
