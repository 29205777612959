import React from "react";
import useDialog from "../../../hooks/useDialog";
import Alert from "../../Common/Alert";
import Dialog from "../../Common/Dialog";
import AddAdmin from "../AddAdmin";
import AddDomain from "../AddDomain";
import AddEstimation from "../AddEstimation";
import AddFileUpload from "../AddFileUpload";
import AddService from "../AddService";
import ApproveServeiceModal from "../ApproveServiceModal";
import OfflinePayModal from "../OfflinePaymodal";
import RejectModal from "../RejectModal";
import ScheduleService from "../ScheduleService";
import ServiceCompletedModal from "../ServiceCompletedModal";

const DialogWrapper = () => {
  const { state, setState } = useDialog();
  return (
    <div>
      <Dialog open={!!state?.type} onClose={() => setState({})}>
        {state?.type === "ADD_ADMIN" && <AddAdmin state={state} />}
        {state?.type === "ADD_DOMAIN" && <AddDomain state={state} />}
        {state?.type === "ALERT" && <Alert data={state} />}
        {state?.type === "ADD_SERVICE" && <AddService state={state} />}
        {state?.type === "ADD_ESTIMATION" && <AddEstimation state={state} />}
        {state?.type === "SCHEDULE_DATE" && <ScheduleService state={state} />}
        {state?.type === "COMPLETE_MODAL" && (
          <ServiceCompletedModal state={state} />
        )}
        {state?.type === "ADD_FILES" && <AddFileUpload state={state} />}
        {state?.type === "APPROVE_SERVICE" && (
          <ApproveServeiceModal state={state} />
        )}
        {state?.type === "PAY_OFFLINE" && <OfflinePayModal state={state} />}
        {state?.type === "REJECT_REASON" && <RejectModal state={state} />}
      </Dialog>
    </div>
  );
};

export default DialogWrapper;
