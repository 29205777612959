import React from "react";

const Alert = ({ data }) => {
  return (
    <>
      <div className="sm:flex sm:items-start">
        {data?.icon && (
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <div className="h-6 w-6">{data?.icon}</div>
          </div>
        )}
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          {data?.title && (
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {data?.title}
            </h3>
          )}
          <div className="mt-2">
            <p className="text-sm text-gray-500">{data?.message}</p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        {data?.primaryBtn && (
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => data?.onSuccess()}
          >
            {data?.primaryBtn}
          </button>
        )}
        {data?.secondaryBtn && (
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => data?.onCancel()}
          >
            {data?.secondaryBtn}
          </button>
        )}
      </div>
    </>
  );
};

export default Alert;
