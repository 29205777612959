import moment from "moment";
import React, { useEffect, useState } from "react";

const ResetTimer = ({
  message = "Can’t find the email?",
  resend = () => {},
  timer = 60,
  resendMessage = "Resend verification email",
  defaultRunning = true,
}) => {
  const [count, setCount] = useState(timer);
  const [enableTimer, setEnableTimer] = useState(defaultRunning);

  // Effect for timer
  useEffect(() => {
    if (count === 0) {
      setEnableTimer(false);
    }
    const timer = setInterval(function () {
      setCount((prev) => prev - 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [count]);

  const resetTimer = () => {
    setCount(timer);
    setEnableTimer(true);
    resend();
  };

  return (
    <div className="text-base text-theme-blue-900 pt-1 flex flex-col sm:flex-row leading-7">
      <span>{message} </span>
      <span className="text-indigo-600 ml-1 cursor-pointer">
        {!enableTimer ? (
          <span onClick={resetTimer}>{resendMessage}</span>
        ) : (
          <span className="cursor-not-allowed">
            Resend in {moment.utc(count * 1000).format("mm:ss")}
          </span>
        )}
      </span>
    </div>
  );
};

export default ResetTimer;
