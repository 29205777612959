import React from "react";
import { SearchIcon } from "@heroicons/react/outline";

const SearchInput = ({
  placeholder = "Search",
  value = "",
  setValue = () => {},
}) => {
  return (
    <div className="flex items-center bg-white max-w-md w-full border border-gray-300 rounded">
      <div className="flex flex-1">
        <input
          className="text-sm px-4 py-2 w-full rounded"
          style={{ outline: "none" }}
          name="searchInput"
          value={value}
          onChange={(e) => setValue(e?.target?.value)}
          placeholder={placeholder}
        />
      </div>
      <div>
        <SearchIcon className="font-bold h-4 w-5 mr-4 text-gray-400" />
      </div>
    </div>
  );
};

export default SearchInput;
