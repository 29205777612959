import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Images } from "../../../assets/image_helper";
import useLoader from "../../../hooks/useLoader";
import useQuery from "../../../hooks/useQuery";
import { useToast } from "../../../hooks/useToast";
import { verifyAccount } from "../../../services/authServices";
import { getErrorMessage } from "../../../_helper/functions";
import { XCircleIcon } from "@heroicons/react/solid";
import useUser from "../../../hooks/useUser";

const UserVerification = ({ failedMessage, failedSubMessage }) => {
  const { setLoader } = useLoader();
  const query = useQuery();
  const toast = useToast();
  const history = useHistory();
  const [failed, setFailed] = useState(false);
  const { token, setToken } = useUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const tokenParam = query.get("token");

    async function accountVerification() {
      try {
        setLoading(true);
        setLoader({ state: true, message: "Verifying Account, pls Wait" });
        const payload = {
          verify_token: tokenParam,
        };
        const result = await verifyAccount(payload);
        if (result?.message) {
          toast("success", result?.message);
          setToken(result?.response?.token);
          setTimeout(() => {
            history.push("/cases");
          }, 0);
          setFailed(false);
        }
      } catch (err) {
        const message = getErrorMessage(err);
        toast("error", message);
        setFailed(true);
      } finally {
        setLoader({
          state: false,
        });
        setLoading(false);
      }
    }
    if (tokenParam) {
      accountVerification();
    } else {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoLogin = () => {
    if (token) {
      setToken("logged_out");
    }
    history.push("/client/login");
  };

  return (
    <div className="relative min-h-screen overflow-y-auto">
      <div
        className="absolute z-10 w-full h-full flex justify-center items-center bg-cover object-center p-4"
        style={{
          backgroundImage: `url(${Images.loginBg})`,
        }}
      >
        {!loading && failed && (
          <div className="p-8 shadow rounded max-w-md w-full bg-white">
            {/* <--Logo image --> */}
            <div className="flex justify-center">
              <XCircleIcon className="h-20 w-20 text-red-500" />
            </div>
            <div className="my-6 text-center flex  flex-col items-center justify-center">
              <div className="text-lg font-bold">{failedMessage}</div>
              {failedSubMessage && (
                <div className="text-sm text-gray-600 mt-6">
                  {failedSubMessage}
                </div>
              )}
            </div>
            <div
              className="text-center text-indigo-600 underline cursor-pointer"
              onClick={() => gotoLogin()}
            >
              Goto login
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserVerification;
