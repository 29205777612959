import React, { useEffect, useState, useRef } from "react";
import { ChevronLeftIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import Editor from "../../Common/Editor";
import { Field, FieldArray, Form, Formik } from "formik";
import FileUpload from "../../Fileupload";
import FormFields from "../../Common/FormFields/FormFields";
import Button from "../../Common/Button";
import {
  caseAttachment,
  checkIsUserVerified,
  createCase,
  getServices,
} from "../../../services/caseServices";
import {
  acceptFileType,
  getErrorMessage,
  uploadToAws,
} from "../../../_helper/functions";
import useLoader from "../../../hooks/useLoader";
import { createLegalCase } from "../../../_helper/ValidationSchema";
import { useToast } from "../../../hooks/useToast";
import { useHistory } from "react-router";
import { getSignedUrl } from "../../../services/commonServices";
import FileTypeImages from "../../Common/FileTypeImages";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";
import useDialog from "../../../hooks/useDialog";
import FocusError from "../../../hooks/useFocus";
import jwt_decode from "jwt-decode";
import useUser from "../../../hooks/useUser";

const CreateCase = () => {
  const [caseServiceList, setCaseServiceList] = useState([]);
  const { setLoader } = useLoader();
  const toast = useToast();
  const history = useHistory();
  const [files, setFiles] = useState([]);
  const { setState } = useDialog();
  const uploadRef = useRef();
  const [isAllowToCreate, setIsAllowtoCreate] = useState({});
  const { token, setToken } = useUser();

  useEffect(() => {
    if (token && token !== "logged_out") {
      const tokenObj = jwt_decode(token)?.claims;
      if (tokenObj?.account_verified_at) {
        getCaseServices();
        setIsAllowtoCreate({ allow_case_create: true });
      } else {
        checkVerifiedUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  async function getCaseServices() {
    setLoader({ state: true, message: "Loading services.." });
    try {
      const result = await getServices();
      if (result?.status === 200) {
        const serviceList = [];
        const defaultService = result?.response?.legal_cases?.filter(
          (el) => el.kind === "default"
        );
        const customService = result?.response?.legal_cases?.filter(
          (el) => el.kind === "additional" && !el.is_created_by_user
        );
        const serviceCreatedByUser = result?.response?.legal_cases?.filter(
          (el) => el.is_created_by_user
        );
        if (defaultService?.length) {
          serviceList.push({
            title: "Services Required",
            items: defaultService,
          });
        }
        if (customService?.length) {
          serviceList.push({
            title: "Additional Services",
            items: customService,
          });
        }
        if (serviceCreatedByUser?.length) {
          serviceList.push({
            title: "My Custom Services",
            items: serviceCreatedByUser,
          });
        }
        setCaseServiceList(serviceList);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
      setCaseServiceList([]);
    } finally {
      setLoader({ state: false });
    }
  }

  async function checkVerifiedUser() {
    try {
      setLoader({ state: true, message: "Loading.." });
      const res = await checkIsUserVerified();
      if (res?.status === 200) {
        getCaseServices();
        setToken(res?.token);
        setIsAllowtoCreate({ allow_case_create: true });
      }
    } catch (e) {
      setIsAllowtoCreate(e);
      if (e?.allow_case_create) {
        getCaseServices();
      }
      setLoader({ state: false });
    }
  }

  const getSignedUrlAndUpload = async (caseId, file, index) => {
    try {
      const url = await getSignedUrl(caseId, file);
      if (url?.response?.url) {
        return {
          url: url?.response?.url,
          file,
        };
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addAttachment = async (attachmentList = [], caseId = "") => {
    try {
      setLoader({ state: true, message: "Adding files to the cases..." });
      const payload = {
        attachable_type: "LegalCase",
        attachable_id: caseId,
        attachment_urls: attachmentList,
      };
      const result = await caseAttachment(payload);
      console.log(result);
      if (result) {
        toast("success", "Successfully attachments are added in this case");
        history.push(`/cases/${caseId}`);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  const triggerLoaderPercentage = () => {
    let overAllPercentage = 0;
    Object.keys(uploadRef.current).forEach((key) => {
      overAllPercentage += uploadRef.current[key];
    });
    setLoader((loader) => ({
      ...loader,
      percentage: Math.floor(overAllPercentage / files?.length),
    }));
  };

  const triggerFileUpload = async (url, file, index) => {
    try {
      const result = await uploadToAws(url, file, (event) => {
        const onProgressPrecentage =
          Math.floor((event.loaded * 100) / event.total) || 0;
        uploadRef.current[index] = 0;
        uploadRef.current[index] += onProgressPrecentage;
        triggerLoaderPercentage();
      });
      if (result) {
        return result;
      }
    } catch (err) {
      throw err;
    }
  };

  const onSubmit = async (values) => {
    if (values?.services?.length) {
      try {
        setLoader({ state: true, message: "Creating Case..." });
        uploadRef.current = {};
        const result = await createCase(values);
        if (result?.message) {
          toast("success", result?.message);
          if (files?.length) {
            setLoader({
              state: true,
              message: `Uploading ${files?.length} ${
                files?.length > 1 ? "Files" : "File"
              }...`,
              showPercentage: true,
            });
            const promises = []; //to store the promise value
            const fileUploadPromise = [];
            files.forEach((el) =>
              promises.push(getSignedUrlAndUpload(result?.response?.uid, el))
            );
            Promise.all(promises)
              .then((results) => {
                if (results.length) {
                  results.forEach((el, index) =>
                    fileUploadPromise.push(
                      triggerFileUpload(el?.url, el.file, index)
                    )
                  );
                  Promise.all(fileUploadPromise)
                    .then((res) => {
                      addAttachment(res, result?.response?.uid);
                      setLoader({ state: false });
                    })
                    .catch((err) => {
                      const message = getErrorMessage(err);
                      toast(message);
                      setLoader({ state: false });
                    });
                }
              })
              .catch((err) => {
                const message = getErrorMessage(err);
                toast(message);
                setLoader({ state: false });
              });
          }
          history.push(`/cases/${result?.response?.uid}`);
          setLoader({ state: false });
        }
      } catch (err) {
        const message = getErrorMessage(err);
        toast("error", message);
        setLoader({ state: false });
      }
    } else {
      toast("error", "Please select atleast one services");
    }
  };

  const addNewService = (arrayHelpers) => {
    setState({
      type: "ADD_SERVICE",
      callBack: (value) => {
        arrayHelpers.push(value);
        setState({});
      },
    });
  };

  return (
    <div className="w-full">
      {isAllowToCreate?.message && (
        <div className="w-full flex items-center">
          <div className="bg-red-500 space-x-2 mb-2 shadow rounded text-white text-sm flex text-center w-full h-full p-5">
            <div>
              <ExclamationCircleIcon className="w-5 h-5 text-white" />
            </div>
            <div> {isAllowToCreate?.message}</div>
          </div>
        </div>
      )}
      <div
        className={`bg-white shadow rounded relative ${
          isAllowToCreate?.allow_case_create ? "" : "cursor-not-allowed"
        }`}
      >
        {isAllowToCreate?.allow_case_create ? null : (
          <div className="absolute inset-0 bg-white opacity-70 z-10"></div>
        )}
        <div className="text-2xl font-semibold p-4 border border-r-0 border-l-0">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => history.goBack()}
          >
            <ChevronLeftIcon className="h-8 w-8 mr-2" />
            <div>New Legal Case</div>
          </div>
        </div>
        <Formik
          initialValues={{
            title: "",
            description: "",
            services: [],
            new_services: [],
          }}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={createLegalCase}
          validateOnChange
        >
          {({ handleChange, errors, touched, values }) => (
            <Form className="h-full p-6">
              <div className="flex space-x-6">
                <div className="flex flex-col flex-1">
                  <div className="py-4">
                    <FormFields
                      type="text"
                      name="title"
                      label="Case name"
                      placeholder="Case name"
                      value={values?.title}
                      onChange={handleChange}
                      error={touched["title"] && errors["title"]}
                    />
                  </div>
                  <div className="py-4">
                    <input
                      type="text"
                      className="opacity-0 h-0"
                      value={values.description}
                      name="description"
                    />
                    <Field name="description">
                      {({ field }) => (
                        <Editor
                          label="Case Overview"
                          value={values.description}
                          onChange={field?.onChange(field.name)}
                        />
                      )}
                    </Field>
                    {errors.description && touched.description && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors.description}
                      </p>
                    )}
                  </div>
                  <div className="py-4">
                    <div className="block text-sm font-medium text-gray-700 mb-1">
                      Add files
                    </div>
                    <div className="h-40">
                      <FileUpload
                        onUpload={(selectedFiles) => {
                          setFiles((files) => [...selectedFiles, ...files]);
                        }}
                        accept={acceptFileType}
                      />
                    </div>
                    <div className="py-4 flex flex-wrap">
                      <FileTypeImages {...{ files }} />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col flex-1">
                  <div className="my-4 border p-4 shadow rounded">
                    <FieldArray
                      name="services"
                      render={(arrayHelpers) => (
                        <>
                          {caseServiceList?.map((service, idx) => (
                            <div key={idx} className="pb-4">
                              <div className="font-semibold text-indigo-600 text-lg">
                                {service?.title}
                              </div>
                              {service?.items?.map((item, index) => (
                                <div
                                  key={index}
                                  className="py-2 flex items-center"
                                >
                                  <FormFields
                                    type="checkBox"
                                    name="services"
                                    id={`service_${index}`}
                                    value={item}
                                    checked={values.services.includes(item.id)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        arrayHelpers.push(item.id);
                                      } else {
                                        const idx = values.services.indexOf(
                                          item.id
                                        );
                                        arrayHelpers.remove(idx);
                                      }
                                    }}
                                  />
                                  <div className="flex text-sm font-medium pt-1 pl-2">
                                    {item?.name}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </>
                      )}
                    />
                    <FieldArray
                      name="new_services"
                      render={(arrayHelpers) => (
                        <>
                          {values?.new_services?.length ? (
                            <div className="pb-4">
                              <div className="font-semibold text-indigo-600 text-lg">
                                Other services
                              </div>
                              {values?.new_services?.map((item, index) => (
                                <div
                                  key={index}
                                  className="py-2 flex items-center"
                                >
                                  <div className="flex text-sm font-medium pl-2">
                                    <span className="font-bold mr-1">
                                      {index + 1}.
                                    </span>{" "}
                                    {item}
                                  </div>
                                  <div
                                    className="ml-4 cursor-pointer"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <TrashIcon className="h-5 w-5 text-red-600" />
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                          <div className="flex justify-end mt-4">
                            <Button
                              type="button"
                              variant="outline"
                              className="flex items-center py-1 px-4"
                              onClick={() => addNewService(arrayHelpers)}
                            >
                              <span>
                                <PlusCircleIcon className="h-6 w-6 mr-2" />
                              </span>
                              Add other service
                            </Button>
                          </div>
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-4 flex justify-end">
                <Button
                  type={"submit"}
                  variant="primary-filled"
                  className="w-52 py-2 px-4"
                >
                  Create Legal Case
                </Button>
              </div>
              <FocusError />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateCase;
