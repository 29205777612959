import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Images } from "../../../assets/image_helper";
import useLoader from "../../../hooks/useLoader";
import { useToast } from "../../../hooks/useToast";
import { resetPassword } from "../../../services/authServices";
import { getErrorMessage } from "../../../_helper/functions";
import ResetPasswordForm from "../../Internal/ResetPasswordForm";

const ResetPassword = () => {
  const { setLoader } = useLoader();
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();
  const [verifyToken, setVerifyToken] = useState("");
  const searchParam = new URLSearchParams(location.search);

  useEffect(() => {
    if (searchParam.get("token")) {
      setVerifyToken(searchParam.get("token"));
    } else {
      history.push("/client/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam]);

  const onSuccess = async (values) => {
    try {
      setLoader({ state: true, message: "Resetting password..." });
      const payload = {
        password: values?.password,
        verify_token: verifyToken,
      };
      const result = await resetPassword(payload);
      if (result?.message) {
        const isAdmin = jwtDecode(verifyToken)?.claims.role !== "client";
        toast("success", result?.message);
        isAdmin
          ? history.push("/support/login")
          : history.push("/client/login");
      }
    } catch (err) {
      const msg = getErrorMessage(err);
      toast("error", msg);
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <div className="relative min-h-screen overflow-y-auto">
      <div
        className="absolute z-10 w-full h-full flex justify-center items-center bg-cover object-center p-4"
        style={{
          backgroundImage: `url(${Images.loginBg})`,
        }}
      >
        <div className={`p-5 shadow rounded max-w-md w-full bg-white`}>
          {/* <--Logo image --> */}
          <div className="flex justify-center">
            <div className="w-full" style={{ maxWidth: 230 }}>
              <img src={Images.companyLogo} alt="company-logo" />
            </div>
          </div>
          <div className="py-4 flex space-x-4">
            {/* <!-- Login form--> */}
            <div className="flex flex-1 pt-4">
              <ResetPasswordForm onSuccess={onSuccess} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
