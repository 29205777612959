import { Route, Redirect } from "react-router-dom";

function PublicRoutes({ component: Component, role, ...rest }) {
  const token = localStorage.getItem("auth_token");
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        token ? <Redirect to="/cases" /> : <Component role={role} {...props} />
      }
    />
  );
}

export default PublicRoutes;
