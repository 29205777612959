import "./App.css";
import AppRouter from "./AppRouter";
import "./styles/main.css";
import "react-circular-progressbar/dist/styles.css";
import { ToastProvider } from "./context/ToastContext";
import ToastContainer from "./Components/Common/Toast/ToastContainer";
import DialogProvider from "./context/dialogContext";
import DialogWrapper from "./Components/Internal/DialogWrapper";
import UserProvider from "./context/userContext";
import LoaderProvider from "./context/LoaderContext";
import Loader from "./Components/Common/Loader";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

function App() {
  return (
    <div>
      <PayPalScriptProvider
        options={{
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          components: "buttons",
          currency: "USD",
        }}
      >
        <UserProvider>
          <ToastProvider>
            <LoaderProvider>
              <DialogProvider>
                <Loader />
                <AppRouter />
                <DialogWrapper />
              </DialogProvider>
            </LoaderProvider>
            <ToastContainer />
          </ToastProvider>
        </UserProvider>
      </PayPalScriptProvider>
    </div>
  );
}

export default App;
