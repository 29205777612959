export const status = [
  {
    value: "Awaiting Estimation",
    key: "received",
    completedState: "Estimated",
  },
  {
    value: "Awaiting Confirmation",
    key: "estimated",
    completedState: "Approved",
  },
  {
    value: "Awaiting Scheduling",
    key: "approved",
    completedState: "Scheduled",
  },
  {
    value: "Awaiting Completion",
    key: "processing",
    completedState: "Completed",
  },
];

export const service_status = [
  {
    label: "Received",
    value: "received",
  },
  {
    label: "Estimate",
    value: "estimated",
  },
  {
    label: "Approve",
    value: "approved",
  },
  {
    label: "Schedule",
    value: "processing",
  },
  {
    label: "Complete",
    value: "completed",
  },
];
