import { Form, Formik } from "formik";
import React from "react";
import { forgotPasswordSchema } from "../../../_helper/ValidationSchema";
import Button from "../../Common/Button";
import FormFields from "../../Common/FormFields/FormFields";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";

const inputs = [
  {
    type: "email",
    name: "email",
    placeholder: "Email Address",
  },
];

const ForgotPasswordForm = ({
  onBacktoLogin = () => {},
  onSuccess = () => {},
}) => {
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      enableReinitialize
      onSubmit={onSuccess}
      validationSchema={forgotPasswordSchema}
      validateOnChange
    >
      {({ handleChange, errors, touched, values }) => (
        <Form className="h-full flex flex-col w-full">
          <div className="flex flex-col space-y-4">
            {inputs?.map((input, index) => (
              <div key={index}>
                <FormFields
                  {...{ ...input }}
                  value={values[input?.name]}
                  onChange={handleChange}
                  error={touched[input?.name] && errors[input?.name]}
                />
              </div>
            ))}
          </div>
          <div className="pt-6 flex flex-1 w-full items-end">
            <Button
              type="submit"
              variant="primary-filled"
              className="px-4 py-2 w-full font-bold"
            >
              Send me reset password instructions
            </Button>
          </div>
          <div
            className="mt-4 flex items-center text-sm text-indigo-600 cursor-pointer font-semibold"
            onClick={onBacktoLogin}
          >
            <div className="mr-1">
              <ArrowNarrowLeftIcon className="text-current h-4 w-5" />
            </div>
            <div>Back to login</div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
