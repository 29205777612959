import { Form, Formik } from "formik";
import React from "react";
import { ResetPasswordSchema } from "../../../_helper/ValidationSchema";
import Button from "../../Common/Button";
import FormFields from "../../Common/FormFields/FormFields";

const loginInputs = [
  {
    type: "password",
    name: "password",
    placeholder: "New password",
    label: "New password",
  },
  {
    type: "password",
    name: "confirmPassword",
    placeholder: "Confirm Password",
    label: "Confirm Password",
  },
];

function ResetPasswordForm({ onSuccess = () => {} }) {
  return (
    <div className="bg-gray-100 rounded border border-gray-200 p-6 w-full h-full">
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        enableReinitialize
        onSubmit={onSuccess}
        validationSchema={ResetPasswordSchema}
        validateOnChange
      >
        {({ handleChange, errors, touched, values }) => (
          <Form className="h-full flex flex-col">
            <div className="flex flex-col space-y-4">
              {loginInputs?.map((input, index) => (
                <div key={index}>
                  <FormFields
                    {...{ ...input }}
                    value={values[input?.name]}
                    onChange={handleChange}
                    error={touched[input?.name] && errors[input?.name]}
                  />
                </div>
              ))}
            </div>
            <div className="py-4 flex flex-1 w-full items-end ">
              <Button
                type="submit"
                variant="primary-filled"
                className="px-4 py-2 w-full font-bold"
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ResetPasswordForm;
