import React from "react";
import { Images } from "../../../assets/image_helper";
import { CheckCircleIcon } from "@heroicons/react/solid";
import useUser from "../../../hooks/useUser";
import { useHistory } from "react-router";

const PendingAccountVerification = () => {
  const { setToken, token } = useUser();
  const history = useHistory();
  const gotoLogin = () => {
    if (token) {
      setToken("logged_out");
    }
    history.push("/client/login");
  };
  return (
    <div className="relative min-h-screen overflow-y-auto">
      <div
        className="absolute z-10 w-full h-full flex justify-center items-center bg-cover object-center p-4"
        style={{
          backgroundImage: `url(${Images.loginBg})`,
        }}
      >
        <div className="p-8 shadow rounded max-w-md w-full bg-white">
          {/* <--Logo image --> */}
          <div className="flex justify-center">
            <CheckCircleIcon className="h-20 w-20 text-green-500" />
          </div>
          <div className="my-6 text-center flex  flex-col items-center justify-center">
            <div className="text-lg font-bold">
              Your Email has been verified but waiting approval.
            </div>
            <div className="text-sm text-gray-600 mt-6">
              Thank you for your interest in being featured on MedzLegal. We
              will send you the email once our admin Review your profile
            </div>
          </div>
          <div
            className="text-center text-indigo-600 underline cursor-pointer"
            onClick={() => gotoLogin()}
          >
            Goto login
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingAccountVerification;
