import React from "react";
import {
  PencilIcon,
  LockClosedIcon,
  LockOpenIcon,
} from "@heroicons/react/solid";

const AdminList = ({
  loading = false,
  data = {},
  onPerformAction = () => {},
  status,
}) => {
  if (loading) {
    return (
      <div className="flex items-center flex-1 w-full border-b bg-white shadow rounded animate-pulse">
        <div className="flex flex-col item-center p-4 w-1/3">
          <div className="h-4 w-32 bg-gray-200 rounded"></div>
          <div className="h-4 w-28 rounded bg-gray-200 mt-2"></div>
        </div>
        <div className="w-2/5 flex flex-col item-center p-4">
          <div className="h-4 w-36 bg-gray-200 rounded"></div>
          <div className="h-4 w-32 rounded bg-gray-200 mt-2"></div>
        </div>
        <div className="flex flex-1 w-1/3  justify-end items-center space-x-4 px-4">
          <div className="h-10 w-10 bg-indigo-200 rounded-full"></div>
          <div className="h-10 w-10 bg-red-200 rounded-full"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex items-center flex-1 w-full border-b bg-white shadow rounded">
      <div className="flex flex-col item-center p-4 w-1/3">
        <div className="text-gray-400 text-xs font-medium">User Name:</div>
        <div className="text-sm font-bold">{data?.name}</div>
      </div>
      <div className="w-2/5 flex flex-col item-center p-4">
        <div className="text-gray-400 text-xs font-medium"> Email:</div>
        <div className="text-sm font-bold truncate break-words">
          <a href={`mailto:${data?.email}`}>{data?.email}</a>
        </div>
      </div>
      <div className="flex flex-1 w-1/3 justify-end items-center space-x-4 px-4">
        <div
          className="flex justify-center items-center h-10 w-10 bg-indigo-600 text-white rounded-full cursor-pointer"
          onClick={() => onPerformAction("EDIT")}
        >
          <PencilIcon className="text-current h-6 w-6" />
        </div>
        <div
          className={`flex justify-center items-center h-10 w-10 ${
            status === "active" ? "bg-red-600" : "bg-green-600"
          } text-white rounded-full cursor-pointer`}
          onClick={() => onPerformAction("ACTION")}
        >
          {status === "active" ? (
            <LockClosedIcon className="text-current h-6 w-6" />
          ) : (
            <LockOpenIcon className="text-current h-6 w-6" />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminList;
