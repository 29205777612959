import { Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router";
import useLoader from "../../../hooks/useLoader";
import { useToast } from "../../../hooks/useToast";
import useUser from "../../../hooks/useUser";
import { signUp } from "../../../services/authServices";
import {
  getErrorMessage,
  replaceSplCharFromPhone,
} from "../../../_helper/functions";
import { signupSchema } from "../../../_helper/ValidationSchema";
import Button from "../../Common/Button";
import FormFields from "../../Common/FormFields/FormFields";
import FocusError from "../../../hooks/useFocus";

const inputs = [
  {
    type: "text",
    name: "name",
    placeholder: "Name",
  },
  {
    type: "text",
    name: "firm_name",
    placeholder: "Firm Name",
  },
  {
    type: "email",
    name: "email",
    placeholder: "Email Address",
  },
  {
    type: "maskField",
    name: "contact_number",
    placeholder: "Contact Number",
    mask: "(999)-999-9999",
  },
  {
    type: "maskField",
    name: "ein",
    placeholder: "Employee Number(EIN)",
    mask: "**-*******",
  },
  {
    type: "password",
    name: "password",
    placeholder: "Password",
  },
  {
    type: "password",
    name: "confirmPassword",
    placeholder: "Confirm Password",
  },
];

const SignupForm = ({ onSuccess = () => {} }) => {
  const history = useHistory();
  const toast = useToast();
  const { setToken } = useUser();
  const { setLoader } = useLoader();

  const onFormSubmit = async (values, { resetForm }) => {
    try {
      setLoader({ state: true });
      const payload = {
        ...values,
        ein: replaceSplCharFromPhone(values?.ein),
        contact_number: replaceSplCharFromPhone(values?.contact_number),
        role: "client",
      };
      const result = await signUp(payload);
      if (result?.message) {
        toast("success", result?.message);
        setToken(result?.response?.token);
        onSuccess();
        resetForm();
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        firm_name: "",
        email: "",
        contact_number: "",
        ein: "",
        password: "",
        confirmPassword: "",
        agreement: false,
      }}
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={signupSchema}
      validateOnChange
    >
      {({ handleChange, errors, touched, values }) => (
        <Form className="h-full flex flex-col w-full">
          <div className="flex flex-col space-y-4">
            {inputs?.map((input, index) => (
              <div key={index}>
                <FormFields
                  {...{ ...input }}
                  value={values[input?.name]}
                  onChange={handleChange}
                  error={touched[input?.name] && errors[input?.name]}
                />
              </div>
            ))}
          </div>
          <div className="flex items-center mt-3">
            <FormFields
              type="checkBox"
              name={`agreement`}
              id={"agreement"}
              onChange={handleChange}
            />
            <div className="ml-2 mt-1 text-sm">
              <label
                htmlFor={"agreement"}
                className="font-medium text-gray-700"
              >
                <span>I agree to</span>{" "}
                <a
                  href="https://www.medzlegal.co/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-600 no-underline cursor-pointer"
                >
                  Terms and Condition
                </a>
              </label>
            </div>
          </div>
          <div className="pt-6 flex flex-1 w-full items-end ">
            <Button
              disabled={!values?.agreement}
              type="submit"
              variant="primary-filled"
              className="px-4 py-2 w-full font-bold"
            >
              Sign up
            </Button>
          </div>
          <div className="text-center text-sm mt-2">
            I already have an account{" "}
            <span
              className="text-indigo-600 cursor-pointer hover:underline"
              onClick={() => history.push("/")}
            >
              Click Here
            </span>
          </div>
          <FocusError />
        </Form>
      )}
    </Formik>
  );
};

export default SignupForm;
