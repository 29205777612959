import React, { useEffect, useState } from "react";
import Pagination from "../../Common/Pagination";
import SearchInput from "../../Common/SearchInput";
import PaymentList from "../../Internal/PaymentList";
import queryString from "query-string";
import NoResult from "../../Common/NoResult";
import { useDebounce } from "use-debounce";
import { getPaymentHistory } from "../../../services/userServices";
import Tabs from "../../Common/Tabs";

const AdminPaymentHistory = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = [
    {
      name: "Pending Payments",
      page: <UserPaymentHistory />,
    },
    {
      name: "Completed Payments",
      page: <UserPaymentHistory status={"paid"} />,
    },
  ];
  return (
    <>
      <div className="text-2xl font-bold uppercase mb-4 pt-1">
        Payments History
      </div>
      <Tabs
        onTabChanges={(index) => setSelectedIndex(index)}
        selectedTabIndex={selectedIndex}
        tabs={tabs}
      />
    </>
  );
};

export default AdminPaymentHistory;

const UserPaymentHistory = ({ status = "pending" }) => {
  const [payments, setPayments] = useState([]);
  const [currentpage, setCurrentPage] = useState(1);
  const [pageDetails, setPageDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const [text] = useDebounce(value, 1000);

  useEffect(() => {
    async function getAllPayment() {
      setLoading(true);
      try {
        let params = {
          page: currentpage,
          per_page: 10,
          payment_status: status,
        };
        if (text) {
          params.term = text;
        }
        const stringParams = `?${queryString.stringify(params)}`;
        const result = await getPaymentHistory(stringParams);
        if (result?.response && result?.response?.users?.length) {
          setPayments(result?.response?.users);
        }
        if (result?.response?.pagination_details) {
          setPageDetails(result?.response?.pagination_details);
        }
      } catch (err) {
        console.log(err);
        setPayments([]);
        setPageDetails({});
      } finally {
        setLoading(false);
      }
    }
    getAllPayment();
  }, [currentpage, text, status]);

  return (
    <Pagination
      pagination={{
        currentpage: currentpage,
        totalPage: pageDetails?.total_pages,
        handlePageClick: ({ selected }) => {
          setCurrentPage(selected + 1);
        },
      }}
    >
      <div
        className="flex justify-between absolute w-full right-5 top-16"
        style={{ maxWidth: 448 }}
      >
        <div className="flex flex-1 justify-end">
          <SearchInput {...{ value, setValue }} />
        </div>
      </div>
      <div className="mt-5 flex flex-col space-y-4">
        {loading ? (
          <>
            {[1, 2, 3, 4, 5, 6]?.map((el) => (
              <div key={el}>
                <PaymentList loading={true} />
              </div>
            ))}
          </>
        ) : (
          <>
            {payments?.length ? (
              <>
                {payments?.map((el, index) => (
                  <div key={index}>
                    <PaymentList data={el} isPaidView={status === "paid"} />
                  </div>
                ))}
              </>
            ) : (
              <div className="flex justify-center my-10">
                <div className="max-w-xl w-full">
                  <NoResult message="No Payments Found!" />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Pagination>
  );
};
