import React, { useEffect, useState } from "react";
import Button from "../../Common/Button/Button";
import ExpansionPanel from "../../Common/ExpansionPanel/ExpansionPanel";
import PercentageCard from "../../Common/PercentageCard.js";
import ProgressStats from "../../Common/ProgressStats";
import moment from "moment";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router";
import { ChatAlt2Icon } from "@heroicons/react/solid";
import _ from "lodash";

const CaseList = ({ loading = false, data = {}, performAction }) => {
  const [expand, setExpand] = useState(false);
  const history = useHistory();
  const [token, setToken] = useState();
  useEffect(() => {
    const store = localStorage.getItem("auth_token");
    if (store && store !== "logged_out") {
      setToken(jwt_decode(store));
    }
  }, []);
  return (
    <ExpansionPanel
      {...{ expand, setExpand, loading }}
      header={<Header {...{ loading, data, token }} />}
    >
      {!loading && (
        <>
          <div className="grid grid-cols-2 py-4 px-8 gap-4">
            {data.case_services?.map((el, index) => (
              <div
                key={index}
                className="w-full px-4 cursor-pointer"
                onClick={() =>
                  history.push(`/cases/${data?.uid}?serviceId=${el?.uid}`)
                }
              >
                <PercentageCard
                  percentage={el?.completion_percentage || 0}
                  status={_.startCase(el?.status)}
                  title={el?.service_name}
                  showActionBtn={
                    el?.is_deletion_requested &&
                    token?.claims?.role !== "client"
                  }
                  performAction={(value) => performAction(value, el?.uid)}
                />
              </div>
            ))}
          </div>
          <div className="flex py-4 px-8 justify-end">
            <div>
              <Button
                variant="primary-filled"
                onClick={() => history.push(`/cases/${data?.uid}`)}
              >
                View Case
              </Button>
            </div>
          </div>
        </>
      )}
    </ExpansionPanel>
  );
};

const Header = ({ loading = false, data = {}, token }) => {
  if (loading) {
    return (
      <div className="flex items-center flex-1 w-full border-b animate-pulse">
        <div
          className="w-full flex justify-center p-4"
          style={{ maxWidth: "12.5%" }}
        >
          <div className="w-16 h-16 bg-gray-400 rounded-full"></div>
        </div>
        <div
          className="flex flex-col item-center p-4 w-full"
          style={{ maxWidth: "12.5%" }}
        >
          <div className="text-gray-400 text-xs font-medium h-4 w-24 bg-gray-200 rounded mb-2"></div>
          <div className="text-sm font-bold h-3 w-20 bg-gray-200 rounded"></div>
        </div>
        <div
          className="w-full flex flex-col item-center p-4"
          style={{ maxWidth: "12.5%" }}
        >
          <div className="text-gray-400 text-xs font-medium h-4 w-24 bg-gray-200 rounded mb-2"></div>
          <div className="text-sm font-bold h-3 w-20 bg-gray-200 rounded"></div>
        </div>
        <div
          className="w-full flex flex-col item-center p-4"
          style={{ maxWidth: "17%" }}
        >
          <div className="text-gray-400 text-xs font-medium h-4 w-24 bg-gray-200 rounded mb-2"></div>
          <div className="text-sm font-bold overflow-hidden truncate h-3 w-20 bg-gray-200 rounded"></div>
        </div>
        <div
          className="w-full flex flex-col item-center p-4"
          style={{
            maxWidth: "17%",
          }}
        >
          <div className="text-gray-400 text-xs font-medium h-4 w-24 bg-gray-200 rounded mb-2"></div>
          <div className="text-sm font-bold h-3 w-20 bg-gray-200 rounded"></div>
        </div>
        <div
          className="w-full flex flex-col item-center p-4"
          style={{
            maxWidth: "17%",
          }}
        >
          <div className="text-gray-400 text-xs font-medium h-4 w-24 bg-gray-200 rounded mb-2"></div>
          <div className="text-sm font-bold h-3 w-20 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex items-center flex-1 w-full border-b">
      <div
        className="w-full flex justify-center p-4"
        style={{ maxWidth: "12.5%" }}
      >
        <div className="w-16 h-16">
          <ProgressStats percentage={data?.percentage_completed || 0} />
        </div>
      </div>
      <div
        className="flex flex-col item-center p-4 w-full"
        style={{ maxWidth: "12.5%" }}
      >
        <div className="text-gray-400 text-xs font-medium">Created date:</div>
        <div className="text-sm font-bold">
          {data?.created_at
            ? moment(data.created_at).format("MM/DD/YYYY")
            : "-"}
        </div>
      </div>
      <div
        className="w-full flex flex-col item-center p-4"
        style={{ maxWidth: "12.5%" }}
      >
        <div className="text-gray-400 text-xs font-medium"> Unique ID:</div>
        <div className="text-sm font-bold">{data?.uid}</div>
      </div>
      <div
        className="w-full flex flex-col item-center p-4"
        style={{ maxWidth: "15.5%" }}
      >
        <div className="text-gray-400 text-xs font-medium">Case Name:</div>
        <div
          className="text-sm font-bold overflow-hidden truncate"
          title={data?.title}
        >
          {data?.title}
        </div>
      </div>
      <div
        className="w-full flex flex-col item-center p-4"
        style={{ maxWidth: "17%" }}
      >
        <div
          className={`text-gray-400 text-xs font-medium ${
            token?.claims?.role === "client" ? "text-center" : ""
          }`}
        >
          {token?.claims?.role !== "client" ? "Firm Name:" : "Ongoing services"}
        </div>
        <div
          className={`text-sm font-bold ${
            token?.claims?.role === "client" ? "text-center" : ""
          }`}
          title={
            token?.claims?.role !== "client"
              ? data?.firm_name || token?.claims?.firm_name || "-"
              : data?.ongoing_cases_count || 0
          }
        >
          {token?.claims?.role !== "client"
            ? data?.firm_name || token?.claims?.firm_name || "-"
            : data?.ongoing_cases_count || 0}
        </div>
      </div>
      <div
        className="w-full flex flex-col item-center p-4"
        style={{ maxWidth: "17%" }}
      >
        <div
          className={`text-gray-400 text-xs font-medium ${
            token?.claims?.role === "client" ? "text-center" : ""
          }`}
        >
          {token?.claims?.role !== "client"
            ? " User Name:"
            : "Completed services"}
        </div>
        <div
          className={`text-sm font-bold ${
            token?.claims?.role === "client" ? "text-center" : ""
          }`}
          title={
            token?.claims?.role !== "client"
              ? data?.user_name || token?.claims?.name || "-"
              : data?.completed_cases_count || 0
          }
        >
          {token?.claims?.role !== "client"
            ? data?.user_name || token?.claims?.name || "-"
            : data?.completed_cases_count || 0}
        </div>
      </div>
      <div
        className="w-full flex flex-col item-center p-4"
        style={{ maxWidth: "5%" }}
      >
        {data?.unread_comments ? (
          <div className="relative w-10 h-10">
            <ChatAlt2Icon className="w-full h-full text-indigo-600" />
            <div className="absolute -right-2 -top-2 bg-red-600 h-6 w-6 flex justify-center items-center text-sm text-white rounded-full">
              {data?.unread_comments}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CaseList;
