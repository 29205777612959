import React from "react";
import useLoader from "../../../hooks/useLoader";

const Loader = () => {
  const { loader } = useLoader();

  if (!loader?.state) return null;

  return (
    <div
      className={`fixed bottom-0 inset-x-0 px-4 pb-4
     inset-0 flex flex-col items-center justify-center bg-black bg-opacity-70 z-50`}
    >
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2 ml-3"></div>
        <div className="bounce3 ml-3"></div>
      </div>
      <div className="mx-auto py-4 text-sm text-center text-white">
        {loader.message ? loader.message : "Loading..."}
      </div>
      {loader?.showPercentage && (
        <>
          <div className="relative max-w-md w-full h-5 border border-white rounded text-white">
            <div
              className="absolute transition-all duration-200 h-full bg-white"
              style={{ width: `${loader?.percentage}%` }}
            ></div>
          </div>
          <div className="text-white text-lg w-full text-center">
            {loader?.percentage || 0}%
          </div>
        </>
      )}
    </div>
  );
};

export default Loader;
