import Dropzone from "react-dropzone";
import useDialog from "../../hooks/useDialog";

const FileUpload = ({
  onUpload = () => {},
  accept = "image/*",
  hasPopupConfirmation = true,
}) => {
  const { setState } = useDialog();

  const handleDrop = (acceptedFiles) => {
    if (hasPopupConfirmation) {
      setState({
        type: "ADD_FILES",
        files: acceptedFiles,
        callBack: (files) => {
          onUpload(files);
          setState({});
        },
      });
    } else {
      onUpload(acceptedFiles);
    }
  };

  return (
    <Dropzone onDrop={handleDrop} accept={accept}>
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
      }) => {
        const additionalClass = isDragAccept
          ? "accept"
          : isDragReject
          ? "reject"
          : "";

        return (
          <div
            {...getRootProps({
              className: `dropzone ${additionalClass}`,
            })}
          >
            <input {...getInputProps()} />
            <span>{isDragActive ? "📂" : "📁"}</span>
            <p>Drag'n'drop images, or click to select files</p>
          </div>
        );
      }}
    </Dropzone>
  );
};

export default FileUpload;
