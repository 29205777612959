import {
  ExclamationCircleIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/solid";
import React, { useState } from "react";
import Select from "react-select";
import InputMask from "react-input-mask";

const FormFields = ({
  type = "",
  name = "",
  placeholder = "",
  value = "",
  label = "",
  error = "",
  options = [],
  isRtl = true,
  isLoading = false,
  isClearable = false,
  isSearchable = false,
  isMulti = false,
  onChange = () => {},
  minDate,
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const [showCapsWarning, setShowCapsWarning] = useState(false);
  const inputClass = (isError) => {
    if (isError) {
      return `block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md`;
    }
    return `shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`;
  };

  const onkeydown = (e) => {
    if (type === "password" && e.getModifierState("CapsLock")) {
      setShowCapsWarning(true);
    } else {
      setShowCapsWarning(false);
    }
  };

  const renderIcon = (type) => {
    if (type === "password") {
      return (
        <div
          className="absolute inset-y-0 right-0 pr-3 flex z-10 items-center cursor-pointer"
          onClick={() => setShow(!show)}
        >
          {show ? (
            <EyeIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          ) : (
            <EyeOffIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          )}
        </div>
      );
    }
    return null;
  };

  const renderInput = (type) => {
    switch (type) {
      case "checkBox":
        return (
          <input
            {...{ ...rest }}
            id={name}
            aria-describedby={name}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          />
        );
      case "textArea":
        return (
          <textarea
            {...{ ...rest }}
            id={name}
            aria-describedby={name}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            rows="4"
            className="focus:ring-indigo-500 w-full border-gray-300 rounded"
          ></textarea>
        );
      case "select":
        return (
          <>
            <Select
              className="select"
              classNamePrefix="select"
              isMulti={isMulti}
              value={value}
              // defaultValue={value}
              {...{ ...rest }}
              isLoading={isLoading}
              isClearable={isClearable}
              isRtl={isRtl}
              isSearchable={isSearchable}
              name={name}
              onChange={onChange}
              options={options}
            />
          </>
        );
      case "maskField":
        return (
          <>
            <div className="mt-1 relative rounded-md shadow-sm">
              <InputMask
                maskChar={null}
                {...{ ...rest }}
                type={"text"}
                name={name}
                id={name}
                value={value}
                className={inputClass(error)}
                onChange={onChange}
                placeholder={placeholder}
              />
              {error && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {error && <Errormsg msg={error} />}
          </>
        );
      default:
        return (
          <div>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                {...{ ...rest }}
                type={show ? "text" : type}
                name={name}
                id={name}
                value={value}
                className={inputClass(error)}
                onChange={onChange}
                onKeyDown={onkeydown}
                placeholder={placeholder}
              />
              {error ? (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                renderIcon(type)
              )}
            </div>
            {showCapsWarning && <Errormsg msg={"Caps Lock is On"} />}
            {error && <Errormsg msg={error} />}
          </div>
        );
    }
  };
  return (
    <div>
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      {renderInput(type)}
    </div>
  );
};

export default FormFields;

const Errormsg = ({ msg }) => {
  return <p className="mt-2 text-sm text-red-600">{msg}</p>;
};
