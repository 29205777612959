import React from "react";
import { Images } from "../../../assets/image_helper";

const NoResult = ({ message = "No Results Found" }) => {
  return (
    <div>
      <img
        src={Images.noResultFound}
        alt="no-result-found"
        className="h-full w-full"
      />
      <div className="text-3xl text-gray-400 font-bold text-center my-10">
        {message}
      </div>
    </div>
  );
};

export default NoResult;
