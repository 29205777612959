import React, { useEffect, useRef, useState } from "react";
import { ChevronUpIcon } from "@heroicons/react/solid";

const ExpansionPanel = ({
  expand = false,
  header = <></>,
  children,
  setExpand = () => {},
  loading = false,
}) => {
  const ref = useRef();
  const [height, setHeight] = useState();

  useEffect(() => {
    if (expand) {
      setHeight(ref.current.offsetHeight + "px");
    } else {
      setHeight("0px");
    }
  }, [expand, ref?.current?.offsetHeight]);

  useEffect(() => {
    setExpand(expand);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand]);

  return (
    <div className="w-full bg-white rounded-lg shadow-sm border border-gray-300">
      {/* <!-- Header Section --> */}
      <div
        className="flex items-center cursor-pointer relative"
        onClick={() => {
          setExpand(!expand);
        }}
        aria-hidden="true"
      >
        {header}
        {!loading && (
          <div className="absolute right-5 flex items-center h-full">
            <div
              className={`transition-all duration-500 transform ${
                !expand ? "rotate-180" : ""
              }`}
            >
              <ChevronUpIcon className="h-6 w-6 text-indigo-600" />
            </div>
          </div>
        )}
      </div>
      {/* <!-- Content Section --> */}
      <div
        className={`${
          expand ? "my-4" : ""
        } transition-all overflow-hidden duration-200 ease-linear`}
        style={{
          height: height,
        }}
      >
        <div
          ref={ref}
          className={`${
            !expand ? "opacity-50" : ""
          } transition-all duration-200`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ExpansionPanel;
