import { Form, Formik } from "formik";
import React from "react";
import { scheduleServiceSchema } from "../../../_helper/ValidationSchema";
import Button from "../../Common/Button/Button";
import FormFields from "../../Common/FormFields/FormFields";
import moment from "moment";

const inputs = [
  {
    type: "date",
    name: "estimated_date",
    placeholder: "Enter the estimated date",
    label: "Estimated Date",
    min: moment().format("yyyy-MM-DD"),
  },
  {
    type: "textArea",
    name: "note",
    placeholder: "Notes",
    label: "Notes",
  },
];

const ScheduleService = ({ state }) => {
  return (
    <div>
      <div className="text-center text-xl font-bold">Update Details</div>
      <div className="py-2">
        <Formik
          initialValues={{
            estimated_date: "",
            note: "",
          }}
          enableReinitialize
          onSubmit={(values) => state.callBack(values)}
          validationSchema={scheduleServiceSchema}
          validateOnChange
        >
          {({ handleChange, errors, touched, values }) => (
            <Form className="h-full flex flex-col">
              <div className="flex flex-col space-y-4">
                {inputs?.map((input, index) => (
                  <div key={index}>
                    <FormFields
                      {...{ ...input }}
                      onChange={handleChange}
                      value={values[input?.name]}
                      error={touched[input?.name] && errors[input?.name]}
                    />
                  </div>
                ))}
              </div>
              <div className="pt-8 flex flex-1 w-full items-end ">
                <Button
                  type="submit"
                  variant="primary-filled"
                  className="px-4 py-2 w-full font-bold"
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ScheduleService;
