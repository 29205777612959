import { PlusIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce/lib";
import useDialog from "../../../hooks/useDialog";
import Button from "../../Common/Button/Button";
import SearchInput from "../../Common/SearchInput";
import BlockedList from "../../Internal/BlockedList";
import queryString from "query-string";
import {
  addBlockData,
  deleteBlockData,
  getBlokedList,
} from "../../../services/BlockServices";
import Pagination from "../../Common/Pagination";
import NoResult from "../../Common/NoResult";
import { getErrorMessage } from "../../../_helper/functions";
import { useToast } from "../../../hooks/useToast";
import useLoader from "../../../hooks/useLoader";

const BlockedEmails = () => {
  const { setState } = useDialog();
  const [blockedList, setBlockedList] = useState([]);
  const [currentpage, setCurrentPage] = useState(1);
  const [pageDetails, setPageDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const [text] = useDebounce(value, 1000);
  const toast = useToast();
  const { setLoader } = useLoader();

  useEffect(() => {
    getBlockedList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentpage, text]);

  async function getBlockedList() {
    setLoading(true);
    try {
      let params = {
        page: currentpage,
        per_page: 10,
      };
      if (text) {
        params.term = text;
      }
      const stringParams = `?${queryString.stringify(params)}`;
      const result = await getBlokedList(stringParams);
      if (result?.response) {
        setBlockedList(result?.response?.blacklists);
      }
      if (result?.response?.pagination_details) {
        setPageDetails(result?.response?.pagination_details);
      }
    } catch (err) {
      console.log(err);
      setBlockedList([]);
      setPageDetails({});
    } finally {
      setLoading(false);
    }
  }

  const addDomain = async (values) => {
    try {
      setLoader({ state: true, message: "Adding block date to the list..." });
      const payload = {};
      if (values?.email) {
        payload.kind = "email";
        payload.component = values?.email;
      } else {
        payload.kind = "domain";
        payload.component = values?.domain;
      }
      const result = await addBlockData(payload);
      if (result) {
        toast("success", result?.message);
        setState({});
        getBlockedList();
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  const showRemoveAlert = (data) => {
    setState({
      type: "ALERT",
      message: `Are you sure, you want to delete this(${data?.component}) data?`,
      primaryBtn: "Yes",
      secondaryBtn: "No",
      onSuccess: () => {
        onRemove(data?.id);
      },
      onCancel: () => {
        setState({});
      },
      title: "Delete Confirmation",
    });
  };

  const onRemove = async (id) => {
    try {
      setLoader({ state: true, message: "Deleting block date to the list..." });
      const result = await deleteBlockData(id);
      if (result) {
        toast("success", result?.message);
        setState({});
        getBlockedList();
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <div>
      <Pagination
        pagination={{
          currentpage: currentpage,
          totalPage: pageDetails?.total_pages,
          handlePageClick: ({ selected }) => {
            setCurrentPage(selected + 1);
          },
        }}
      >
        {/* actiotn bar */}
        <div className="flex justify-between space-x-4 items-center">
          <div className="text-2xl font-bold uppercase">Blacklisted emails</div>
          <div className="flex flex-1 justify-end">
            <SearchInput {...{ value, setValue }} />
          </div>
        </div>
        <div className="mt-5">
          {loading ? (
            <div className="grid grid-cols-3 gap-4">
              {[1, 2, 3, 4, 5, 6]?.map((el, index) => (
                <div key={index}>
                  <BlockedList loading={true} />
                </div>
              ))}
            </div>
          ) : (
            <>
              <>
                {blockedList?.length ? (
                  <div className="grid grid-cols-3 gap-4">
                    {blockedList?.map((el, index) => (
                      <div key={index}>
                        <BlockedList
                          data={el}
                          onRemove={() => showRemoveAlert(el)}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex justify-center my-10">
                    <div className="max-w-xl w-full">
                      <NoResult message="No Blacklisted Domain/Mail Found!" />
                    </div>
                  </div>
                )}
              </>
            </>
          )}
        </div>
        <div className="fixed bottom-8 right-8">
          <Button
            animate={true}
            variant="primary-filled"
            className="h-16 px-6 flex items-center text-sm justify-center rounded-full uppercase hover:shadow"
            onClick={() =>
              setState({
                type: "ADD_DOMAIN",
                callBack: (values) => addDomain(values),
              })
            }
          >
            <PlusIcon className="h-5 w-5 text-white text-current mr-2" />
            Add Email or Domain
          </Button>
        </div>
      </Pagination>
    </div>
  );
};

export default BlockedEmails;
