import React, { useState } from "react";
import Button from "../../Common/Button";
import FormFields from "../../Common/FormFields/FormFields";

const AddService = ({ state = () => {} }) => {
  const [value, setValue] = useState("");
  return (
    <div>
      <div className="text-center text-xl font-bold">New Service</div>
      <div className="py-2">
        <div className="flex flex-col space-y-4">
          <div>
            <FormFields
              type="text"
              label="Service Name"
              value={value}
              onChange={(e) => setValue(e?.target?.value)}
            />
          </div>
        </div>
        <div className="pt-8 flex flex-1 w-full items-end ">
          <Button
            type="button"
            variant="primary-filled"
            disabled={!value?.trim()}
            className="px-4 py-2 w-full font-bold"
            onClick={() => state.callBack(value.trim())}
          >
            Create New Service
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddService;
