import React, { Fragment, useEffect, useRef, useState } from "react";
import _ from "lodash";
import useLoader from "../../../hooks/useLoader";
import { getErrorMessage } from "../../../_helper/functions";
import { useToast } from "../../../hooks/useToast";
import {
  getComments,
  postComment,
  readCommad,
} from "../../../services/caseServices";
import moment from "moment";
import useUser from "../../../hooks/useUser";
import jwt_decode from "jwt-decode";
import { Images } from "../../../assets/image_helper";
import { ChatAlt2Icon } from "@heroicons/react/solid";

const ContactUsCard = ({ caseDetails = {} }) => {
  const [selectedService, setSelectedService] = useState();
  const [comments, setComments] = useState([]);
  const { setLoader } = useLoader();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [msg, setMsg] = useState("");
  const { token } = useUser();
  const chatContainerRef = useRef();
  const [triggerTobtm, setTriggerTobtm] = useState(false);
  const [commentCount, setCommentCount] = useState({});

  useEffect(() => {
    if (chatContainerRef?.current && triggerTobtm) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
      setTriggerTobtm(false);
    }
  }, [triggerTobtm, chatContainerRef]);

  useEffect(() => {
    if (caseDetails?.case_services?.length) {
      setSelectedService(caseDetails?.case_services[0]);
      const counts = caseDetails?.case_services?.reduce((pre, cur) => {
        pre[cur?.uid] = cur?.unread_comments;
        return pre;
      }, {});
      setCommentCount(counts);
    }
  }, [caseDetails?.case_services]);

  async function getServiceComments() {
    try {
      setLoading(true);
      const result = await getComments(selectedService?.uid);
      if (result) {
        setComments(result?.comments);
        setTriggerTobtm(true);
        if (result?.unread_comments) {
          triggerReadComment(selectedService?.uid);
        }
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
      setComments([]);
    } finally {
      setLoading(false);
    }
  }

  const triggerReadComment = async (id) => {
    try {
      setLoading(true);
      const result = await readCommad(id);
      if (result?.message) {
        const tempCount = { ...commentCount };
        tempCount[id] = 0;
        setCommentCount(tempCount);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(selectedService)) {
      getServiceComments(selectedService?.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService]);

  const addComment = async (msg) => {
    try {
      setLoader({ state: true, message: "adding Comment" });
      const payload = {
        content: msg,
      };
      const result = await postComment(selectedService?.uid, payload);
      if (result?.message) {
        toast("success", result.message);
        getServiceComments(selectedService?.uid);
        setMsg("");
      }
    } catch (err) {
      const msg = getErrorMessage(err);
      toast("error", msg);
    } finally {
      setLoader({ state: false });
    }
  };

  const setPosition = ({ is_user_client }) => {
    const role = jwt_decode(token)?.claims?.role;
    if (role === "admin" || role === "super_admin") {
      return is_user_client ? "left" : "right";
    } else if (role === "client") {
      return is_user_client ? "right" : "left";
    }
  };

  return (
    <div className="rounded shadow w-full bg-white text-sm p-4">
      <div className="font-bold pt-2 pb-4">Chat with us</div>
      <div
        className="flex drop-shadow-2xl border rounded"
        style={{ height: 400 }}
      >
        {/* Left Pannel */}
        <div className="w-2/5 border-r">
          {/* serviceList */}
          {caseDetails?.case_services?.map((service, index) => (
            <div
              className={`p-4 font-bold text-base border-b cursor-pointer hover:bg-black hover:bg-opacity-10 flex items-center ${
                service?.uid === selectedService?.uid
                  ? "bg-indigo-100 text-indigo-600"
                  : ""
              }`}
              key={index}
              onClick={() => setSelectedService(service)}
            >
              <div className="flex flex-1">{service?.service_name}</div>
              {commentCount[service?.uid] ? (
                <div className="relative w-6 h-6">
                  <ChatAlt2Icon className="w-full h-full text-indigo-600" />
                  <div className="absolute -right-1 -top-2 bg-red-600 text-xs text-white rounded-full">
                    <div className="px-1">
                      {commentCount[service?.uid] < 10
                        ? commentCount[service?.uid]
                        : "10+"}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
        <div className="flex-1 relative">
          <div
            ref={chatContainerRef}
            className="p-6 overflow-auto chat-list flex flex-col flex-no-wrap flex-1"
            style={{ height: "calc(100% - 71px)" }}
          >
            {loading ? (
              <>
                {[1, 2, 4, 5]?.map((el) => (
                  <div key={el}>
                    <ChatBubble
                      position={el % 2 === 0 ? "right" : "left"}
                      isLoading={true}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {comments?.length ? (
                  <>
                    {comments?.map((comment, index) => (
                      <div key={index}>
                        <ChatBubble
                          position={
                            token &&
                            token !== "logged_out" &&
                            setPosition(comment)
                          }
                          message={comment?.content}
                          date={moment(new Date(comment?.created_at)).format(
                            "DD MMM YY"
                          )}
                          author={
                            comment?.is_user_client
                              ? comment?.user_name
                              : "MedzLegal Team"
                          }
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex items-center justify-center">
                    <div className="w-full" style={{ maxWidth: 280 }}>
                      <img
                        src={Images.noResultFound}
                        alt="no-result-found"
                        className="h-full w-full"
                      />
                      <div className="text-base text-gray-400 font-bold text-center my-2">
                        No Messages Found! Type message to start the
                        conversation
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="w-full flex absolute bottom-0 border-t">
            <div className="w-full flex border">
              <textarea
                rows="2"
                name="msg"
                className="w-full h-full resize-none border-none shadow-none"
                placeholder="Enter the message"
                value={msg}
                style={{
                  outline: "none",
                  boxShadow: "none",
                  outlineOffset: "0px",
                }}
                onChange={(e) => setMsg(e?.target?.value)}
              />
              <div className="mx-4 mb-1 w-24 flex items-center">
                <button
                  className={`w-full p-2 bg-indigo-600 rounded-lg border text-white ${
                    !msg?.trim()
                      ? "cursor-not-allowed bg-opacity-50"
                      : "hover:bg-opacity-80"
                  }`}
                  onClick={() => msg?.trim() && addComment(msg)}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsCard;

const ChatBubble = ({
  date,
  message,
  position,
  author = "",
  isLoading = false,
  ...rest
}) => {
  if (isLoading) {
    return (
      <div className="animate-pulse">
        {position === "left" ? (
          <div {...rest} className="flex flex-row mb-8">
            <div className="flex flex-1 flex-col items-start">
              <div
                className={`bg-gray-200 break-all rounded-2xl rounded-br-2xl h-10 w-1/3`}
                style={{ maxWidth: "75%" }}
              ></div>
            </div>
          </div>
        ) : (
          <div {...rest} className="flex flex-col items-end mb-4">
            <div
              className={`bg-gray-200 break-all rounded-2xl rounded-br-2xl h-10 w-1/3`}
              style={{ maxWidth: "75%" }}
            ></div>
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      {position === "left" ? (
        <div {...rest} className="flex flex-row mb-8">
          <div className="flex flex-1 flex-col items-start">
            <div
              className={`bg-indigo-100 break-all rounded-t-2xl rounded-br-2xl py-3 px-5 text-indigo-600 mb-1  text-sm`}
              style={{ maxWidth: "75%" }}
            >
              {message}
            </div>
            <span className="text-xs text-gray-500 ml-1">
              by {author} at {date}
            </span>
          </div>
        </div>
      ) : (
        <div {...rest} className="flex flex-col items-end mb-4">
          <div
            className={`bg-indigo-600 break-all rounded-t-2xl rounded-bl-2xl py-3 px-5 text-indigo-100 mb-1 text-sm`}
            style={{ maxWidth: "75%" }}
          >
            {message}
          </div>
          <span className="text-xs text-gray-500 ml-1">{date}</span>
        </div>
      )}
    </>
  );
};
