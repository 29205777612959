import { Form, Formik } from "formik";
import React from "react";
import useLoader from "../../../hooks/useLoader";
import { useToast } from "../../../hooks/useToast";
import { updatePassword } from "../../../services/userServices";
import { getErrorMessage } from "../../../_helper/functions";
import { updatePasswordSchema } from "../../../_helper/ValidationSchema";
import Button from "../../Common/Button";
import FormFields from "../../Common/FormFields/FormFields";

const inputs = [
  {
    type: "password",
    name: "current_password",
    placeholder: "Current Password",
    label: "Current Password",
  },
  {
    type: "password",
    name: "new_password",
    placeholder: "New Password",
    label: "New Password",
  },
  {
    type: "password",
    name: "confirm_password",
    placeholder: "Confirm Password",
    label: "Confirm Password",
  },
];

const UpdatePasswordForm = ({ data = {} }) => {
  const toast = useToast();
  const { setLoader } = useLoader();

  const onFormSubmit = async (values, { resetForm }) => {
    try {
      setLoader({ state: true });
      const result = await updatePassword(values);
      if (result?.message) {
        toast("success", result?.message);
        resetForm();
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };
  return (
    <Formik
      initialValues={{
        current_password: "",
        new_password: "",
        confirm_password: "",
      }}
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={updatePasswordSchema}
      validateOnChange
    >
      {({ handleChange, errors, touched, values }) => (
        <Form className="h-full flex flex-col w-full">
          <div className="flex flex-col space-y-4">
            {inputs?.map((input, index) => (
              <div key={index}>
                <FormFields
                  {...{ ...input }}
                  value={values[input?.name]}
                  onChange={handleChange}
                  error={touched[input?.name] && errors[input?.name]}
                />
              </div>
            ))}
          </div>
          <div className="pt-6 flex flex-1 w-full items-end ">
            <Button
              type="submit"
              variant="primary-filled"
              className="px-4 py-2 w-full font-bold"
            >
              Update password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdatePasswordForm;
