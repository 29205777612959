import React from "react";
import ProgressStats from "../ProgressStats";

const PercentageCard = ({
  percentage,
  title = "",
  status = "",
  showActionBtn,
  performAction = () => {},
}) => {
  return (
    <div className="shadow rounded-lg flex border">
      {/* <!--Progress stats--> */}
      <div className="p-4 border-r">
        <div className="w-16 h-16">
          <ProgressStats {...{ percentage }} />
        </div>
      </div>
      <div className="p-4">
        <div className="mb-4 font-bold text-sm">{title}</div>
        <div className="text-gray-400 text-sm">{status}</div>
      </div>
      {showActionBtn && (
        <div className="p-4 flex flex-1 flex-col items-end justify-between">
          <div className="mb-2">
            <button
              type="button"
              className="w-16 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1 bg-green-600 text-xs font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={(e) => {
                performAction(true);
                e.stopPropagation();
              }}
            >
              Approve
            </button>
          </div>
          <div>
            <button
              type="button"
              className="w-16 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1 bg-red-600 text-xs font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={(e) => {
                performAction(false);
                e.stopPropagation();
              }}
            >
              Reject
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PercentageCard;
