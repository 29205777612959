import { Form, Formik } from "formik";
import React, { useState } from "react";
import { paymentFormSchema } from "../../../_helper/ValidationSchema";
import Button from "../../Common/Button";
import FormFields from "../../Common/FormFields/FormFields";
import moment from "moment";

const OfflinePayModal = ({ state }) => {
  const onFormSubmit = (values) => {
    state.callBack(values);
  };

  const [selectedPayment, setSelectedPayment] = useState("invoice");

  const fields = [
    {
      type: "text",
      name: "paymentId",
      placeholder: `${
        selectedPayment === "invoice" ? "Enter Payment ID" : "Cheque Number"
      }`,
      label: `${
        selectedPayment === "invoice" ? "Enter Payment ID" : "Cheque Number"
      }`,
    },
    {
      type: "number",
      name: "paymentAmount",
      placeholder: `${
        selectedPayment === "invoice" ? "Enter Payment amount" : "Cheque amount"
      }`,
      label: `${
        selectedPayment === "invoice" ? "Enter Payment amount" : "Cheque amount"
      }`,
    },
    {
      type: "number",
      name: "payable_amount",
      placeholder: "Payable amount",
      label: "Payable amount",
      disabled: true,
    },
    {
      type: "date",
      name: "transaction_date",
      placeholder: "Enter the trasaction date",
      label: "Trasaction Date",
      max: moment().format("yyyy-MM-DD"),
    },
  ];

  return (
    <div>
      <div className="text-center text-xl font-bold">Payment Details</div>
      <div className="py-2">
        <Formik
          initialValues={{
            paymentId: "",
            paymentAmount: "",
            payable_amount: state?.totalAmount || 0,
            transaction_date: "",
          }}
          enableReinitialize
          onSubmit={(values) => {
            const data = {
              ...values,
              mode: selectedPayment,
            };
            onFormSubmit(data);
          }}
          validationSchema={paymentFormSchema}
          validateOnChange
        >
          {({ handleChange, errors, touched, values }) => (
            <Form className="h-full flex flex-col">
              <div className="flex flex-col space-y-4">
                <div className="flex space-x-4 pt-4">
                  <div class="flex items-center">
                    <input
                      id="radio1"
                      type="radio"
                      class="hidden"
                      onChange={() => setSelectedPayment("invoice")}
                      checked={selectedPayment === "invoice"}
                    />
                    <label
                      for="radio1"
                      class="flex items-center cursor-pointer"
                    >
                      <span class="w-4 h-4 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                      Invoice
                    </label>
                  </div>

                  <div class="flex items-center">
                    <input
                      id="radio2"
                      type="radio"
                      class="hidden"
                      onChange={() => setSelectedPayment("cheque")}
                      checked={selectedPayment === "cheque"}
                    />
                    <label
                      for="radio2"
                      class="flex items-center cursor-pointer"
                    >
                      <span class="w-4 h-4 inline-block mr-2 rounded-full border border-grey flex-no-shrink"></span>
                      Cheque
                    </label>
                  </div>
                </div>
                {fields?.map((input, index) => (
                  <div key={index}>
                    <FormFields
                      {...{ ...input }}
                      value={values[input?.name]}
                      onChange={handleChange}
                      error={touched[input?.name] && errors[input?.name]}
                    />
                  </div>
                ))}
              </div>
              <div className="pt-8 flex flex-1 w-full items-end ">
                <Button
                  type="submit"
                  variant="primary-filled"
                  className="px-4 py-2 w-full font-bold"
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default OfflinePayModal;
