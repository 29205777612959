import React, { useEffect, useState, useRef } from "react";
import ProgressStats from "../../Common/ProgressStats";
import { TrashIcon } from "@heroicons/react/outline";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/solid";
import FormFields from "../../Common/FormFields/FormFields";
import { service_status, status } from "../../../_helper/constants";
import {
  acceptFileType,
  formatToCurrency,
  getErrorMessage,
  setStatusNotes,
  uploadToAws,
} from "../../../_helper/functions";
import Button from "../../Common/Button";
import moment from "moment";
import FileUpload from "../../Fileupload";
import { useToast } from "../../../hooks/useToast";
import useLoader from "../../../hooks/useLoader";
import { getSignedUrl } from "../../../services/commonServices";
import FileTypeImages from "../../Common/FileTypeImages";
import {
  adminRejectUpdate,
  caseAttachment,
  caseRemoveAttachment,
  deleteService,
} from "../../../services/caseServices";
import useDialog from "../../../hooks/useDialog";

const ServicesCard = ({
  data = {},
  caseId = "",
  role = "",
  onSelectChange = () => {},
  onCaseRefresh = () => {},
  ableTodelele = false,
  isAdmin = false,
}) => {
  const toast = useToast();
  const { setLoader } = useLoader();
  const [files, setFiles] = useState([]);
  const { setState } = useDialog();
  const uploadRef = useRef();

  useEffect(() => {
    if (data?.attachments?.length) {
      setFiles(data?.attachments);
    }
  }, [data]);

  const triggerLoaderPercentage = (selectedFiles) => {
    let overAllPercentage = 0;
    Object.keys(uploadRef.current).forEach((key) => {
      overAllPercentage += uploadRef.current[key];
    });
    setLoader((loader) => ({
      ...loader,
      percentage: Math.floor(overAllPercentage / selectedFiles?.length),
    }));
  };

  const appendClass = (index) => {
    const statusIndex = status?.findIndex((el) => el?.key === data?.status);
    if (index === statusIndex) {
      return {
        textColor: "text-indigo-600",
        bgColor: "bg-gray-200",
      };
    }
    return {
      textColor: "text-black",
      bgColor: "bg-white",
    };
  };

  const onRemove = async (index) => {
    if (files[index]?.id) {
      try {
        setLoader({ state: true, message: "Removing file to the cases..." });
        const payload = {
          attachment_id: files[index]?.id,
        };
        const result = await caseRemoveAttachment(payload);
        if (result?.status === 200) {
          const operatedFiles = [...files];
          operatedFiles.splice(index, 1);
          setFiles(operatedFiles);
          toast("success", result?.message);
        }
      } catch (err) {
        const message = getErrorMessage(err);
        toast("error", message);
      } finally {
        setLoader({ state: false });
      }
    }
  };

  const getSignedUrlAndUpload = async (file, index) => {
    try {
      const path = `${caseId}/${data?.uid}`;
      const url = await getSignedUrl(path, file);
      if (url?.response?.url) {
        return {
          url: url?.response?.url,
          file,
        };
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addAttachment = async (attachmentList = [], selectedFiles = []) => {
    try {
      setLoader({ state: true, message: "Adding files to the cases..." });
      const payload = {
        attachable_type: "CaseService",
        attachable_id: data?.uid,
        attachment_urls: attachmentList,
      };
      const result = await caseAttachment(payload);
      console.log(result);
      if (result) {
        toast("success", "Successfully attachments are added in this case");
        onCaseRefresh();
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  const triggerFileUpload = async (url, file, index, selectedFiles) => {
    try {
      const result = await uploadToAws(url, file, (event) => {
        const onProgressPrecentage =
          Math.floor((event.loaded * 100) / event.total) || 0;
        uploadRef.current[index] = 0;
        uploadRef.current[index] += onProgressPrecentage;
        triggerLoaderPercentage(selectedFiles);
      });
      if (result) {
        return result;
      }
    } catch (err) {
      throw err;
    }
  };

  const uploadFile = (selectedFiles = []) => {
    setLoader({
      state: true,
      message: `Uploading ${selectedFiles?.length} ${
        selectedFiles?.length > 1 ? "Files" : "File"
      }...`,
      showPercentage: true,
    });
    uploadRef.current = {};
    const promises = []; //to store the promise value
    const fileUploadPromise = [];
    selectedFiles.forEach((el) => promises.push(getSignedUrlAndUpload(el)));
    Promise.all(promises)
      .then((results) => {
        if (results.length) {
          results.forEach((el, index) =>
            fileUploadPromise.push(
              triggerFileUpload(el?.url, el.file, index, selectedFiles)
            )
          );
          Promise.all(fileUploadPromise)
            .then((res) => {
              addAttachment(res, files);
              setLoader({ state: false });
            })
            .catch((err) => {
              const message = getErrorMessage(err);
              toast(message);
              setLoader({ state: false });
            });
        }
      })
      .catch((err) => {
        const message = getErrorMessage(err);
        toast(message);
        setLoader({ state: false });
      });
  };

  const deleteServiceTriggered = async () => {
    try {
      setLoader({ state: true, message: "Deleting services" });
      const result = await deleteService(data?.uid);
      if (result?.message) {
        toast("success", result?.message);
        onCaseRefresh();
      }
    } catch (err) {
      const msg = getErrorMessage(err);
      toast("error", msg);
    } finally {
      setLoader({ state: false });
      setState({});
    }
  };

  const triggerDeleteService = () => {
    setState({
      type: "ALERT",
      onSuccess: () => deleteServiceTriggered(),
      onCancel: () => setState({}),
      primaryBtn: "Yes",
      secondaryBtn: "No",
      title: "Delete Confirmation",
      message: "Are you sure, you want to delete this services?",
    });
  };

  const triggerAction = async (isApproved, serviceId, value) => {
    try {
      setLoader({ state: true, message: "Updating status" });
      const payload = {
        case_service_id: serviceId,
        status: isApproved ? "accepted" : "rejected",
      };
      if (value) {
        payload.rejection_reason = value;
      }
      const result = await adminRejectUpdate(payload);
      if (result?.message) {
        toast("success", result?.message);
        setState({});
        onCaseRefresh();
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setState({});
      setLoader({ state: false });
    }
  };

  const performAction = (isApproved, id) => {
    if (isApproved) {
      setState({
        type: "ALERT",
        message: "Are you sure, you want to approve this request?",
        primaryBtn: "Approve",
        secondaryBtn: "Cancel",
        onSuccess: () => triggerAction(isApproved, id, ""),
        onCancel: () => {
          setState({});
        },
        title: "Alert",
      });
    } else {
      setState({
        type: "REJECT_REASON",
        callBack: (value) => {
          triggerAction(isApproved, id, value);
        },
      });
    }
  };

  return (
    <div className="rounded shadow w-full bg-white text-sm">
      {/* over all details Details */}
      <div className="border-b">
        <div className="flex items-center flex-1 w-full">
          <div
            className="w-full flex justify-center p-4"
            style={{ maxWidth: "12.5%" }}
          >
            <div className="w-16 h-16">
              <ProgressStats percentage={data?.completion_percentage} />
            </div>
          </div>
          <div className="p-4 flex flex-1 text-lg font-bold">
            {data?.service_name}
          </div>
          <div className="flex p-4 flex-1">
            <span className="text-xs text-gray-500">
              Estimated Cost :{" "}
              <span className="text-sm font-bold text-black">
                {" "}
                {data?.estimated_cost
                  ? `${formatToCurrency(data?.estimated_cost)}`
                  : "-"}
              </span>
            </span>
          </div>
          <div className="flex p-4 flex-1">
            <span className="text-xs text-gray-500">
              Estimated Time :{" "}
              <span className="text-sm font-bold text-black">
                {" "}
                {data?.estimated_time
                  ? data?.estimated_time > 1
                    ? `${data?.estimated_time} hrs`
                    : `${data?.estimated_time} hr`
                  : "-"}
              </span>
            </span>
          </div>
          <div className={`flex flex-1 justify-end p-4`}>
            {role === "client" ? (
              <>
                {data?.status === "estimated" && (
                  <>
                    <Button
                      variant="primary-filled"
                      className="py-2 w-20"
                      onClick={() =>
                        onSelectChange(
                          {
                            label: "Approve",
                            value: "approved",
                          },
                          data
                        )
                      }
                    >
                      Confirm
                    </Button>
                    {/* <Button
                      variant="error-filled"
                      className="py-2 w-20 ml-2"
                      onClick={() =>
                        onSelectChange(
                          {
                            label: "Reject",
                            value: "rejected",
                          },
                          data
                        )
                      }
                    >
                      Reject
                    </Button> */}
                  </>
                )}
              </>
            ) : (
              <div className="w-36">
                <FormFields
                  type="select"
                  name="status"
                  onChange={(e) => onSelectChange(e, data)}
                  options={service_status}
                  isRtl={false}
                  value={service_status?.find(
                    (el) => el?.value === data?.status
                  )}
                  isSearchable={false}
                  isClearable={false}
                />
              </div>
            )}
          </div>
          {ableTodelele && (
            <>
              {!data?.deletion_requested ? (
                <div className="flex p-4">
                  <TrashIcon
                    className="font-bold h-8 w-8 cursor-pointer"
                    onClick={() => triggerDeleteService()}
                  />
                </div>
              ) : (
                <>
                  {isAdmin ? (
                    <div className="p-4 flex flex-1 flex-col items-end justify-between">
                      <div className="mb-2">
                        <button
                          type="button"
                          className="w-28 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1 bg-green-600 text-xs font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          onClick={(e) => performAction(true, data?.uid)}
                        >
                          Approve{" "}
                          <TrashIcon className="w-4 h-4 pl-0.5 text-white" />
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="w-28 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-1 bg-red-600 text-xs font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          onClick={(e) => performAction(false, data?.uid)}
                        >
                          Reject{" "}
                          <TrashIcon className="w-4 h-4 pl-0.5 text-white" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="text-xs px-4 font-bold text-red-600 w-32">
                      Delete request Raised
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {/* Service status flow */}
      <div className="p-4">
        <div className="border rounded flex">
          <div className="max-w-md w-full">
            {status?.map((state, index) => (
              <div
                key={index}
                className={`flex items-center border-separate px-7 py-4 ${
                  appendClass(index).bgColor
                } ${index !== status?.length - 1 ? "border-b" : ""}`}
              >
                {index + 1 >
                  status?.findIndex((el) => el?.key === data?.status) &&
                data?.status !== "completed" &&
                data?.status !== "paid" ? (
                  <MinusCircleIcon className="text-gray-300 text-xl font-extrabold h-6 w-6 mr-3.5" />
                ) : (
                  <CheckCircleIcon className="text-green-600 text-xl font-extrabold h-6 w-6 mr-3.5" />
                )}

                <div
                  className={`font-bold text-base ${
                    appendClass(index).textColor
                  }`}
                >
                  {index + 1 >
                    status?.findIndex((el) => el?.key === data?.status) &&
                  data?.status !== "completed" &&
                  data?.status !== "paid"
                    ? state?.value
                    : state?.completedState}
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-1 flex-col bg-gray-200 p-4">
            {data?.status === "processing" && (
              <div className="border-b border-gray-400 border-opacity-60 mb-2 py-4 font-bold text-center">
                <span className="text-gray-400 text-xs">
                  Estimated Completion Date:
                </span>{" "}
                {moment(new Date(data?.estimated_date)).format("MM/DD/YYYY")}
              </div>
            )}
            {/* Notes section */}
            <div className="flex flex-col flex-1 text-sm">
              <div className="font-bold">Notes</div>
              <div className="py-1">
                {setStatusNotes(data?.case_service_status?.note, data?.status)}
              </div>
            </div>
            <div className="border-t border-gray-400 border-opacity-60 flex justify-between w-full pt-4">
              {/* user name section */}
              <div className="flex items-center">
                {/* avatar */}
                <div className="h-10 w-10 rounded mr-4">
                  <img
                    src="https://ui-avatars.com/api/?name=MedzLegal Team&background=0852FF&color=fff"
                    alt="avatar"
                    className="w-full h-full rounded"
                  />
                </div>
                <div>
                  <div className="font-bold">{"MedzLegal Team"}</div>
                  <div className="mt-1">Support</div>
                </div>
              </div>

              {/* Created Data */}
              <div className="font-medium">
                {moment(data?.case_service_status?.created_at).format(
                  "MMMM DD, YYYY [at] hh:mm A"
                )}{" "}
                {/* August 28, 2021 at 01:40 PM{" "} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Completed files*/}
      {(data?.status === "completed" || data?.status === "paid") && (
        <div className="p-4">
          {(files?.length && role === "client") ||
            (role !== "client" && (
              <div className="font-bold">Completed Files:</div>
            ))}
          <div className="py-4 flex flex-col">
            {role !== "client" && (
              <div className="w-full max-w-2xl h-32">
                <FileUpload
                  accept={acceptFileType}
                  onUpload={(selectedFiles) => {
                    uploadFile(selectedFiles);
                  }}
                />
              </div>
            )}
            {/* Added file section */}
            <div className="w-full">
              {files?.length ? (
                <div className="py-4 flex flex-wrap">
                  <FileTypeImages
                    onRemove={(index) => onRemove(index)}
                    files={files?.map((el) => {
                      return {
                        name: el?.file_name || el?.name,
                        url: el?.signed_url || "",
                      };
                    })}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServicesCard;
