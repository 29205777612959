import React, { useEffect, useState } from "react";
import {
  adminRejectUpdate,
  getCases,
  getCaseStasts,
  getDeleteReqCases,
} from "../../../services/caseServices";
import Pagination from "../../Common/Pagination";
import SearchInput from "../../Common/SearchInput";
import CaseList from "../../Internal/CaseList";
import queryString from "query-string";
import NoResult from "../../Common/NoResult";
import { useDebounce } from "use-debounce";
import Button from "../../Common/Button/Button";
import { PlusIcon } from "@heroicons/react/outline";
import { useHistory, useLocation } from "react-router";
import jwt_decode from "jwt-decode";
import useUser from "../../../hooks/useUser";
import Tabs from "../../Common/Tabs";
import useDialog from "../../../hooks/useDialog";
import { getErrorMessage } from "../../../_helper/functions";
import { useToast } from "../../../hooks/useToast";
import useLoader from "../../../hooks/useLoader";

const Cases = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [caseTabs, setCaseTabs] = useState([]);
  const [stats, setStats] = useState({});
  const { token } = useUser();
  const history = useHistory();
  const location = useLocation();
  const [triggerCount, setTriggerCount] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchParam = new URLSearchParams(location.search);
  const tabs = [
    {
      name: "All Cases",
      key: "all_cases",
      page: <CaseTab />,
    },
    {
      name: "Estimated Cases",
      key: "estimated_cases",
      page: <CaseTab status={"estimated"} />,
    },
    {
      name: "Ongoing Cases",
      key: "ongoing_cases",
      page: <CaseTab status={"ongoing"} />,
    },
    {
      name: "Unread Comments",
      key: "unread_comments",
      page: <CaseTab status={"unread_comments"} />,
    },
    {
      name: "Delete Requests",
      key: "service_delete_requests",
      page: <CaseTab status={"deletion_requests"} {...{ setTriggerCount }} />,
    },
  ];

  useEffect(() => {
    if (token && token !== "logged_out" && triggerCount) {
      const userObj = jwt_decode(token)?.claims;
      if (userObj?.role === "client") {
        const filterMenu = tabs?.filter(
          (el) =>
            el?.name !== "Unread Comments" && el?.name !== "Delete Requests"
        );
        setCaseTabs(filterMenu);
      } else {
        setCaseTabs(tabs);
      }
      getCaseCounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, triggerCount]);

  useEffect(() => {
    if (searchParam?.get("tabIndex")) {
      setSelectedIndex(Number(searchParam?.get("tabIndex")));
    } else {
      setSelectedIndex(0);
    }
  }, [searchParam]);

  async function getCaseCounts() {
    try {
      const res = await getCaseStasts();
      if (res?.response) {
        setStats(res?.response?.cases_count);
        console.log(res);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTriggerCount(false);
    }
  }

  const onTabChange = (index) => {
    history.push(`/cases?tabIndex=${index}`);
  };

  return (
    <>
      <div className="text-2xl font-bold uppercase mb-4 pt-1">Cases</div>
      {!caseTabs?.length ? null : (
        <Tabs
          onTabChanges={onTabChange}
          selectedTabIndex={selectedIndex}
          tabs={caseTabs}
          stats={stats}
        />
      )}
    </>
  );
};

const CaseTab = ({ status, setTriggerCount }) => {
  const [cases, setCases] = useState([]);
  const { setLoader } = useLoader();
  const [currentpage, setCurrentPage] = useState(1);
  const [pageDetails, setPageDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const [text] = useDebounce(value, 1000);
  const history = useHistory();
  const [isClient, setIsClient] = useState(false);
  const { token } = useUser();
  const { setState } = useDialog();
  const toast = useToast();

  async function getAllCases() {
    setLoading(true);
    try {
      let params = {
        page: currentpage,
        per_page: 10,
      };
      if (text) {
        params.term = text;
      }
      if (status) {
        params.list_type = status;
      }
      const stringParams = `?${queryString.stringify(params)}`;
      let result;
      if (status === "deletion_requests") {
        result = await getDeleteReqCases(stringParams);
      } else {
        result = await getCases(stringParams);
      }
      if (result?.response) {
        setCases(result?.response?.legal_cases);
      }

      setPageDetails(result?.response?.pagination_details ?? {});
    } catch (err) {
      console.log(err);
      setCases([]);
      setPageDetails({});
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentpage, text, status]);

  useEffect(() => {
    if (token && token !== "logged_out") {
      setIsClient(jwt_decode(token)?.claims?.role === "client");
    }
  }, [token]);

  const triggerAction = async (isApproved, serviceId, value) => {
    try {
      setLoader({ state: true, message: "Updating status" });
      const payload = {
        case_service_id: serviceId,
        status: isApproved ? "accepted" : "rejected",
      };
      if (value) {
        payload.rejection_reason = value;
      }
      const result = await adminRejectUpdate(payload);
      if (result?.message) {
        toast("success", result?.message);
        setState({});
        getAllCases();
        setTriggerCount(true);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setState({});
      setLoader({ state: false });
    }
  };

  const performAction = (isApproved, id) => {
    if (isApproved) {
      setState({
        type: "ALERT",
        message: "Are you sure, you want to approve this request?",
        primaryBtn: "Approve",
        secondaryBtn: "Cancel",
        onSuccess: () => triggerAction(isApproved, id, ""),
        onCancel: () => {
          setState({});
        },
        title: "Alert",
      });
    } else {
      setState({
        type: "REJECT_REASON",
        callBack: (value) => {
          triggerAction(isApproved, id, value);
        },
      });
    }
  };

  return (
    <Pagination
      pagination={{
        currentpage: currentpage,
        totalPage: pageDetails?.total_pages,
        handlePageClick: ({ selected }) => {
          setCurrentPage(selected + 1);
        },
      }}
    >
      {/* actiotn bar */}
      <div
        className="flex justify-between absolute w-full right-5 top-5"
        style={{ maxWidth: 448 }}
      >
        <div className="flex flex-1 justify-end">
          <SearchInput {...{ value, setValue }} />
        </div>
      </div>
      <div className="mt-5 flex flex-col space-y-4">
        {loading ? (
          <>
            {[1, 2, 3, 4, 5, 6]?.map((el) => (
              <div key={el}>
                <CaseList loading={true} />
              </div>
            ))}
          </>
        ) : (
          <>
            {cases?.length ? (
              <>
                {cases?.map((el, index) => (
                  <div key={index}>
                    <CaseList data={el} {...{ performAction }} />
                  </div>
                ))}
              </>
            ) : (
              <div className="flex justify-center my-10">
                <div className="max-w-xl w-full">
                  <NoResult message="No Cases Found!" />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {isClient && (
        <div className="fixed bottom-8 right-8 hidden">
          <Button
            variant="primary-filled"
            animate={true}
            className="h-16 px-6 flex items-center text-sm justify-center rounded-full uppercase hover:shadow"
            onClick={() => history.push("/new-case")}
          >
            <PlusIcon className="h-5 w-5 text-white text-current mr-2" />
            Create New Case
          </Button>
        </div>
      )}
    </Pagination>
  );
};

export default Cases;
