import React, { useEffect, useState } from "react";
import FormFields from "../../Common/FormFields/FormFields";
import Button from "../../Common/Button";
import { getServices } from "../../../services/caseServices";
import { getErrorMessage } from "../../../_helper/functions";
import useLoader from "../../../hooks/useLoader";
import { useToast } from "../../../hooks/useToast";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/outline";
import useDialog from "../../../hooks/useDialog";
import { FieldArray, Form, Formik } from "formik";
import { XIcon, PlusIcon } from "@heroicons/react/solid";

const AddNewCaseService = ({
  open = false,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const [caseServiceList, setCaseServiceList] = useState([]);
  const { setLoader } = useLoader();
  const toast = useToast();
  const { setState } = useDialog();

  useEffect(() => {
    async function getCaseServices() {
      setLoader({ state: true, message: "Loading services.." });
      try {
        const result = await getServices();
        if (result?.status === 200) {
          const serviceList = [];
          const defaultService = result?.response?.legal_cases?.filter(
            (el) => el.kind === "default"
          );
          const customService = result?.response?.legal_cases?.filter(
            (el) => el.kind === "additional" && !el.is_created_by_user
          );
          const serviceCreatedByUser = result?.response?.legal_cases?.filter(
            (el) => el.is_created_by_user
          );
          if (defaultService?.length) {
            serviceList.push({
              title: "Services Required",
              items: defaultService,
            });
          }
          if (customService?.length) {
            serviceList.push({
              title: "Additional Services",
              items: customService,
            });
          }
          if (serviceCreatedByUser?.length) {
            serviceList.push({
              title: "My Custom Services",
              items: serviceCreatedByUser,
            });
          }
          setCaseServiceList(serviceList);
        }
      } catch (err) {
        const message = getErrorMessage(err);
        toast("error", message);
        setCaseServiceList([]);
      } finally {
        setLoader({ state: false });
      }
    }
    getCaseServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values) => {
    if (values?.services?.length || values?.new_services?.length) {
      onSuccess(values);
    } else {
      toast("error", "Please select atleast one services");
    }
  };

  const addNewService = (arrayHelpers) => {
    setState({
      type: "ADD_SERVICE",
      callBack: (value) => {
        arrayHelpers.push(value);
        setState({});
      },
    });
  };

  if (!open) return null;

  return (
    <div>
      <div
        className={`fixed bottom-0 inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10`}
      >
        <div
          className={`bg-white overflow-auto relative animate-modal rounded-2xl shadow-2xl`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ maxHeight: "calc(100% - 50px)", maxWidth: "70%" }}
        >
          <button
            type="button"
            onClick={() => onClose()}
            className="right-3 sm:right-5 top-3 sm:top-5 absolute focus:outline-none"
          >
            <XIcon className="w-7 h-7 text-theme-blue-900" />
          </button>
          <div className="w-full">
            <div className="text-center text-2xl font-bold py-4 border-b">
              New Service
            </div>
            <Formik
              initialValues={{
                services: [],
                new_services: [],
              }}
              enableReinitialize
              onSubmit={onSubmit}
              validateOnChange
            >
              {({ values }) => (
                <Form className="h-full relative">
                  <div
                    className="flex space-x-6 overflow-y-auto px-8 mb-4"
                    style={{ height: "calc(100vh - 160px)" }}
                  >
                    <div className="my-4 py-4 flex">
                      <FieldArray
                        name="services"
                        render={(arrayHelpers) => (
                          <>
                            {caseServiceList?.map((service, idx) => (
                              <div key={idx} className="pb-4 px-4 flex-1">
                                <div className="font-semibold text-indigo-600 text-lg">
                                  {service?.title}
                                </div>
                                {service?.items?.map((item, index) => (
                                  <div
                                    key={index}
                                    className="py-2 flex items-center"
                                  >
                                    <FormFields
                                      type="checkBox"
                                      name="services"
                                      id={`service_${index}`}
                                      value={item}
                                      checked={values.services.includes(
                                        item.id
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          arrayHelpers.push(item.id);
                                        } else {
                                          const idx = values.services.indexOf(
                                            item.id
                                          );
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                    />
                                    <div className="flex text-sm font-medium pt-1 pl-2">
                                      {item?.name}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </>
                        )}
                      />
                      <div className="flex-1">
                        <FieldArray
                          name="new_services"
                          render={(arrayHelpers) => (
                            <>
                              {values?.new_services?.length ? (
                                <div className="pb-4">
                                  <div className="font-semibold text-indigo-600 text-lg">
                                    Other services
                                  </div>
                                  {values?.new_services?.map((item, index) => (
                                    <div
                                      key={index}
                                      className="py-2 flex items-center"
                                    >
                                      <div className="flex text-sm font-medium pl-2">
                                        <span className="font-bold mr-1">
                                          {index + 1}.
                                        </span>{" "}
                                        {item}
                                      </div>
                                      <div
                                        className="ml-4 cursor-pointer"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <TrashIcon className="h-5 w-5 text-red-600" />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : null}
                              <div className="flex justify-end mt-4">
                                <Button
                                  type="button"
                                  variant="outline"
                                  className="flex items-center py-1 px-4"
                                  onClick={() => addNewService(arrayHelpers)}
                                >
                                  <span>
                                    <PlusCircleIcon className="h-6 w-6 mr-2" />
                                  </span>
                                  Add other service
                                </Button>
                              </div>
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-4 absolute flex justify-end bottom-10 right-10">
                    <button
                      type={"submit"}
                      variant="primary-filled"
                      className="w-20 h-20 rounded-full bg-indigo-600 flex justify-center items-center hover:shadow-2xl"
                    >
                      <PlusIcon className="w-10 h-10 text-white" />
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCaseService;
