import React from "react";
import Button from "../../Common/Button/Button";

const VerifyUserList = ({
  loading = false,
  data = {},
  onApprove = () => {},
  onReject = () => {},
}) => {
  if (loading) {
    return (
      <div className="py-4 bg-white rounded-lg shadow-sm border border-gray-300 animate-pulse">
        <div className="flex items-center flex-1 w-full">
          <div className="w-1/6 flex flex-col px-4">
            <div className="h-4 w-32 bg-gray-200 rounded"></div>
            <div className="h-4 w-28 rounded bg-gray-200 mt-2"></div>
          </div>
          <div className="w-1/6 flex flex-col px-4">
            <div className="h-4 w-32 bg-gray-200 rounded"></div>
            <div className="h-4 w-28 rounded bg-gray-200 mt-2"></div>
          </div>
          <div className="w-1/6 flex flex-col px-4">
            <div className="h-4 w-32 bg-gray-200 rounded"></div>
            <div className="h-4 w-28 rounded bg-gray-200 mt-2"></div>
          </div>
          <div className="w-1/6 flex flex-col px-4">
            <div className="h-4 w-32 bg-gray-200 rounded"></div>
            <div className="h-4 w-28 rounded bg-gray-200 mt-2"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="py-4 bg-white rounded-lg shadow-sm border border-gray-300">
      <div className="flex items-center flex-1 w-full">
        <div className="w-1/6 flex flex-col px-4">
          <div className="text-gray-400 text-xs font-medium">User Name:</div>
          <div className="text-sm font-bold">{data?.name}</div>
        </div>
        <div className="w-1/6 flex flex-col px-4">
          <div className="text-gray-400 text-xs font-medium">Firm Name:</div>
          <div className="text-sm font-bold">{data?.firm_name || "-"}</div>
        </div>
        <div className="w-1/6 flex flex-col px-4">
          <div className="text-gray-400 text-xs font-medium">
            Contact Number:
          </div>
          <div className="text-sm font-bold">
            <a href={`tel:${data?.contact_number}`}>{data?.contact_number}</a>
          </div>
        </div>
        <div className="w-1/6 flex flex-col px-4">
          <div className="text-gray-400 text-xs font-medium">Email:</div>
          <div className="text-sm font-bold truncate">
            <a href={`mailto:${data?.email}`}>{data?.email}</a>
          </div>
        </div>
        <div className="flex flex-1 w-1/3 justify-end items-center space-x-1">
          <div className="flex-1 flex flex-col px-4">
            <Button
              variant="primary-filled"
              className="p-1 text-sm"
              onClick={() =>
                onApprove(
                  data?.email_verified_at ? "verify_account" : "verify_email"
                )
              }
            >
              {data?.email_verified_at ? "Verify Account" : "Confirm Email"}
            </Button>
          </div>
          <div className="flex-1 flex flex-col px-4">
            <div
              className="underline text-indigo-600 cursor-pointer text-sm"
              onClick={() => onReject()}
            >
              Reject Client
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyUserList;
