import React, { useEffect, useState } from "react";
import useLoader from "../../../hooks/useLoader";
import { useToast } from "../../../hooks/useToast";
import { getUserDetails } from "../../../services/userServices";
import { getErrorMessage } from "../../../_helper/functions";
import MyProfileForm from "../../Internal/MyprofileForm";
import UpdatePasswordForm from "../../Internal/UpdatePasswordForm";

const MyProfile = () => {
  const [user, setUser] = useState({});
  const toast = useToast();
  const { setLoader } = useLoader();

  useEffect(() => {
    async function getUserInfo() {
      try {
        setLoader({ state: true, message: "Getting user information..." });
        const result = await getUserDetails();
        if (result?.status === 200) {
          setUser(result?.response?.user);
        }
      } catch (err) {
        const message = getErrorMessage(err);
        toast("error", message);
      } finally {
        setLoader({ state: false });
      }
    }
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-full flex justify-center space-x-4">
      <div className="max-w-md w-full p-6 bg-white shadow rounded-lg">
        {/* Heading */}
        <div className="text-center text-2xl font-bold uppercase">
          Update Profile
        </div>
        <div className="py-6 h-full">
          <MyProfileForm data={user} />
        </div>
      </div>
      <div className="max-w-md w-full p-6 bg-white shadow rounded-lg">
        {/* Heading */}
        <div className="text-center text-2xl font-bold uppercase">
          Update password
        </div>
        <div className="py-6 h-full">
          <UpdatePasswordForm />
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
