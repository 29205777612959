import React from "react";
import { TrashIcon } from "@heroicons/react/solid";
import _ from "lodash";

const BlockedList = ({ loading = false, data = {}, onRemove = () => {} }) => {
  if (loading) {
    return (
      <div className="flex items-center flex-1 w-full border-b bg-white shadow rounded animate-pulse">
        <div className="flex flex-col item-center p-4 w-full">
          <div className="h-4 w-32 bg-gray-200 rounded"></div>
          <div className="h-4 w-28 rounded bg-gray-200 mt-2"></div>
        </div>
        <div className="w-full justify-end flex item-center p-4">
          <div className="h-10 w-10 rounded-full bg-red-200"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex items-center flex-1 w-full border-b bg-white shadow rounded">
      <div
        className="flex flex-col item-center p-4 w-full"
        style={{ maxWidth: "70%" }}
      >
        <div className="text-gray-400 text-xs font-medium">
          {_.startCase(data?.kind)}
        </div>
        <div
          className="text-sm font-bold break-all truncate"
          title={data?.component}
        >
          {data?.component}
        </div>
      </div>
      <div className="w-full justify-end flex item-center p-4">
        <div
          className="h-10 w-10 rounded-full bg-red-600 flex justify-center items-center cursor-pointer"
          onClick={() => onRemove()}
        >
          <TrashIcon className="h-6 w-6 text-white" />
        </div>
      </div>
    </div>
  );
};

export default BlockedList;
