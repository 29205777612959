import axios from "axios";

/**
 * Create an Axios Client with defaults
 */
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 30000,
});

/**
 * Request Wrapper with default success/error actions
 */
const Api = async (
  config = { method: "GET", data: {}, url: "" },
  shouldAppendToken = true
) => {
  // Success
  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  // Error
  const onError = (error) => {
    if (error?.message === "Network Error") {
      return Promise.reject(
        "You have lost Network Connectivity. Please check your Network Settings"
      );
    }
    if (error.response.status === 401) {
      /**
       * If 401
       * Clear the token from offline store
       * and navigate to Initial Stack using Navigation Service
       */
    }
    if (error.response.status === 500) {
      /**
       * If 500
       * Clear the token from offline store
       * and navigate to Initial Stack using Navigation Service
       */
    }

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      return Promise.reject(error.response.data);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      return Promise.reject(error.message);
    }
  };

  // Append headers
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  // Append auth token
  if (shouldAppendToken) {
    try {
      const token = await localStorage.getItem("auth_token");
      if (token) {
        headers["Authorization"] = token;
      } else {
        return Promise.reject("Auth Token not found");
      }
    } catch (error) {
      // Token is not found
      return Promise.reject(error.message);
    }
  }

  // Set headers
  axiosClient.defaults.headers = headers;

  // console.log("Request Headers!", axiosClient.defaults.headers);
  // console.log("Request Configurations!", config);

  return axiosClient(config).then(onSuccess).catch(onError);
};

export default Api;
