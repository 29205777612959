import { XCircleIcon } from "@heroicons/react/solid";
import React, { Fragment } from "react";
import { renderImgBasedOnFileType } from "../../../_helper/functions";

const FileTypeImages = ({ files = [], onRemove = () => {} }) => {
  return (
    <>
      {files?.length
        ? files.map((file, index) => (
            <Fragment key={index}>
              <div className="w-24 mx-4 mt-4 relative">
                <div
                  className="cursor-pointer"
                  onClick={() => window.open(file?.url, "_blank")}
                >
                  <img
                    src={renderImgBasedOnFileType(file.name.split(".").pop())}
                    className="h-24 w-24"
                    alt="file_type_images"
                  />
                  <div
                    className="text-center text-xs mt-1 text-gray-600 font-semibold truncate"
                    title={decodeURIComponent(file?.name)}
                  >
                    {decodeURIComponent(file?.name)}
                  </div>
                </div>
                <div
                  className="h-6 w-6 cursor-pointer absolute -top-4 -right-1"
                  onClick={() => onRemove(index)}
                >
                  <XCircleIcon className="h-full w-full text-red-600" />
                </div>
              </div>
            </Fragment>
          ))
        : null}
    </>
  );
};

export default FileTypeImages;
