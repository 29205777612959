import _ from "lodash";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({
  tabs = [],
  onTabChanges = () => {},
  selectedTabIndex = 0,
  stats = null,
}) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={tabs[selectedTabIndex].name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <a
              key={tab.name}
              href={tab.href}
              onClick={() => onTabChanges(index)}
              className={classNames(
                index === selectedTabIndex
                  ? "bg-indigo-100 text-indigo-700"
                  : "text-gray-500 hover:text-gray-700",
                "px-3 py-2 font-medium text-sm rounded-md cursor-pointer uppercase"
              )}
              aria-current={index === selectedTabIndex ? "page" : undefined}
            >
              <div className="flex item-center">
                <span>{tab?.name}</span>{" "}
                {!_.isEmpty(stats) && (
                  <span className="ml-2 w-8 text-xs flex justify-center items-center rounded-full bg-pink-700 text-white">
                    {stats[tab?.key]}
                  </span>
                )}
              </div>
            </a>
          ))}
        </nav>
      </div>
      <div>{tabs[selectedTabIndex]?.page}</div>
    </div>
  );
};

export default Tabs;
