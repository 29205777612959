import axios from "axios";
import { Images } from "../assets/image_helper";
import _ from "lodash";

export const getErrorMessage = (error) =>
  error?.message
    ? error.message
    : error?.error && typeof error.error === "string"
    ? error.error
    : typeof error === "string"
    ? error
    : error[0]?.message
    ? error[0]?.message
    : "Something went wrong!";

export function replaceHtmlTag(html) {
  const str = html ? html?.toString() : "";
  return str.replace(/(<([^>]+)>)/gi, "");
}

/**
 * @description function to upload to AWS
 * @param {*} url
 * @param {*} file
 */
export const uploadToAws = async (url, file, onUploadProgress) => {
  const result = axios
    .put(url, file, {
      onUploadProgress,
    })
    .then((_) => {
      console.log(url.split("?")[0]);
      return url.split("?")[0];
    })
    .catch((err) => {
      throw err;
    });
  return result;
};

export const renderImgBasedOnFileType = (extention) => {
  const type = _.lowerCase(extention).trim();
  if (type === "pdf") {
    return Images.pdfFileType;
  } else if (type === "doc" || type === "docx") {
    return Images.documentFileType;
  } else if (type === "jpg" || type === "png" || type === "jpeg") {
    return Images.imageFileType;
  } else if (type === "txt") {
    return Images.textFileType;
  } else if (type === "ppt" || type === "pptx") {
    return Images.pptFileType;
  } else if (type === "xls" || type === "xlsx" || type === "csv") {
    return Images.excelFileType;
  } else {
    return Images.normalFile;
  }
};

export const formatToCurrency = (numb) => {
  const formatter = new Intl.NumberFormat("en-US");
  return `$${formatter.format(numb)}`;
};

export const setStatusNotes = (notes, status) => {
  if (notes) {
    return notes;
  }
  switch (status) {
    case "received":
      return "Your request for the service has been received and it is waiting for our team to review";
    case "estimated":
      return "Please confirm the estimated cost and time for further proceeding with the service request";
    case "approved":
      return "Your request has been confirmed and it will be scheduled to our expertise shortly";
    case "processing":
      return "Your request will be completed within our estimated completion date";
    default:
      break;
  }
};

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export const replaceSplCharFromPhone = (phone) => {
  return phone ? phone.toString()?.replace(/[^0-9]/gi, "") : "";
};

export const acceptFileType =
  "audio/*,video/*,image/*,.pdf,.ppt,.pptx,.xls,.xlsx,.rtf,.zip,.doc,.docx,.txt";
