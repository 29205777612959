import React, { useEffect, useState } from "react";

export const UserContext = React.createContext();

function UserProvider({ children }) {
  const [token, setToken] = useState(null);

  useEffect(() => {
    try {
      function getToken() {
        try {
          const local_token = localStorage.getItem("auth_token");
          if (local_token) setToken(local_token);
        } catch (error) {
          console.log(error);
        }
      }
      getToken();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      if (token) {
        if (token === "logged_out") {
          localStorage.setItem("auth_token", "");
          setToken(null);
        } else {
          localStorage.setItem("auth_token", token);
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const value = React.useMemo(() => {
    return {
      token,
      setToken,
    };
  }, [token, setToken]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserProvider;
