import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  XIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/solid";
import { Fragment } from "react";
import { useToastDispatchContext } from "../../../context/ToastContext";

export default function Toast({ type, message, id }) {
  const dispatch = useToastDispatchContext();

  const renderClass = (type) => {
    switch (type) {
      case "success":
        return {
          messageCls: "text-white",
          borderCls: "border border-green-500 bg-green-500",
        };
      case "error":
        return {
          messageCls: "text-white",
          borderCls: "border border-red-500 bg-red-500",
        };
      default:
        return {
          messageCls: "text-white",
          borderCls: "border border-gray-500 bg-gray-500",
        };
    }
  };

  return (
    <>
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end p-4">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition
          show={!!type}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-4 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ${
              renderClass(type).borderCls
            }`}
          >
            <div className="p-4">
              <div className="flex items-start">
                {type === "success" && (
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                )}
                {type === "error" && (
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                )}
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p
                    className={`text-sm font-medium ${
                      renderClass(type).messageCls
                    }`}
                  >
                    {message}
                  </p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => dispatch({ type: "DELETE_TOAST", id: id })}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}
