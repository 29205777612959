import { Form, Formik } from "formik";
import React from "react";
import Button from "../../Common/Button/Button";
import FormFields from "../../Common/FormFields/FormFields";

const AddDomain = ({ state = {} }) => {
  const onFormSubmit = (values) => {
    console.log("submitted");
    state.callBack(values);
  };
  return (
    <div>
      <div className="text-center text-xl font-bold">New Email/Domain</div>
      <div className="py-2">
        <Formik
          initialValues={{
            domain: "",
            email: "",
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validateOnChange
        >
          {({ handleChange, errors, touched, values }) => (
            <Form className="h-full flex flex-col">
              <div className="flex flex-col">
                <div>
                  <FormFields
                    type="email"
                    name="email"
                    placeholder="Email"
                    label="Email"
                    disabled={values?.domain}
                    value={values?.email}
                    onChange={handleChange}
                    error={touched["email"] && errors["email"]}
                  />
                </div>
                <div className="text-center font-bold pt-4">or</div>
                <div>
                  <FormFields
                    type="text"
                    name="domain"
                    placeholder="Domain"
                    label="Domain"
                    value={values?.domain}
                    disabled={values?.email}
                    onChange={handleChange}
                    error={touched["domain"] && errors["domain"]}
                  />
                </div>
              </div>
              <div className="pt-8 flex flex-1 w-full items-end ">
                <Button
                  type="submit"
                  variant="primary-filled"
                  disabled={!values?.domain && !values?.email}
                  className="px-4 py-2 w-full font-bold"
                >
                  Blocklist
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddDomain;
