import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  UserIcon,
  UserGroupIcon,
  CreditCardIcon,
  MailIcon,
  MenuAlt2Icon,
  DocumentDuplicateIcon,
  XIcon,
} from "@heroicons/react/solid";
import { Images } from "../../../assets/image_helper";
import { useHistory, useLocation } from "react-router";
import useDialog from "../../../hooks/useDialog";
import useUser from "../../../hooks/useUser";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import Button from "../../Common/Button";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout({ children }) {
  const [navigation, setNavigation] = useState([]);
  const [userNavigation, setUserNavigation] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [name, setName] = useState("");
  const location = useLocation();
  const history = useHistory();
  const { setState } = useDialog();
  const { setToken, token } = useUser();
  const [isVerified, setIsVerified] = useState(false);
  const [isClient, setIsClient] = useState(false);

  const triggerLogout = () => {
    if (token) {
      const userObj = jwt_decode(token)?.claims;
      setState({
        type: "ALERT",
        message: "Are you sure, you want to logout?",
        primaryBtn: "Yes",
        secondaryBtn: "No",
        onSuccess: () => {
          setToken("logged_out");
          setState({});
          setTimeout(() => {
            if (userObj?.role === "client") {
              history.push("/");
            } else {
              history.push("/support/login");
            }
          });
        },
        onCancel: () => {
          setState({});
        },
        title: "Logout Confirmation",
      });
    }
  };

  useEffect(() => {
    if (token && token !== "logged_out") {
      const userObj = jwt_decode(token)?.claims;
      setIsClient(jwt_decode(token)?.claims?.role === "client");
      setBadge(userObj);
      if (userObj?.role === "client") {
        const filterMenu = sidePanelMenu?.filter(
          (el) => el?.name === "Case" || el?.name === "Payment"
        );
        setNavigation(filterMenu);
        setUserNavigation(userMenu);
        setIsVerified(!!userObj?.account_verified_at);
      } else {
        if (userObj?.role === "admin") {
          const adminMenu = sidePanelMenu?.filter(
            (el) => el?.name === "Case" || el?.name === "Payment"
          );
          setNavigation(adminMenu);
        } else {
          setNavigation(sidePanelMenu);
        }
        const filterMenu = userMenu?.filter((el) => el?.name === "Logout");
        setUserNavigation(filterMenu);
        setIsVerified(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, location?.pathname]);

  const userMenu = [
    // { name: "New Case", href: "#", onClick: () => history.push("/new-case") },
    { name: "Profile", href: "#", onClick: () => history.push("/my-profile") },
    { name: "Logout", href: "#", onClick: () => triggerLogout() },
  ];

  const sidePanelMenu = [
    {
      name: "Case",
      href: "#",
      onClick: () => history.push("/cases"),
      icon: DocumentDuplicateIcon,
      current: location.pathname.includes("cases"),
    },
    {
      name: "Payment",
      href: "#",
      onClick: () => history.push("/payments"),
      icon: CreditCardIcon,
      current: location.pathname.includes("payments"),
    },
    {
      name: "Verify User",
      href: "#",
      onClick: () => history.push("/verify-users"),
      icon: UserGroupIcon,
      current: location.pathname.includes("verify-users"),
    },
    {
      name: "Admins",
      href: "#",
      onClick: () => history.push("/admins"),
      icon: UserIcon,
      current: location.pathname.includes("admins"),
    },
    {
      name: "Blacklisted Email",
      href: "#",
      onClick: () => history.push("/blocked-emails"),
      icon: MailIcon,
      current: location.pathname.includes("blocked-emails"),
    },
  ];

  const setBadge = (userObj) => {
    const name = userObj.name || "";
    setName(
      _.upperCase(
        name
          .split(" ")
          .map((el) => el?.substring(0, 1))
          .join("")
      )
    );
  };

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-8 w-auto"
                  onClick={() => history.push("/")}
                  src={Images.companyLogo}
                  alt="Workflow"
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto py-10">
                <nav className="px-2 space-y-1">
                  {navigation?.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={() => (isVerified ? item?.onClick() : "")}
                      className={classNames(
                        item.current
                          ? "bg-indigo-800 text-white"
                          : isVerified
                          ? "text-gray-400 hover:bg-indigo-600 hover:text-white"
                          : "cursor-not-allowed",
                        "group flex items-center px-4 py-4 text-sm font-bold rounded-md"
                      )}
                    >
                      <item.icon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-300"
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="hidden bg-white md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4 h-20 shadow">
              <img
                className="cursor-pointer"
                onClick={() => history.push("/")}
                src={Images.companyLogo}
                alt="Workflow"
              />
            </div>
            <div className="mt-5 flex-1 flex flex-col pb-10">
              <nav className="flex-1 px-2 space-y-1">
                {navigation?.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    onClick={() => (isVerified ? item?.onClick() : "")}
                    className={classNames(
                      item.current
                        ? "bg-indigo-800 text-white"
                        : isVerified
                        ? "text-gray-400 hover:bg-indigo-600 hover:text-white"
                        : "cursor-not-allowed text-gray-200",
                      "group flex items-center px-4 py-4 text-sm font-bold rounded-md"
                    )}
                  >
                    <item.icon
                      className="mr-3 flex-shrink-0 h-5 w-5  text-current font-bold"
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-14 sm:h-20 bg-white shadow">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="ml-4 items-center w-full flex justify-end md:ml-6">
              {isClient && (
                <div className="mr-4">
                  <Button
                    variant="primary-filled"
                    animate={true}
                    className="px-4 py-2 flex items-center text-sm justify-center rounded-full uppercase hover:shadow"
                    onClick={() => history.push("/new-case")}
                  >
                    Create New Case
                  </Button>
                </div>
              )}

              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="h-10 w-10 rounded-full bg-indigo-600 focus:ring-indigo-500">
                    <span className="sr-only">Open user menu</span>
                    <div className="font-bold text-xl text-white">{name}</div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation?.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            onClick={item?.onClick}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1 relative overflow-y-auto focus:outline-none bg-gray-100">
          <div className="px-4 py-6">{children}</div>
        </main>
      </div>
      {isClient && (
        <TawkMessengerReact
          propertyId={process.env.REACT_APP_TAWK_CHAT_ID}
          widgetId="default"
        />
      )}
    </div>
  );
}
