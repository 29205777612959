import React, { useState } from "react";
import { useHistory } from "react-router";
import Button from "../../../Common/Button";
import ExpansionPanel from "../../../Common/ExpansionPanel/ExpansionPanel";
import ProgressStats from "../../../Common/ProgressStats";
import moment from "moment";
import { formatToCurrency } from "../../../../_helper/functions";
import FormFields from "../../../Common/FormFields/FormFields";

const PendingHistory = ({
  loading = false,
  data = {},
  role = "client",
  selectedService = [],
  setSelectedService = () => {},
}) => {
  const [expand, setExpand] = useState(false);
  const history = useHistory();
  return (
    <ExpansionPanel
      {...{ expand, setExpand, loading }}
      header={<Header {...{ loading, data }} />}
    >
      {!loading && (
        <>
          <div className="grid grid-cols-3 py-4 px-8 gap-4">
            {data.services?.map((el, index) => (
              <div className="flex items-center">
                {role !== "client" && (
                  <div className="pr-4">
                    <FormFields
                      type="checkBox"
                      name="services"
                      id={`service_${index}`}
                      checked={selectedService?.includes(el.uid)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedService((selectedService) => [
                            ...selectedService,
                            el.uid,
                          ]);
                        } else {
                          const idx = selectedService.indexOf(el.uid);
                          setSelectedService((selectedService) => {
                            const tempArr = selectedService.splice(idx, 1);
                            return tempArr;
                          });
                        }
                      }}
                    />
                  </div>
                )}
                <div
                  key={index}
                  className="w-full border shadow rounded flex text-sm font-semibold"
                >
                  <div className="border-r pl-3 w-3/4 py-3">
                    {el?.service_name}
                  </div>
                  <div className="flex flex-1 py-3 pl-3">
                    {formatToCurrency(el?.estimated_cost)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex py-4 px-8 justify-end">
            <div>
              <Button
                variant="primary-filled"
                onClick={() => history.push(`/cases/${data?.legal_case?.uid}`)}
              >
                View Case
              </Button>
            </div>
          </div>
        </>
      )}
    </ExpansionPanel>
  );
};

const Header = ({ loading = false, data = {} }) => {
  if (loading) {
    return (
      <div className="flex items-center flex-1 w-full border-b animate-pulse">
        <div
          className="w-full flex justify-center p-4"
          style={{ maxWidth: "12.5%" }}
        >
          <div className="w-16 h-16 bg-gray-400 rounded-full"></div>
        </div>
        <div
          className="flex flex-col item-center p-4 w-full"
          style={{ maxWidth: "12.5%" }}
        >
          <div className="text-gray-400 text-xs font-medium h-4 w-24 bg-gray-200 rounded mb-2"></div>
          <div className="text-sm font-bold h-3 w-20 bg-gray-200 rounded"></div>
        </div>
        <div
          className="w-full flex flex-col item-center p-4"
          style={{ maxWidth: "20.5%" }}
        >
          <div className="text-gray-400 text-xs font-medium h-4 w-24 bg-gray-200 rounded mb-2"></div>
          <div className="text-sm font-bold h-3 w-20 bg-gray-200 rounded"></div>
        </div>
        <div
          className="w-full flex flex-col item-center p-4"
          style={{ maxWidth: "20.5%" }}
        >
          <div className="text-gray-400 text-xs font-medium h-4 w-24 bg-gray-200 rounded mb-2"></div>
          <div className="text-sm font-bold overflow-hidden truncate h-3 w-20 bg-gray-200 rounded"></div>
        </div>
        <div
          className="w-full flex flex-col item-center p-4"
          style={{ maxWidth: "12.5%" }}
        >
          <div className="text-gray-400 text-xs font-medium h-4 w-24 bg-gray-200 rounded mb-2"></div>
          <div className="text-sm font-bold h-3 w-20 bg-gray-200 rounded"></div>
        </div>
        <div
          className="w-full flex flex-col item-center p-4"
          style={{ maxWidth: "12.5%" }}
        >
          <div className="text-gray-400 text-xs font-medium h-4 w-24 bg-gray-200 rounded mb-2"></div>
          <div className="text-sm font-bold h-3 w-20 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="flex items-center flex-1 w-full border-b">
      <div
        className="w-full flex justify-center p-4"
        style={{ maxWidth: "12.5%" }}
      >
        <div className="w-16 h-16">
          <ProgressStats
            percentage={data?.legal_case?.percentage_completed || 0}
          />
        </div>
      </div>
      <div
        className="flex flex-col item-center p-4 w-full"
        style={{ maxWidth: "12.5%" }}
      >
        <div className="text-gray-400 text-xs font-medium">Created date:</div>
        <div className="text-sm font-bold">
          {data?.legal_case?.created_at
            ? moment(data.legal_case.created_at).format("MM/DD/YYYY")
            : "-"}
        </div>
      </div>
      <div
        className="w-full flex flex-col item-center p-4"
        style={{ maxWidth: "20.5%" }}
      >
        <div className="text-gray-400 text-xs font-medium"> Unique ID:</div>
        <div className="text-sm font-bold">{data?.legal_case?.uid}</div>
      </div>
      <div className="w-full flex flex-col item-center p-4 flex-1">
        <div className="text-gray-400 text-xs font-medium">Case Name:</div>
        <div className="text-sm font-bold overflow-hidden truncate">
          {data?.legal_case?.title}
        </div>
      </div>
    </div>
  );
};

export default PendingHistory;
