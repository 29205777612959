import React, { useEffect, useState } from "react";
import useLoader from "../../../hooks/useLoader";
import { useToast } from "../../../hooks/useToast";
import {
  caseServiceStatusUpdate,
  getCaseDetails,
  postServices,
  rejectCaseRequest,
} from "../../../services/caseServices";
import { formatToCurrency, getErrorMessage } from "../../../_helper/functions";
import Button from "../../Common/Button";
import CaseDetailCard from "../../Internal/CaseDetailCard";
import ContactUsCard from "../../Internal/ContactUsCard";
import ServicesCard from "../../Internal/ServicesCard";
import _ from "lodash";
import jwt_decode from "jwt-decode";
import useUser from "../../../hooks/useUser";
import useDialog from "../../../hooks/useDialog";
import AddNewCaseService from "../../Internal/AddNewCaseService/AddNewCaseService";
import useQuery from "../../../hooks/useQuery";

const ViewCase = ({ match }) => {
  const { setLoader } = useLoader();
  const toast = useToast();
  const [caseDetails, setCaseDetails] = useState({});
  const { token } = useUser();
  const [role, setRole] = useState("");
  const { setState } = useDialog();
  const [triggerRefresh, setTriggerRefresh] = useState(true);
  const [openNewService, setOpenNewService] = useState(false);
  const query = useQuery();

  useEffect(() => {
    async function getCaseInfo(id) {
      try {
        const res = await getCaseDetails(id);
        if (res?.response?.legal_case) {
          setCaseDetails(res?.response?.legal_case);
        }
      } catch (err) {
        const message = getErrorMessage(err);
        toast("error", message);
      } finally {
        setLoader({ state: false });
      }
    }
    if (match?.params?.slug && triggerRefresh) {
      setLoader({ state: true, message: "Getting Case details" });
      getCaseInfo(match?.params?.slug);
      setTriggerRefresh(false);
      if (query.get("serviceId")) {
        const serviceId = query.get("serviceId");
        setTimeout(() => {
          scrollToService(serviceId);
        }, 500);
      } else {
        scrollToService();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRefresh]);

  const scrollToService = (id) => {
    setTimeout(() => {
      const top = id ? document.getElementById(`service_${id}`)?.offsetTop : 0;
      const main = document.querySelector("main");
      main.scroll({
        top,
        behavior: "smooth",
      });
    }, 500);
  };

  useEffect(() => {
    if (token && token !== "logged_out") {
      setRole(jwt_decode(token).claims?.role);
    }
  }, [token]);

  const updateStaus = async (id, value, status) => {
    try {
      setLoader({ state: true, message: "Updating status" });
      let payload = { status: status?.value };
      if (value) {
        payload = { ...payload, ...value };
      }
      const result = await caseServiceStatusUpdate(id, payload);
      if (result?.message) {
        toast("success", result?.message);
        setState({});
        setTriggerRefresh(true);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  const triggerReject = async (id) => {
    try {
      setLoader({ state: true, message: "Updating status" });
      let payload = { case_service_ids: [id] };
      const result = await rejectCaseRequest(payload);
      if (result?.message) {
        toast("success", result?.message);
        setState({});
        setTriggerRefresh(true);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  const onSelectChange = (status, data) => {
    if (status?.value) {
      switch (status?.value) {
        case "estimated":
          setState({
            type: "ADD_ESTIMATION",
            callBack: (value) => {
              updateStaus(data?.uid, value, status);
            },
          });
          break;
        case "approved":
          if (role === "client") {
            updateStaus(data?.uid, null, status);
          }
          break;
        case "rejected":
          if (role === "client") {
            triggerReject(data?.uid);
          }
          break;
        case "processing":
          setState({
            type: "SCHEDULE_DATE",
            callBack: (value) => {
              updateStaus(data?.uid, value, status);
            },
          });
          break;
        case "completed":
          setState({
            type: "COMPLETE_MODAL",
            callBack: (value) => {
              updateStaus(data?.uid, value, status);
            },
          });
          break;
        default:
          break;
      }
    }
  };

  const addNewService = async (values) => {
    try {
      setLoader({ state: true, message: "Adding service to the case" });
      const payload = { ...values };
      const result = await postServices(caseDetails?.uid, payload);
      if (result?.message) {
        toast("success", result?.message);
        setTriggerRefresh(true);
        setOpenNewService(false);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <div>
      {/* Case Detailed card */}
      <div>
        <CaseDetailCard data={caseDetails} {...{ setTriggerRefresh, role }} />
      </div>

      {/* Service tracker title*/}
      <div className="flex justify-between items-center mt-4">
        <div className="w-1/4 p-4 text-lg font-bold">Service Tracker</div>
        <div className="w-1/4 p-4">
          <span className="text-xs text-gray-500">
            Estimated Cost :{" "}
            <span className="text-sm font-bold text-black">
              {" "}
              {formatToCurrency(
                _.sumBy(
                  caseDetails?.case_services,
                  (el) => el?.estimated_cost || 0
                )
              )}
            </span>
          </span>
        </div>
        <div className="w-1/4 p-4">
          <span className="text-xs text-gray-500">
            Estimated Time :{" "}
            <span className="text-sm font-bold text-black">
              {" "}
              {_.sumBy(
                caseDetails?.case_services,
                (el) => el?.estimated_time || 0
              )}{" "}
              hrs
            </span>
          </span>
        </div>
        <div className="w-1/4 p-4 flex flex-1 justify-end">
          <Button
            variant="primary-filled"
            className="px-4 py-2"
            onClick={() => setOpenNewService(true)}
          >
            New Service
          </Button>
        </div>
      </div>

      {/* Servies Card */}
      {caseDetails?.case_services?.length ? (
        <div className="mt-4 space-y-4">
          {caseDetails?.case_services.map((el, index) => (
            <div key={index} id={`service_${el?.uid}`}>
              <ServicesCard
                data={el}
                caseId={caseDetails?.uid}
                role={role}
                onSelectChange={onSelectChange}
                onCaseRefresh={() => setTriggerRefresh(true)}
                ableTodelele={caseDetails?.case_services?.length > 1}
                isAdmin={role !== "client"}
              />
            </div>
          ))}
        </div>
      ) : null}

      {/* Contact us section */}
      <div className="mt-4">
        <ContactUsCard caseDetails={caseDetails} />
      </div>
      <AddNewCaseService
        open={openNewService}
        onSuccess={(values) => addNewService(values)}
        onClose={() => setOpenNewService(false)}
      />
    </div>
  );
};

export default ViewCase;
