import React, { useEffect, useState } from "react";
import Button from "../../Common/Button/Button";
import SearchInput from "../../Common/SearchInput";
import { PlusIcon } from "@heroicons/react/solid";
import AdminList from "../../Internal/AdminList";
import useDialog from "../../../hooks/useDialog";
import Pagination from "../../Common/Pagination";
import queryString from "query-string";
import { useDebounce } from "use-debounce/lib";
import {
  createAdmin,
  updateAdminStatus,
  getAdmins,
  updataAdmin,
} from "../../../services/adminServices";
import NoResult from "../../Common/NoResult";
import {
  getErrorMessage,
  replaceSplCharFromPhone,
} from "../../../_helper/functions";
import { useToast } from "../../../hooks/useToast";
import useLoader from "../../../hooks/useLoader";
import Tabs from "../../Common/Tabs";

const AdminListWrapper = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = [
    {
      name: "Active Admins",
      page: <Admins />,
    },
    {
      name: "Inactive Admins",
      page: <Admins status={"inactive"} />,
    },
  ];
  return (
    <>
      <div className="text-2xl font-bold uppercase mb-4 pt-1">Admins</div>
      <Tabs
        onTabChanges={(index) => setSelectedIndex(index)}
        selectedTabIndex={selectedIndex}
        tabs={tabs}
      />
    </>
  );
};

export default AdminListWrapper;

const Admins = ({ status = "active" }) => {
  const { setState } = useDialog();
  const [admins, setAdmins] = useState([]);
  const [currentpage, setCurrentPage] = useState(1);
  const [pageDetails, setPageDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const [text] = useDebounce(value, 1000);
  const toast = useToast();
  const { setLoader } = useLoader();

  useEffect(() => {
    getAllAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentpage, text, status]);

  async function getAllAdmins() {
    setLoading(true);
    try {
      let params = {
        page: currentpage,
        per_page: 10,
      };
      if (text) {
        params.term = text;
      }
      if (status) {
        params.status = status;
      }
      const stringParams = `?${queryString.stringify(params)}`;
      const result = await getAdmins(stringParams);
      if (result?.response && result?.response?.users?.length) {
        setAdmins(result?.response?.users);
      } else {
        setAdmins([]);
      }
      if (result?.response?.pagination_details) {
        setPageDetails(result?.response?.pagination_details);
      }
    } catch (err) {
      console.log(err);
      setAdmins([]);
      setPageDetails({});
    } finally {
      setLoading(false);
    }
  }

  const addAdmin = async (values) => {
    try {
      setLoader({ state: true, message: "Adding Admin..." });
      const payload = {
        ...values,
        contact_number:
          replaceSplCharFromPhone(values?.contact_number) || "0000000000",
        role: "admin",
      };
      const result = await createAdmin(payload);
      if (result?.message) {
        toast("success", result?.message);
        getAllAdmins();
        setState({});
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  const onEditSuccess = async (value, data) => {
    try {
      setLoader({ state: true, message: "Updating Details..." });
      const payload = {
        ...value,
      };
      const result = await updataAdmin(payload, data?.id);
      if (result?.message) {
        toast("success", result?.message);
        getAllAdmins();
        setState({});
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  const triggerStatusUpdate = async (id) => {
    try {
      setLoader({ state: true, message: "Updating Status..." });
      const payload = {
        status: status === "active" ? "inactive" : "active",
      };
      const result = await updateAdminStatus(id, payload);
      if (result?.message) {
        toast("success", result?.message);
        getAllAdmins();
        setState({});
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  const onPerformAction = (action, data) => {
    if (action === "EDIT") {
      setState({
        type: "ADD_ADMIN",
        data,
        callBack: (value) => onEditSuccess(value, data),
      });
    } else {
      setState({
        type: "ALERT",
        message: `Are you sure, you want to ${
          status === "active" ? "inactive" : "active"
        } this(${data?.name}) admin?`,
        primaryBtn: "Yes",
        secondaryBtn: "No",
        onSuccess: () => {
          triggerStatusUpdate(data?.id);
        },
        onCancel: () => {
          setState({});
        },
        title: "Alert",
      });
    }
  };

  return (
    <Pagination
      pagination={{
        currentpage: currentpage,
        totalPage: pageDetails?.total_pages,
        handlePageClick: ({ selected }) => {
          setCurrentPage(selected + 1);
        },
      }}
    >
      {/* actiotn bar */}
      <div
        className="flex justify-between absolute w-full right-5 top-16"
        style={{ maxWidth: 448 }}
      >
        <div className="flex flex-1 justify-end">
          <SearchInput {...{ value, setValue }} />
        </div>
      </div>
      <div
        className={`mt-5 ${
          admins?.length || loading ? "grid grid-cols-2 gap-4" : ""
        }`}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4, 5, 6]?.map((el, index) => (
              <div key={index}>
                <AdminList loading={true} />
              </div>
            ))}
          </>
        ) : (
          <>
            <>
              {admins?.length ? (
                <>
                  {admins?.map((el, index) => (
                    <div key={index}>
                      <AdminList
                        data={el}
                        status={status}
                        onPerformAction={(action) =>
                          onPerformAction(action, el)
                        }
                      />
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex justify-center my-10">
                  <div className="max-w-xl w-full">
                    <NoResult message="No Admins Found!" />
                  </div>
                </div>
              )}
            </>
          </>
        )}
      </div>
      <div className="fixed bottom-8 right-8">
        <Button
          variant="primary-filled"
          animate={true}
          className="h-16 px-6 flex items-center text-sm justify-center rounded-full uppercase hover:shadow"
          onClick={() =>
            setState({
              type: "ADD_ADMIN",
              callBack: (values) => addAdmin(values),
            })
          }
        >
          <PlusIcon className="h-5 w-5 text-white text-current mr-2" />
          Add Admin
        </Button>
      </div>
    </Pagination>
  );
};
