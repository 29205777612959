import React, { useEffect, useRef, useState } from "react";
import ProgressStats from "../../Common/ProgressStats";
import FileUpload from "../../Fileupload";
import moment from "moment";
import FileTypeImages from "../../Common/FileTypeImages";
import useLoader from "../../../hooks/useLoader";
import {
  acceptFileType,
  getErrorMessage,
  uploadToAws,
} from "../../../_helper/functions";
import { useToast } from "../../../hooks/useToast";
import { getSignedUrl } from "../../../services/commonServices";
import {
  approveEstimatedService,
  caseAttachment,
  caseRemoveAttachment,
} from "../../../services/caseServices";
import Button from "../../Common/Button";
import useDialog from "../../../hooks/useDialog";

const CaseDetailCard = ({
  data = {},
  setTriggerRefresh = () => {},
  role = "",
}) => {
  const [files, setFiles] = useState([]);
  const { setLoader } = useLoader();
  const { setState } = useDialog();
  const toast = useToast();
  const [selectedFile, setSelectedFiles] = useState([]);
  const uploadRef = useRef();

  useEffect(() => {
    if (data?.attachments?.length) {
      setFiles(data?.attachments);
    }
  }, [data]);

  const triggerLoaderPercentage = () => {
    let overAllPercentage = 0;
    Object.keys(uploadRef.current).forEach((key) => {
      overAllPercentage += uploadRef.current[key];
    });
    setLoader((loader) => ({
      ...loader,
      percentage: Math.floor(overAllPercentage / selectedFile?.length),
    }));
  };

  /**
   * this useeffect will trigger the upload file
   */
  useEffect(() => {
    if (selectedFile?.length) {
      const promises = []; //to store the promise value
      const fileUploadPromise = [];
      selectedFile.forEach((el, index) =>
        promises.push(getSignedUrlAndUpload(el, index))
      );
      Promise.all(promises)
        .then((results) => {
          if (results.length) {
            results.forEach((el, index) =>
              fileUploadPromise.push(triggerFileUpload(el?.url, el.file, index))
            );
            Promise.all(fileUploadPromise)
              .then((res) => {
                addAttachment(res, selectedFile);
                setLoader({ state: false });
              })
              .catch((err) => {
                const message = getErrorMessage(err);
                toast(message);
                setLoader({ state: false });
              });
          }
        })
        .catch((err) => {
          const message = getErrorMessage(err);
          toast(message);
          setLoader({ state: false });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const onRemove = async (index) => {
    if (files[index]?.id) {
      try {
        setLoader({ state: true, message: "Removing file to the cases..." });
        const payload = {
          attachment_id: files[index]?.id,
        };
        const result = await caseRemoveAttachment(payload);
        if (result?.status === 200) {
          const operatedFiles = [...files];
          operatedFiles.splice(index, 1);
          setFiles(operatedFiles);
          toast("success", result?.message);
        }
      } catch (err) {
        const message = getErrorMessage(err);
        toast("error", message);
      } finally {
        setLoader({ state: false });
      }
    }
  };

  const triggerFileUpload = async (url, file, index) => {
    try {
      const result = await uploadToAws(url, file, (event) => {
        const onProgressPrecentage =
          Math.floor((event.loaded * 100) / event.total) || 0;
        uploadRef.current[index] = 0;
        uploadRef.current[index] += onProgressPrecentage;
        triggerLoaderPercentage();
      });
      if (result) {
        return result;
      }
    } catch (err) {
      throw err;
    }
  };

  const getSignedUrlAndUpload = async (file, index) => {
    try {
      const url = await getSignedUrl(data?.uid, file);
      if (url?.response?.url) {
        return {
          url: url?.response?.url,
          file,
        };
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addAttachment = async (attachmentList = [], selectedFiles = []) => {
    try {
      setLoader({ state: true, message: "Adding files to the cases..." });
      const payload = {
        attachable_type: "LegalCase",
        attachable_id: data?.uid,
        attachment_urls: attachmentList,
      };
      const result = await caseAttachment(payload);
      console.log(result);
      if (result) {
        toast("success", "Successfully attachments are added in this case");
        setTriggerRefresh(true);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  const uploadFile = (selectedFiles = []) => {
    uploadRef.current = {};
    setLoader({
      state: true,
      message: `Uploading ${selectedFiles?.length} ${
        selectedFiles?.length > 1 ? "Files" : "File"
      }...`,
      showPercentage: true,
    });
    setSelectedFiles(selectedFiles);
  };

  const approveEstimatedCases = async (value) => {
    try {
      setLoader({ state: true, message: "Approving Selected Service..." });
      const payload = {
        case_service_ids: value?.services,
        note: "",
      };
      const result = await approveEstimatedService(payload);
      if (result) {
        toast("success", result?.message);
        setState({});
        setTriggerRefresh(true);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  const openApproveEstimatedDialog = () => {
    setState({
      type: "APPROVE_SERVICE",
      services: data?.case_services?.filter((el) => el?.status === "estimated"),
      callBack: (value) => {
        if (value?.services?.length) {
          approveEstimatedCases(value);
        } else {
          setState({});
        }
      },
    });
  };

  return (
    <div className="rounded shadow w-full bg-white text-sm">
      {/* over all case Details */}
      <div className="flex items-center flex-1 w-full border-b">
        <div
          className="w-full flex justify-center p-4"
          style={{ maxWidth: "12.5%" }}
        >
          <div className="w-16 h-16">
            <ProgressStats percentage={data?.percentage_completed || 0} />
          </div>
        </div>
        <div
          className="flex flex-col item-center p-4 w-full"
          style={{ maxWidth: "12.5%" }}
        >
          <div className="text-gray-400 text-xs font-medium">Created date:</div>
          <div className="text-sm font-bold">
            {" "}
            {data?.created_at
              ? moment(data.created_at).format("MM/DD/YYYY")
              : "-"}
          </div>
        </div>
        <div
          className="w-full flex flex-col item-center p-4"
          style={{ maxWidth: "12.5%" }}
        >
          <div className="text-gray-400 text-xs font-medium"> Unique ID:</div>
          <div className="text-sm font-bold">{data?.uid || "-"}</div>
        </div>
        <div className="w-full flex flex-col item-center p-4">
          <div className="text-gray-400 text-xs font-medium">Case Name:</div>
          <div className="text-sm font-bold overflow-hidden truncate">
            {data?.title || "-"}
          </div>
        </div>
        {role === "client" &&
          data?.case_services?.filter((el) => el?.status === "estimated")
            ?.length > 0 && (
            <div className="flex justify-end p-4 w-full">
              <Button
                variant="primary-filled"
                className="px-4 py-2"
                onClick={() => openApproveEstimatedDialog()}
              >
                Approve Estimated Service
              </Button>
            </div>
          )}
      </div>

      {/* case main details */}
      <div className="py-4 px-11">
        {/* Overview Container */}
        <div className="py-4">
          <div className="font-bold">Overview</div>
          <div
            className="py-2 font-medium text-gray-500"
            dangerouslySetInnerHTML={{ __html: data?.description || "-" }}
          ></div>
        </div>
        {/*Add file container */}
        <div className="py-4">
          <div className="font-bold">Upload File</div>
          <div className="py-2 h-40 max-w-2xl">
            <FileUpload
              accept={acceptFileType}
              onUpload={(selectedFiles) => {
                uploadFile(selectedFiles);
              }}
            />
          </div>
        </div>
        {/* Added file section */}
        {files?.length ? (
          <div className="py-4">
            <div className="font-bold">Uploaded File</div>
            <div className="py-4 flex flex-wrap">
              <FileTypeImages
                onRemove={(index) => onRemove(index)}
                files={files?.map((el) => {
                  return {
                    name: el?.file_name || el?.name,
                    url: el?.signed_url || "",
                  };
                })}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CaseDetailCard;
