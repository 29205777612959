import * as Yup from "yup";
import { replaceHtmlTag, replaceSplCharFromPhone } from "../_helper/functions";

const CHECKALPHABETS = /^([^0-9]*)$/;

const loginSchema = Yup.object({
  email: Yup.string()
    .email("Please Enter the valid Email id")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const ResetPasswordSchema = Yup.object({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .label("Confirm password")
    .test("passwords-match", "Passwords do not match", function (value) {
      // eslint-disable-next-line no-invalid-this
      return this.parent.password === value;
    }),
});

const signupSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .matches(CHECKALPHABETS, "Name should be alphabetic"),
  firm_name: Yup.string().required("Firmname is required"),
  email: Yup.string()
    .email("Please Enter the valid Email id")
    .required("Email is required"),
  contact_number: Yup.string()
    .required("Contact number is required")
    .test("valid-phone", "Enter valid phone number", function (value) {
      return (
        2 < replaceSplCharFromPhone(value)?.length &&
        replaceSplCharFromPhone(value)?.length === 10
      );
    }),
  ein: Yup.string(),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .label("Confirm password")
    .test("passwords-match", "Passwords do not match", function (value) {
      // eslint-disable-next-line no-invalid-this
      return this.parent.password === value;
    }),
});

const updatePasswordSchema = Yup.object({
  current_password: Yup.string().required("Current Password is required"),
  new_password: Yup.string().required("New Password is required"),
  confirm_password: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

const updateProfile = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .matches(CHECKALPHABETS, "Name should be alphabetic"),
  firm_name: Yup.string().required("Firmname is required"),
  email: Yup.string()
    .email("Please Enter the valid Email id")
    .required("Email is required"),
  contact_number: Yup.number().test(
    "valid-phone",
    "Enter valid phone number",
    function (value) {
      return (
        2 < replaceSplCharFromPhone(value)?.length &&
        replaceSplCharFromPhone(value)?.length === 10
      );
    }
  ),
  ein: Yup.string(),
});

const forgotPasswordSchema = Yup.object({
  email: Yup.string()
    .email("Please Enter the valid Email id")
    .required("Email is required"),
});

const addAdminSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .matches(CHECKALPHABETS, "Name should be alphabetic"),
  email: Yup.string()
    .email("Please Enter the valid Email id")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const updateAdminSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .matches(CHECKALPHABETS, "Name should be alphabetic"),
});

const createLegalCase = Yup.object({
  title: Yup.string().required("Name is required"),
  description: Yup.string()
    .nullable()
    .trim()
    .test("test", "Description is required", function (value) {
      return replaceHtmlTag(value)?.trim()?.length > 0;
    }),
});

const addEstimationSchema = Yup.object({
  estimated_cost: Yup.number().required("Estimated cost is required"),
  estimated_time: Yup.number().required("Estimated time is required"),
});

const scheduleServiceSchema = Yup.object({
  estimated_date: Yup.string().required("Estimated date is required"),
});

const paymentFormSchema = Yup.object({
  paymentId: Yup.string().required("Please enter the required field"),
  paymentAmount: Yup.string()
    .required("Please enter the required field")
    .test(
      "check_greater_amount",
      "Paid amount should not greater than payable amount",
      function (value) {
        return this.parent.payable_amount >= value;
      }
    ),
  transaction_date: Yup.string().required("Please enter the required field"),
});

export {
  loginSchema,
  signupSchema,
  forgotPasswordSchema,
  addAdminSchema,
  createLegalCase,
  addEstimationSchema,
  scheduleServiceSchema,
  updateProfile,
  ResetPasswordSchema,
  updateAdminSchema,
  paymentFormSchema,
  updatePasswordSchema,
};
