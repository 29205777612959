import { Form, Formik } from "formik";
import React from "react";
import useLoader from "../../../hooks/useLoader";
import { useToast } from "../../../hooks/useToast";
import { login, resendMail } from "../../../services/authServices";
import { getErrorMessage } from "../../../_helper/functions";
import { loginSchema } from "../../../_helper/ValidationSchema";
import Button from "../../Common/Button/Button";
import FormFields from "../../Common/FormFields/FormFields";

const loginInputs = [
  {
    type: "email",
    name: "email",
    placeholder: "Enter your email id",
    label: "Email",
  },
  {
    type: "password",
    name: "password",
    placeholder: "Enter your password",
    label: "Password",
  },
];

const LoginForm = ({
  onForgetPassword = () => {},
  onLoginSuccess = () => {},
  role = "client",
}) => {
  const toast = useToast();
  const { setLoader } = useLoader();

  const onFormSubmit = async (values) => {
    try {
      setLoader({ state: true });
      const payload = {
        ...values,
        role: role === "admin" ? ["admin", "super_admin"] : role,
      };
      const res = await login(payload);
      if (res?.message) {
        toast("success", res?.message);
        onLoginSuccess(res?.response?.token);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  const resendEmail = async ({ email }) => {
    try {
      setLoader({ state: true });
      const payload = {
        role: "client",
        email,
      };
      const res = await resendMail(payload);
      if (res?.message) {
        toast("success", res?.message);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <div className="bg-gray-100 rounded border border-gray-200 p-6 w-full h-full">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        enableReinitialize
        onSubmit={onFormSubmit}
        validationSchema={loginSchema}
        validateOnChange
      >
        {({ handleChange, errors, touched, values }) => (
          <Form className="h-full flex flex-col">
            <div className="flex flex-col space-y-4">
              {loginInputs?.map((input, index) => (
                <div key={index}>
                  <FormFields
                    {...{ ...input }}
                    value={values[input?.name]}
                    onChange={handleChange}
                    error={touched[input?.name] && errors[input?.name]}
                  />
                </div>
              ))}
            </div>
            {role === "client" && (
              <div
                className="flex flex-1 justify-end text-sm underline mt-0.5 cursor-pointer"
                onClick={onForgetPassword}
              >
                Forgot password?
              </div>
            )}
            <div className="py-4 flex flex-1 w-full items-end mt-2">
              <Button
                type="submit"
                variant="primary-filled"
                className="px-4 py-2 w-full font-bold"
              >
                Login
              </Button>
            </div>
            {role === "client" && (
              <div className="w-full text-sm mt-0.5 text-center select-none">
                <span
                  className="cursor-pointer underline"
                  onClick={() => {
                    if (!values?.email) {
                      toast("error", "Please enter the email id");
                      return false;
                    }
                    resendEmail(values);
                  }}
                >
                  Click Here
                </span>{" "}
                to receive Verification Email
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
