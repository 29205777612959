import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Editor = ({
  value = "",
  onChange = () => {},
  placeholder = "",
  disabled = false,
  label = "Label",
}) => {
  return (
    <>
      <div className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </div>
      <div className="quil">
        <ReactQuill
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          readOnly={disabled}
        />
      </div>
    </>
  );
};

export default Editor;
