const { REACT_APP_BASE_API_URL } = process.env;

const ApiConfig = {
  baseUrl: REACT_APP_BASE_API_URL,
  urls: {
    auth: {
      login: "/users/login",
      signup: "/users",
      resendMail: "/users/verification_email",
      verifyAccount: "/users/verify_email",
      refreshToken: "/users/refresh_session",
    },
    case: {
      index: "/legal_cases",
      status: "/status",
      getServices: "/legal_cases/services",
      caseAddAttachment: "/attachments/add",
      caseRemoveAttachment: "/attachments/remove",
      updateService: "/case_services",
    },
    user: {
      index: "/users",
      admins: "/users/admins",
      unverifiedUser: "/users/unverified_clients",
      verifiedClient: "/users/verify_client",
      adminIndex: "/admins",
      payments: "/payments",
      verifyUser: "/user",
    },
    blockedList: {
      index: "/blacklists",
    },
    attachement: {
      signedUrl: "/attachments/file_write_url",
    },
  },
};

export default ApiConfig;
