import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import useUser from "../../../hooks/useUser";
import AdminPaymentHistory from "../AdminPaymentHistory";
import UserPaymentHistory from "../UserPaymentHistory";
import _ from "lodash";

const PaymentHistory = () => {
  const [user, setUser] = useState(false);
  const { token } = useUser();
  useEffect(() => {
    if (token && token !== "logged_out") {
      setUser(jwtDecode(token)?.claims);
    }
  }, [token]);
  return (
    <>
      {!_.isEmpty(user) && (
        <>
          {user.role !== "client" ? (
            <AdminPaymentHistory />
          ) : (
            <UserPaymentHistory role={"client"} />
          )}
        </>
      )}
    </>
  );
};

export default PaymentHistory;
