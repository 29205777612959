import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Images } from "../../../assets/image_helper";

const TermsAndCondition = () => {
  const history = useHistory();
  return (
    <div
      className="z-10 w-full h-full flex justify-center items-center bg-cover object-center p-4"
      style={{
        backgroundImage: `url(${Images.loginBg})`,
      }}
    >
      <div className="p-10 m-10 shadow bg-white">
        <div className="font-bold text-lg cursor-pointer">
          {"<"}
          <span className="ml-1" onClick={() => history.push("/signup")}>
            Go Back
          </span>
        </div>
        <h1 className="text-center text-2xl font-bold">Terms and conditions</h1>
        <p className="pt-5">
          Welcome to our portal. If you continue to br/owse and use this portal,
          it confirms your acceptance of the terms and conditions as well as our
          privacy policy, which together govern our relationship with you
          related to this portal.
          <br />
          <br />
          <br />
          1. The content of the pages of this portal is for your general
          information purposes only and shall neither constitute legal advice
          nor an agreement for legal services.
          <br />
          <br />
          2. The portal contains material which is owned by or licensed to us.
          <br />
          <br />
          3. We provide trial offers for the limited use of the services to new
          customers
          <br />
          <br />
          4. We provide high standards of services with due care and skill.
          <br />
          <br />
          5. We regularly update with technology, threats to our security and
          functionality to support law firms and clients.
          <br />
          <br />
          6. We also provide a help desk that is able to receive notifications
          of information regarding technical issues and anything related to the
          cases.
          <br />
          <br />
          7. In order to obtain secure access, you will be asked to choose a
          unique username and password which should be kept confidential and not
          disclose them to any third party.
          <br />
          <br />
          8. You agree to notify us promptly if you become aware or suspect that
          your username or password have been lost or have been used by any
          unauthorized third party.
          <br />
          <br />
          9. You must not misuse our site by knowingly introducing viruses,
          trojans, or other material which is maliciously or technologically
          harmful.
          <br />
          <br />
          10. You must not attempt to gain unauthorized access to our site, the
          server or database connected to our site. By br/eaching this
          provision, you would commit a criminal offence under the law of
          computer misuse act 1990.
          <br />
          <br />
          11. You agree that we may terminate your account or password or use of
          your service immediately if we believe that you have violated or acted
          inconsistently with these terms; if you infringe third party
          intellectual property rights; for lack of use; or if we are unable to
          authenticate any information that you have provided to us.
          <br />
          <br />
          12. These terms and policies referred herein constitute the entire
          agreement between you and us and govern your use of the service.
          <br />
          <br />
          13. We may revise these terms of use at any time by amending this
          page. You are expected to check this page from time to time to take
          notice of any changes we made, as they are binding on you.
          <br />
          <br />
          14. This portal stores cookies on your computer. These cookies are
          used to collect information about how you interact with our portal and
          allow us to remember you. We use this information in order to improve
          and customize your br/owsing experience and for analytics and metrics
          about our visitors both on this portal and other media. To find out
          more about the cookies we use, see our Privacy Policy.
          <br />
          <br />
          15. The Software and Services are licensed, not sold, solely for use
          by Client and its Users under the terms of this Agreement. Except as
          specifically set forth herein, Medzlegal and the Affiliated Entities
          retain all right, title, and interest, including all intellectual
          property and moral rights, relating to or embodied in the Software or
          Services, including without limitation all technology, hardware,
          software, systems and copies of the foregoing relating to the Software
          or Services. All graphics, logos, service marks, and trade names,
          including third-party names, product names, and br/and names
          (collectively, the "Marks") relating to the Software or Services are
          the trademarks of Medzlegal and/or the Affiliated Entities. You are
          prohibited from using any Marks without the prior written permission,
          which shall be at the sole and exclusive discretion, of Medzlegal or
          the applicable Affiliated Entities. Medzlegal reserves all rights and
          interests not expressly granted in this Agreement, and no direct or
          indirect ownership interest or license right in or to the Medzlegal of
          Affiliated Entities' Marks, the Services, Software or any other
          Medzlegal or Affiliate Entity intellectual property is granted to
          Client or created by implication in this Agreement. Unless You direct
          otherwise, You agree that Medzlegal may list You as a client of the
          firm in our marketing, business development and/or public relations
          materials and announcements.
          <br />
          <br />
          16. Medzlegal will perform regular maintenance. In certain
          circumstances Medzlegal may need to perform emergency maintenance and
          will, to the extent reasonably possible, provide advance notice of
          such emergencies. Any such maintenance, whether regular or emergency,
          will not in any way qualify as a Services "Unavailability".
          <br />
          <br />
          17. You are fully responsible for the security of data on Your site or
          otherwise in Your possession. You agree to comply with all applicable
          state and federal laws and rules in connection with Your collection,
          security, and dissemination of any protected health information (PHI)
          on Your site. You agree that at all times You shall be compliant with
          HIPAA and HITECH requirements, as applicable. The steps You will need
          to take to comply with HIPAA and HITECH when using Medzlegal will vary
          based on Your implementation. If we believe that it is necessary based
          on Your implementation and we request it of You, then You will
          promptly provide us with documentation evidencing Your compliance with
          HIPAA and HITECH. You also agree that You will use only HIPAA
          compliant service providers in connection with the storage, or
          transmission of PHI. Information on the HIPAA rules can be found on
          the HHS website. It is also your responsibility to comply with these
          standards.
          <br />
          <br />
          18. You agree that Medzlegal and the affiliated entities will not be
          liable for any content, including but not limited to content that is
          sent, received, held, released or otherwise connected in any respect
          to the software or services, content that is uploaded or downloaded
          but not received, and content sent using and/or included in the
          services, including without limitation any threatening, defamatory,
          obscene, offensive, or illegal content; further, you agree that
          Medzlegal and the affiliated entities will not be liable for any
          access to or alteration of such content or for any infringement of
          another's rights, including privacy, intellectual property, or data
          protection rights arising from such client content or from the conduct
          of client or third parties other than which may be the result of
          Medzlegal's willful actions.
          <br />
          <br />
          19. The Affiliated Entities are third party beneficiaries to this
          agreement. There are no other third-party beneficiaries to this
          agreement. No party may assign or otherwise transfer this agreement,
          or any rights or obligations hereunder, whether by contract, operation
          of law, or otherwise without the express written consent of the other
          party to the agreement, except that Medzlegal may assign or otherwise
          transfer this agreement, any of its rights or obligations under this
          agreement, and/or any content provided to Medzlegal pursuant to this
          agreement, to one (1) or more of the Affiliated Entities, or by way of
          merger, acquisition or sale of all or substantially all of its assets
          to any third party or Affiliated Entity or as part of a corporate
          reorganization, without your consent. This agreement will be binding
          on and inure to the benefit of Medzlegal and client and their
          respective permitted successors and permitted assigns.
          <br />
          <br />
          20. Medzlegal may amend this Agreement (including any SLA, AUP or
          Privacy Policy) from time to time by posting an amended version at its
          portal or sending you written notice in accordance with this
          Agreement's notice provisions. Such modifications will be deemed
          accepted and become effective after such notice (the "Proposed
          Amendment Date") unless you give Medzlegal written notice of your
          rejection of the amendment(s). Your continued use of Services
          following any Proposed Amendment Date stated in the portal posting or
          writing notice will confirm your consent to any modifications. This
          Agreement may not be otherwise amended by you in any other way except
          through a written agreement executed by authorized representatives of
          each party. Notwithstanding the foregoing, Provider may amend the AUP
          or Privacy Policy at any time by posting a new version at its portal
          and sending or providing you with notice, and any amended version will
          become effective 7 business days after such notice is sent.
          <br />
          <br />
          21. We use and develop advanced technologies such as artificial
          intelligence, machine learning systems and augmented reality so that
          people can use our Products safely regardless of physical ability or
          geographic location.
          <br />
          <br />
          22. You agree to defend, indemnify, and hold harmless Medzlegal from
          and against any and all claims and liabilities (including, but not
          limited to, fees, costs and other reasonable expenses of attorneys and
          expert witnesses, but excluding any loss, liabilities, costs, damages
          or other expenses attributable solely to Medzlegal's negligent
          acts/omissions or wilful misconduct) made by any third party related
          to or arising from: (i) Your use of the Software or Services
          (including without limitation, any User or person accessing the
          Services using Your password or access key with or without Your
          permission), (ii) any actual or alleged violation of this Agreement or
          applicable law, and/or (iii) any actual or alleged infringement or
          violation (by You or any User or person accessing the Services using
          Your password or access key with or without Your permission) of any
          intellectual property or privacy or other right of any person or
          entity. Notwithstanding the foregoing Your potential indemnification
          liability shall exclude, to the extent permitted by applicable state,
          federal or international laws, any special, incidental, punitive or
          consequential damages to Medzlegal or its Affiliates other than those
          arising from or related to Your negligent or wilful: (x)
          misappropriation of Content or trade secrets; (y) actual or alleged
          infringement of a third party's intellectual property rights; or (z)
          br/each of the confidentiality obligations provided.
          <br />
          <br />
          23. These Terms will remain in full force and effect as long as you
          continue to access or use the site. We may, with or without prior
          notice, terminate any of the rights granted by these Terms without
          cause. You shall comply immediately with any termination or other
          notice, including, as applicable, by ceasing all use of the site.
          <br />
          <br />
        </p>
        <h1 className="text-center text-2xl font-bold">Definitions</h1>
        <p className="pt-5">
          Affiliated Entities or Affiliates means any licensors and suppliers
          providing any or part of the software and/or services; and all
          subsidiaries, affiliates, directors, officers, employees, consultants,
          and agents of Medzlegal and of any of the forgoing
          <br />
          <br />
          Confidential Information means all non-public information disclosed by
          Medzlegal and our Affiliates or by You that is designated as
          confidential or that, given the nature of the information or
          circumstances surrounding its disclosure, reasonably should be
          understood to be confidential. Confidential Information includes: (a)
          non-public information relating to a party's technology, customers,
          business plans, promotional and marketing activities, finances and
          other business affairs; (b) third-party information that a party is
          obligated to keep confidential; and (c) the nature, content and
          existence of discussions or negotiations between the parties.
          Confidential Information does not include any information that: (i) is
          or becomes publicly available without breach of this Agreement; (ii)
          can be shown by documentation to have been known to the receiving
          party at the time of receipt from the disclosing party; (iii) is
          received from a third party who did not acquire or disclose the same
          by a wrongful or tortious act; or (iv) can be shown by documentation
          to have been independently developed by a party without reference to
          the other party's Confidential Information.
          <br />
          <br />
          Content means any and all information, data, and protected health
          information (PHI), regardless of the file format or file contents (and
          includes, without limitation, all photographs, text, images, audio
          clips, sounds, videos, databases, e-mails, computer programs, as well
          as any other files) uploaded by or for You in connection with Your use
          of the Services.
          <br />
          <br />
          PHI or protected health information, means Individually Identifiable
          Health Information, as defined in 45 CFT 160.103, that is transmitted
          in any medium that identifies an individual or creates a reasonable
          basis to believe the information can be used to identify an individual
          and relates to (i) the past, present or future physical or mental
          health or condition of an individual; (ii) the provision of health
          care to an individual; or (iii) the past, present or future payment
          for the provision of health care to an individual. Protected Health
          Information is limited to that information received by Medzlegal from
          or on behalf of the Client.
          <br />
          <br />
          Term means the duration of this Agreement commencing with the earliest
          of the date: (i) that Medzlegal accepts Client's order for Services,
          or (ii) that Client first accesses or uses the Services, and
          continuing until the end of the applicable Services period as
          designated by Medzlegal either pursuant to the Services Order, subject
          to earlier termination in accordance with this Agreement. The initial
          term of engagement shall automatically renew for an additional one (1)
          year period, unless either party delivers written notice of
          non-renewal to the other party at least thirty (30) calendar days
          before a term expires.
          <br />
          <br />
          User(s) means the individual(s) accessing or using the Medzlegal
          services for or on behalf of the Client.
          <br />
          <br />
        </p>
        <h1 className="text-center text-2xl font-bold">Privacy Policy</h1>
        <p className="pt-5">
          1. We are committed in protecting and respecting your privacy and
          confidentiality when dealing with your personal information.
          <br />
          <br />
          2. We have appropriate organized technical security measures in place
          to prevent unauthorized access or unlawful processing of data.
          <br />
          <br />
          3. We ensure that any information transferred to our portal is via an
          encrypted connection. Once we have received your information, we will
          use strict procedures and security features for prevention of
          unauthorized access.
          <br />
          <br />
          4. Our premises are secured with biometric access and surveyed by CCTV
          for the purposes of security and the safe provision of care.
          <br />
          <br />
          5. We have individual teams for estimation, production and quality
          analysis.
          <br />
          <br />
          6. We follow multiple levels of audit to provide a qualitative work
          product.
          <br />
          <br />
          7. We have well differentiated teams with experts for individual law
          firms.
          <br />
          <br />
          8. We follow an intricate case screening protocol.
          <br />
          <br />
          9. We have an advanced technical support team for establishing,
          recommending and implementing policies to ensure quality, timely and
          efficient design of customer oriented services.
          <br />
          <br />
          10. We also have specialized customer service support team to provide
          information about products/services and to help solve customer
          problems.
          <br />
          <br />
          11. We have individual special subject matter experts to deal with
          critical cases.
          <br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default TermsAndCondition;
