import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Images } from "../../../assets/image_helper";
import useUser from "../../../hooks/useUser";
import ResetTimer from "../../Common/ResetTimer/ResetTimer";
import jwt_decode from "jwt-decode";
import { resendMail } from "../../../services/authServices";
import { getErrorMessage } from "../../../_helper/functions";
import { useToast } from "../../../hooks/useToast";
import useLoader from "../../../hooks/useLoader";

const PendingEmailVerification = () => {
  const { setToken, token } = useUser();
  const history = useHistory();
  const toast = useToast();
  const { setLoader } = useLoader();
  const [userObj, setUserObject] = useState({
    role: "",
    email: "",
  });
  useEffect(() => {
    if (token && token !== "logged_out") {
      const userObj = jwt_decode(token)?.claims;
      setUserObject(userObj);
    }
  }, [token]);

  const gotoLogin = () => {
    if (token) {
      setToken("logged_out");
    }
    history.push("/client/login");
  };

  const resendEmail = async () => {
    try {
      setLoader({ state: true });
      const payload = {
        role: userObj?.role,
        email: userObj.email,
      };
      const res = await resendMail(payload);
      if (res?.message) {
        toast("success", res?.message);
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };
  return (
    <div className="relative min-h-screen overflow-y-auto">
      <div
        className="absolute z-10 w-full h-full flex justify-center items-center bg-cover object-center p-4"
        style={{
          backgroundImage: `url(${Images.loginBg})`,
        }}
      >
        <div className="p-8 shadow rounded max-w-md w-full bg-white">
          {/* <--Logo image --> */}
          <div className="flex justify-center">
            <div className="w-full" style={{ maxWidth: 230 }}>
              <img src={Images.companyLogo} alt="company-logo" />
            </div>
          </div>
          <div className="text-center flex  flex-col items-center justify-center">
            <div className="text-sm text-gray-600 my-12">
              You’re almost done! A verification message has been sent to your
              mail <i className="font-bold">({userObj?.email})</i>. Just check
              your email and follow the link to finish creating your MedzLegal
              account.
            </div>
            <ResetTimer
              message="Can’t find the email?"
              resend={resendEmail}
              resendMessage="Resend verification email"
              timer={60}
              defaultRunning={false}
            />
            <div
              className="text-center mt-4 text-indigo-600 underline cursor-pointer"
              onClick={() => gotoLogin()}
            >
              Go to login
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingEmailVerification;
