import API from "../api/manager";
import config from "../api/config";

const login = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.login,
      data,
    },
    false
  );
};

const signUp = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.signup,
      data,
    },
    false
  );
};

const resendMail = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.resendMail,
      data,
    },
    false
  );
};

const verifyAccount = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.verifyAccount,
      data,
    },
    false
  );
};

const refreshToken = (data) => {
  return API({
    method: "GET",
    url: config.urls.auth.refreshToken,
  });
};

const forgetPassword = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.user.index + "/forgot_password",
      data,
    },
    false
  );
};

const resetPassword = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.user.index + "/reset_password",
      data,
    },
    false
  );
};

export {
  login,
  signUp,
  resendMail,
  verifyAccount,
  refreshToken,
  forgetPassword,
  resetPassword,
};
