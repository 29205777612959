import API from "../api/manager";
import config from "../api/config";

const getCases = (params) => {
  return API({
    method: "GET",
    url: config.urls.case.index + params,
  });
};

const getDeleteReqCases = (params) => {
  return API({
    method: "GET",
    url: `${config.urls.case.index}/deletion_requests${params}`,
  });
};

const getServices = () => {
  return API({
    method: "GET",
    url: config.urls.case.getServices,
  });
};

const createCase = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.index,
    data,
  });
};

const getCaseDetails = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.index + "/" + id,
  });
};

const caseAttachment = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.caseAddAttachment,
    data,
  });
};

const caseRemoveAttachment = (data) => {
  return API({
    method: "POST",
    url: config.urls.case.caseRemoveAttachment,
    data,
  });
};

const caseServiceStatusUpdate = (id, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.updateService}/${id}/update_status`,
    data,
  });
};

const deleteService = (id) => {
  return API({
    method: "POST",
    url: `${config.urls.case.updateService}/${id}/request_delete`,
    data: {},
  });
};

const getComments = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.updateService}/${id}/comments`,
  });
};

const readCommad = (id) => {
  return API({
    method: "POST",
    url: `${config.urls.case.updateService}/${id}/read_messages`,
    data: {},
  });
};

const postComment = (id, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.updateService}/${id}/comment`,
    data,
  });
};

const postServices = (caseId, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.index}/${caseId}/case_services`,
    data,
  });
};

const approveEstimatedService = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.updateService}/approve`,
    data,
  });
};

const getCaseStasts = () => {
  return API({
    method: "GET",
    url: `${config.urls.case.index}/cases_count`,
  });
};

const rejectCaseRequest = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.updateService}/reject`,
    data,
  });
};

const adminRejectUpdate = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.updateService}/update_deletion_request`,
    data,
  });
};

const checkIsUserVerified = () => {
  return API({
    method: "GET",
    url: `${config.urls.user.index}/status`,
  });
};

export {
  getCases,
  getServices,
  createCase,
  getCaseDetails,
  caseAttachment,
  caseRemoveAttachment,
  caseServiceStatusUpdate,
  getComments,
  postComment,
  deleteService,
  postServices,
  readCommad,
  approveEstimatedService,
  getCaseStasts,
  rejectCaseRequest,
  getDeleteReqCases,
  adminRejectUpdate,
  checkIsUserVerified,
};
