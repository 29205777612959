import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ children, loading, pagination, ...rest }) => {
  return (
    <section className="w-full mx-auto h-full" {...rest}>
      {children}
      {pagination?.totalPage > 1 && !loading && (
        <div className="mt-8 flex justify-start pr-2 sm:pr-0">
          <ReactPaginate
            breakLabel={"..."}
            pageCount={pagination.totalPage}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={(e) => {
              pagination.handlePageClick(e);
              document.documentElement.scrollTop = 0;
            }}
            forcePage={pagination.currentpage - 1}
            containerClassName={`flex space-x-3 text-theme-blue-900 ${pagination.containerClassName}`}
            disabledClassName="bg-white"
            pageClassName="w-12 h-12 flex bg-white rounded-lg cursor-pointer"
            pageLinkClassName="focus:outline-none w-full h-full flex items-center justify-center"
            activeClassName="bg-indigo-600 text-white font-medium"
            previousClassName={`${
              pagination.currentpage === 1 ? "hidden" : ""
            } sm:bg-white rounded-lg flex justify-center items center`}
            previousLinkClassName="px-2 sm:px-5 py-3 focus:outline-none"
            nextClassName={`${
              pagination.totalPage === pagination.currentpage ? "hidden" : ""
            } sm:bg-white rounded-lg flex justify-center items center`}
            nextLinkClassName="px-2 sm:px-5 py-3 focus:outline-none"
            previousLabel={`${"Prev"}`}
            nextLabel={`${"Next"}`}
            breakClassName="w-12 h-12 flex bg-white mx-2 rounded cursor-pointer"
            breakLinkClassName="focus:outline-none w-full h-full flex items-center justify-center"
          />
        </div>
      )}
    </section>
  );
};

export default Pagination;
