import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

//Layout
import Layout from "./Components/Internal/Layout";

//Component
import Cases from "./Components/Pages/Cases";
import Login from "./Components/Pages/Login";
import Signup from "./Components/Pages/Signup";
import ForgotPassword from "./Components/Pages/ForgotPassword";
import VerifyUser from "./Components/Pages/VerifyUser";
import BlockedEmails from "./Components/Pages/BlockedEmails";
import CreateCase from "./Components/Pages/CreateCase";
import ViewCase from "./Components/Pages/ViewCase";
import PrivateRoute from "./Routes/PrivateRoutes";
import PublicRoutes from "./Routes/PublicRoutes";
import PendingEmailVerification from "./Components/Pages/PendingEmailVerification";
import PendingAccountVerification from "./Components/Pages/PendingAccountVerification";
import UserVerification from "./Components/Pages/UserVerification";
import UserPaymentHistory from "./Components/Pages/UserPaymentHistory";
import PaymentHistory from "./Components/Pages/paymentHistory";
import MyProfile from "./Components/Pages/MyProfile";
import ResetPassword from "./Components/Pages/ResetPassword";
import AdminListWrapper from "./Components/Pages/AdminListWrapper";
import TermsAndCondition from "./Components/Internal/TermsAndCondition";
// import PageNotFound from "./Components/Pages/PageNotFound/PageNotFound";

const AppRouter = () => {
  return (
    <>
      <Router>
        <Switch>
          <Redirect from="/support/authenticate" to="/support/login" exact />
          <Redirect from="/client/authenticate" to="/client/login" exact />
          <Redirect from="/" to="/client/login" exact />
          {/* <Route path="*" exact={true} component={PageNotFound} /> */}
          <PublicRoutes path="/client/login" component={Login} />
          <PublicRoutes path="/support/login" component={Login} role="admin" />
          <PublicRoutes path="/signup" component={Signup} />
          <PublicRoutes path="/forgot-password" component={ForgotPassword} />
          <PublicRoutes path="/reset_password" component={ResetPassword} />
          <PublicRoutes path="/terms-condition" component={TermsAndCondition} />
          <PublicRoutes
            path="/support/forgot-password"
            component={ForgotPassword}
            role="admin"
          />
          <Route
            path="/pending-email-verification"
            component={PendingEmailVerification}
          />
          <Route
            path="/pending-account-verification"
            component={PendingAccountVerification}
          />
          <Route
            path="/verify-user"
            render={() => (
              <UserVerification
                failedMessage="Sorry!!! Email Verification Failed."
                failedSubMessage="Please use the correct link to verify your email id."
              />
            )}
          />
          <Layout>
            <PrivateRoute path="/cases" component={Cases} />
            <PrivateRoute path="/my-profile" component={MyProfile} />
            <PrivateRoute path="/new-case" component={CreateCase} />
            <PrivateRoute path="/cases/:slug" component={ViewCase} />
            <PrivateRoute path="/payments" component={PaymentHistory} />
            <PrivateRoute
              path="/payments/:id"
              component={UserPaymentHistory}
              role="admin"
            />
            <PrivateRoute path="/verify-users" component={VerifyUser} />
            <PrivateRoute path="/admins" component={AdminListWrapper} />
            <PrivateRoute path="/blocked-emails" component={BlockedEmails} />
          </Layout>
        </Switch>
      </Router>
    </>
  );
};

export default AppRouter;
