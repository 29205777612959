import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";

function PrivateRoute({ component: Component, excludePath, ...rest }) {
  const token = localStorage.getItem("auth_token");
  const excludePathForAccountVerification = ["/new-case", "/my-profile"];
  const userObj =
    token && token !== "logged_out" ? jwt_decode(token)?.claims : null;

  const renderComponent = (props) => {
    if (userObj) {
      if (userObj?.role === "client" && !userObj?.email_verified_at) {
        return <Redirect to="/pending-email-verification" />;
      } else if (userObj?.role === "client" && !userObj?.account_verified_at) {
        if (!excludePathForAccountVerification?.includes(rest?.path)) {
          return <Redirect to="/new-case" />;
        }
      }
      return <Component {...{ ...props, ...rest }} />;
    } else {
      return <Redirect to="/" />;
    }
  };

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      exact={true}
      render={(props) => renderComponent(props, rest)}
    />
  );
}

export default PrivateRoute;
