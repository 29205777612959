import React, { useState } from "react";
import { bytesToSize } from "../../../_helper/functions";
import Button from "../../Common/Button";
import FileUpload from "../../Fileupload";
import { XIcon } from "@heroicons/react/outline";

const AddFileUpload = ({ state = {} }) => {
  const [files, setFiles] = useState(state?.files?.length ? state?.files : []);
  const uploadFile = () => {
    state?.callBack(files);
  };
  return (
    <div>
      <div className="text-center text-xl font-bold">Upload Files</div>
      <div className="py-2">
        <div className="h-full flex flex-col">
          <div className="flex flex-col space-y-4">
            <div className="w-full h-32">
              <FileUpload
                hasPopupConfirmation={false}
                accept="image/*,.doc, .docx,.txt,.pdf, .tif, .rtf,.ppt,"
                onUpload={(selectedFiles) => {
                  setFiles([...files, ...selectedFiles]);
                }}
              />
            </div>
            {files?.length ? (
              <>
                <div className="font-bold text-base">Selected Files:</div>
                {files?.map((el, index) => (
                  <div key={index} className="flex items-center">
                    <div className="font-semibold">
                      {el?.name}
                      <span className="font-normal">
                        ({bytesToSize(el?.size)})
                      </span>
                    </div>
                    <div
                      className="ml-2 cursor-pointer"
                      onClick={() => {
                        const afterRemove = [...files];
                        afterRemove?.splice(index, 1);
                        setFiles(afterRemove);
                      }}
                    >
                      <XIcon className="w-4 h-4 text-red-600" />
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>

          <div className="pt-8 flex flex-1 w-full items-end ">
            <Button
              type="button"
              variant="primary-filled"
              disabled={files?.length < 1}
              className="px-4 py-2 w-full font-bold"
              onClick={() => uploadFile()}
            >
              Upload
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFileUpload;
