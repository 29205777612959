import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce/lib";
import queryString from "query-string";
import NoResult from "../../Common/NoResult";
import SearchInput from "../../Common/SearchInput";
import PendingHistory from "./components/PendingHistory";
import { getUserPayments, postPayments } from "../../../services/userServices";
import CompletedPayments from "../../Internal/CompletedPayments";
import PaypalButton from "../../Common/PaypalButton";
import { formatToCurrency, getErrorMessage } from "../../../_helper/functions";
import { useToast } from "../../../hooks/useToast";
import Button from "../../Common/Button";
import useDialog from "../../../hooks/useDialog";
import useLoader from "../../../hooks/useLoader";

const UserPaymentHistory = ({ match, role }) => {
  const [payments, setPayment] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const [text] = useDebounce(value, 1000);
  const [selectedService, setSelectedService] = useState([]);
  const { setState } = useDialog();
  const toast = useToast();
  const { setLoader } = useLoader();

  async function getAllUserPayments(id) {
    setLoading(true);
    try {
      const params = {};
      if (id) {
        params.user_id = id;
      }
      if (text) {
        params.term = text;
      }
      const stringParams = `?${queryString.stringify(params)}`;
      const result = await getUserPayments(stringParams);
      if (result?.response) {
        setPayment(result?.response);
      }
    } catch (err) {
      console.log(err);
      setPayment([]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (match?.params?.id || role === "client") {
      getAllUserPayments(match?.params?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, match?.params?.id, role]);

  const getServiceId = (pendingPayments = []) => {
    const service_id = [];
    pendingPayments?.forEach((el) => {
      el?.services?.forEach((service) => {
        service_id.push(service?.uid);
      });
    });
    return service_id;
  };

  const afterPaymentSuccess = async (data) => {
    try {
      setLoading(true);
      const payload = {
        mode: "default",
        kind: "paid",
        payload: data,
        payable_amount: payments?.user?.payment_pending,
        case_service_references: getServiceId(payments?.pending_payments),
      };
      const res = await postPayments(payload);
      if (res?.message) {
        toast("success", res?.message);
        getAllUserPayments(match?.params?.id);
      }
    } catch (e) {
      const message = getErrorMessage(e);
      toast("error", message);
    } finally {
      setLoading(false);
    }
  };

  const getSelectedPayment = (pendingPayments) => {
    const service_id = [];
    pendingPayments?.forEach((el) => {
      el?.services
        ?.filter((el) => selectedService?.includes(el?.uid))
        .forEach((service) => {
          service_id.push(service?.uid);
        });
    });
    return service_id;
  };

  const getTotalAmount = (pendingPayments) => {
    const service_id = [];
    pendingPayments?.forEach((el) => {
      el?.services
        ?.filter((el) => selectedService?.includes(el?.uid))
        .forEach((service) => {
          service_id.push(service?.estimated_cost);
        });
    });
    return service_id.reduce((partialSum, a) => partialSum + a || 0, 0);
  };

  const proceedToOfflinePayment = async (data) => {
    try {
      setLoader({ state: true });
      const payload = {
        kind: "paid",
        mode: data?.mode,
        reference:
          data?.mode === "invoice" ? "transaction_number" : "cheque_number",
        paid_amount: data?.paymentAmount,
        payable_amount: data?.payable_amount,
        case_service_references: getSelectedPayment(payments?.pending_payments),
        transaction_date: data?.transaction_date,
      };
      const res = await postPayments(payload);
      if (res?.message) {
        toast("success", res?.message);
        setState({});
        getAllUserPayments(match?.params?.id);
      }
    } catch (e) {
      const message = getErrorMessage(e);
      toast("error", message);
    } finally {
      setLoader({});
    }
  };

  const openOfflinePaymentModal = (amount) => {
    setState({
      type: "PAY_OFFLINE",
      totalAmount: amount,
      callBack: (details) => proceedToOfflinePayment(details),
    });
  };

  return (
    <div>
      <div className="flex items-center">
        <div className="text-2xl font-bold uppercase">All Payments</div>
        <div className="flex flex-1 justify-end">
          <SearchInput {...{ value, setValue }} />
        </div>
      </div>
      <div className="mt-5 flex flex-col space-y-4">
        {loading ? (
          <>
            {[1, 2, 3, 4, 5, 6]?.map((el) => (
              <div key={el}>
                <PendingHistory loading={true} />
              </div>
            ))}
          </>
        ) : (
          <>
            {payments?.pending_payments?.length ||
            payments?.completed_payments?.length ? (
              <>
                {payments?.pending_payments?.length ? (
                  <>
                    <div className="bg-white p-4 rounded shadow">
                      <div className="flex items-center">
                        <div className="font-semibold text-xl pb-4 flex flex-1">
                          Pending payments
                        </div>
                        <div className="flex space-x-6">
                          <div className="font-semibold">
                            Total Amount:{" "}
                            {formatToCurrency(payments?.user?.payment_pending)}
                          </div>
                          {role === "client" && (
                            <PaypalButton
                              amount={Number(payments?.user?.payment_pending)}
                              onsuccess={afterPaymentSuccess}
                            />
                          )}
                        </div>
                      </div>
                      {payments?.pending_payments?.map((el, index) => (
                        <div key={index}>
                          <PendingHistory
                            data={el}
                            {...{ role, selectedService, setSelectedService }}
                          />
                        </div>
                      ))}
                      {role !== "client" && (
                        <div className="py-4 flex justify-end">
                          <Button
                            variant="primary-filled"
                            disabled={!selectedService?.length}
                            onClick={() => {
                              const totalAmount = getTotalAmount(
                                payments?.pending_payments
                              );
                              openOfflinePaymentModal(totalAmount);
                            }}
                          >
                            Pay Offline
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
                {payments?.completed_payments?.length ? (
                  <>
                    <div className="bg-white p-4 rounded shadow">
                      <div className="flex items-center">
                        <div className="font-semibold text-xl pb-4">
                          Completed Payments
                        </div>
                      </div>
                      {payments?.completed_payments?.map((el, index) => (
                        <div key={index}>
                          <CompletedPayments data={el} />
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </>
            ) : (
              <div className="flex justify-center my-10">
                <div className="max-w-xl w-full">
                  <NoResult message="No Payment Found!" />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserPaymentHistory;
