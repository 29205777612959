import React from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { formatToCurrency } from "../../../_helper/functions";
import { useHistory } from "react-router";

const PaymentList = ({ loading = false, data = {}, isPaidView = false }) => {
  const history = useHistory();
  if (loading) {
    return (
      <div className="py-4 bg-white rounded-lg shadow-sm border border-gray-300 animate-pulse">
        <div className="flex items-center flex-1 w-full">
          <div className="w-1/4 flex flex-col px-4">
            <div className="h-4 w-24 rounded bg-gray-200 mb-2"></div>
            <div className="h-3 w-20 bg-gray-200 rounded"></div>
          </div>
          <div className="w-1/3 flex flex-col px-4">
            <div className="h-4 w-32 rounded bg-gray-200 mb-2"></div>
            <div className="h-3 w-28 bg-gray-200 rounded"></div>
          </div>
          <div className="w-1/4 flex flex-col px-4">
            <div className="h-4 w-24 rounded bg-gray-200 mb-2"></div>
            <div className="h-3 w-20 bg-gray-200 rounded"></div>
          </div>
          <div className="flex-1 flex flex-col px-4"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="py-4 bg-white rounded-lg shadow-sm border border-gray-300">
      <div className="flex items-center flex-1 w-full">
        <div className="w-1/4 flex flex-col px-4">
          <div className="text-gray-400 text-xs font-medium">User Name:</div>
          <div className="text-sm font-bold">{data?.name}</div>
        </div>
        <div className="w-1/3 flex flex-col px-4">
          <div className="text-gray-400 text-xs font-medium">Firm Name:</div>
          <div className="text-sm font-bold">{data?.firm_name}</div>
        </div>
        {isPaidView ? (
          <div className="w-1/4 flex flex-col px-4">
            <div className="text-gray-400 text-xs font-medium">
              Paid Amount:
            </div>
            <div className="text-sm font-bold">
              {formatToCurrency(data?.payment_made || 0)}
            </div>
          </div>
        ) : (
          <div className="w-1/4 flex flex-col px-4">
            <div className="text-gray-400 text-xs font-medium">
              Amount To Pay:
            </div>
            <div className="text-sm font-bold">
              {formatToCurrency(data?.payment_pending || 0)}
            </div>
          </div>
        )}
        <div
          className="flex-1 flex flex-col px-4"
          onClick={() => history.push(`/payments/${data?.id}`)}
        >
          <ArrowRightIcon className="h-6 w-6 text-indigo-600 cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default PaymentList;
