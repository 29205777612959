export const Images = {
  companyLogo: require("./images/zecure.png").default,
  loginBg: require("./images/medz--login-bg.png").default,
  loading: require("./images/loading.svg").default,
  noResultFound: require("./images/no-result-found.svg").default,
  textFileType: require("./images/attachment-file.png").default,
  documentFileType: require("./images/doc-icon.png").default,
  imageFileType: require("./images/image-icon.png").default,
  pdfFileType: require("./images/pdf-icon.png").default,
  pptFileType: require("./images/powerpoint.jpeg").default,
  excelFileType: require("./images/excel.png").default,
  normalFile: require("./images/normal.png").default,
  notFoundImage: require("./images/404page.gif").default,
  medzStatic: require("./images/medz-logo.png").default,
};
