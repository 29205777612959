import API from "../api/manager";
import config from "../api/config";

const getBlokedList = (params) => {
  return API({
    method: "GET",
    url: config.urls.blockedList.index + params,
  });
};

const addBlockData = (data) => {
  return API({
    method: "POST",
    url: config.urls.blockedList.index,
    data,
  });
};

const deleteBlockData = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.blockedList.index + "/" + id,
  });
};

export { getBlokedList, addBlockData, deleteBlockData };
