import React, { useState } from "react";
import ExpansionPanel from "../../Common/ExpansionPanel/ExpansionPanel";
import moment from "moment";
import { formatToCurrency } from "../../../_helper/functions";
import ProgressStats from "../../Common/ProgressStats";
import { CheckIcon } from "@heroicons/react/solid";

const CompletedPayments = ({ data = {} }) => {
  const [expand, setExpand] = useState(true);
  return (
    <ExpansionPanel
      {...{ expand, setExpand }}
      header={<Header {...{ data }} />}
    >
      {data?.case_services?.map((el, index) => (
        <div className="mx-4 py-4">
          <PaidCaseList data={el} />
        </div>
      ))}
    </ExpansionPanel>
  );
};

export default CompletedPayments;

const CaseHeader = ({ data }) => {
  return (
    <div className="flex items-center flex-1 w-full">
      <div className="w-1/5 flex justify-center p-4">
        <div className="w-16 h-16">
          <ProgressStats percentage={data?.percentage_completed || 0} />
        </div>
      </div>
      <div className="flex flex-col item-center p-4 w-1/5">
        <div className="text-gray-400 text-xs font-medium">Created date:</div>
        <div className="text-sm font-bold">
          {data?.created_at
            ? moment(data.created_at).format("MM/DD/YYYY")
            : "-"}
        </div>
      </div>
      <div className="w-1/5 flex flex-col item-center p-4">
        <div className="text-gray-400 text-xs font-medium"> Unique ID:</div>
        <div className="text-sm font-bold">{data?.uid}</div>
      </div>
      <div className="w-1/5 flex flex-col item-center p-4">
        <div className="text-gray-400 text-xs font-medium">Case Name:</div>
        <div className="text-sm font-bold overflow-hidden truncate">
          {data?.title}
        </div>
      </div>
      <div className="w-1/5 flex flex-col justify-center item-end p-4">
        <div className="flex bg-green-500 rounded px-2 py-1 text-white w-min font-bold items-center">
          <CheckIcon className="w-4 h-4 text-current mr-1" />
          <div className="text-xs">Paid</div>
        </div>
      </div>
    </div>
  );
};

const PaidCaseList = ({ data }) => {
  return (
    <div className="border rounded shadow">
      <CaseHeader data={data?.legal_case} />
      <div className="border-t">
        <div className="grid grid-cols-3 py-4 px-8 gap-4">
          {data.case_services?.map((el, index) => (
            <div
              key={index}
              className="w-full border shadow rounded flex text-sm font-semibold"
            >
              <div className="border-r pl-3 w-3/4 py-3">{el?.service_name}</div>
              <div className="flex flex-1 py-3 pl-3">
                {formatToCurrency(el?.estimated_cost)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Header = ({ data = {} }) => {
  return (
    <div className="flex items-center flex-1 w-full border-b">
      <div className="flex flex-col item-center p-4 w-1/3">
        <div className="text-gray-400 text-xs font-medium">Payment ID:</div>
        <div className="text-sm font-bold">{data?.payment_transaction?.id}</div>
      </div>
      <div className="w-1/3 flex flex-col item-center p-4">
        <div className="text-gray-400 text-xs font-medium"> Total Amount:</div>
        <div className="text-sm font-bold">
          {formatToCurrency(data?.payment_transaction?.paid_amount)}
        </div>
      </div>
      <div className="w-1/3 flex flex-col item-center p-4">
        <div className="text-gray-400 text-xs font-medium">Payment Date:</div>
        <div className="text-sm font-bold overflow-hidden truncate">
          {data?.payment_transaction?.transaction_date
            ? moment(data?.payment_transaction?.transaction_date).format(
                "MM/DD/YYYY"
              )
            : "-"}
        </div>
      </div>
    </div>
  );
};
