import React from "react";

const Button = ({
  className = "px-2.5 py-1.5",
  variant = "",
  children,
  animate = false,
  ...rest
}) => {
  const renderButton = (variant) => {
    if (rest?.disabled) {
      return (
        <button
          {...{ ...rest }}
          className={`${className} inline-flex items-center border justify-center
          shadow-sm font-medium rounded  bg-gray-200 text-gray-400 cursor-not-allowed`}
        >
          {children}
        </button>
      );
    }
    switch (variant) {
      case "outline":
        return (
          <button
            {...{ ...rest }}
            className={`${className} inline-flex items-center border border-indigo-600 
            shadow-sm font-medium rounded 
            text-indigo-600 justify-center bg-white disabled:bg-opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            {children}
          </button>
        );
      case "primary-filled":
        return (
          <button
            {...{ ...rest }}
            className={`${className} inline-flex items-center border border-indigo-600 
            shadow-sm font-medium rounded 
            text-white justify-center bg-indigo-600 disabled:bg-opacity-50 disabled:cursor-not-allowed ${
              animate
                ? "transform hover:scale-105 duration-200"
                : "hover:bg-opacity-80"
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            {children}
          </button>
        );
      case "error-filled":
        return (
          <button
            {...{ ...rest }}
            className={`${className} inline-flex items-center border border-red-600 
              shadow-sm font-medium rounded 
              text-white justify-center bg-red-600 disabled:bg-opacity-50 disabled:cursor-not-allowed hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`}
          >
            {children}
          </button>
        );
      default:
        return (
          <button
            {...{ ...rest }}
            className={`${className} inline-flex items-center border border-indigo-600
              shadow-sm font-medium rounded 
              text-indigo-600 justify-center bg-white disabled:bg-opacity-50 disabled:cursor-not-allowed hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            {children}
          </button>
        );
    }
  };

  return renderButton(variant);
};

export default Button;
