import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  addAdminSchema,
  updateAdminSchema,
} from "../../../_helper/ValidationSchema";
import Button from "../../Common/Button/Button";
import FormFields from "../../Common/FormFields/FormFields";

const inputs = [
  {
    type: "text",
    name: "name",
    placeholder: "Enter the admin name ",
    label: "Name",
  },
  {
    type: "email",
    name: "email",
    placeholder: "Enter the admin email id",
    label: "Email",
  },
  {
    type: "maskField",
    name: "contact_number",
    placeholder: "Enter the admin contact number",
    label: "Contact Number",
    mask: "(999)-999-9999",
  },
  {
    type: "password",
    name: "password",
    placeholder: "Enter the admin password",
    label: "Password",
  },
];

const AddAdmin = ({ state }) => {
  const [fields, setFields] = useState([]);
  const onFormSubmit = (values) => {
    if (state?.data?.name) {
      state.callBack({ name: values?.name });
    } else {
      state.callBack(values);
    }
  };

  useEffect(() => {
    if (state?.data?.name) {
      setFields(inputs?.filter((el) => el?.name === "name"));
    } else {
      setFields(inputs);
    }
  }, [state?.data]);

  return (
    <div>
      <div className="text-center text-xl font-bold">
        {state?.data?.name ? "Edit Admin" : "New Admin"}
      </div>
      <div className="py-2">
        <Formik
          initialValues={{
            name: state?.data?.name || "",
            email: state?.data?.email || "",
            password: state?.data?.password || "",
            contact_number: state?.data?.contact_number || "",
          }}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={
            state?.data?.name ? updateAdminSchema : addAdminSchema
          }
          validateOnChange
        >
          {({ handleChange, errors, touched, values }) => (
            <Form className="h-full flex flex-col">
              <div className="flex flex-col space-y-4">
                {fields?.map((input, index) => (
                  <div key={index}>
                    <FormFields
                      {...{ ...input }}
                      value={values[input?.name]}
                      onChange={handleChange}
                      error={touched[input?.name] && errors[input?.name]}
                    />
                  </div>
                ))}
              </div>
              <div className="pt-8 flex flex-1 w-full items-end ">
                <Button
                  type="submit"
                  variant="primary-filled"
                  className="px-4 py-2 w-full font-bold"
                >
                  {state?.data?.name ? "Update Admin" : "Create Admin"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddAdmin;
