import React from "react";
import { Images } from "../../../assets/image_helper";
import { CheckIcon } from "@heroicons/react/solid";
import Button from "../../Common/Button/Button";
import LoginForm from "../../Internal/LoginForm";
import { useHistory } from "react-router";
import useUser from "../../../hooks/useUser";

const options = [
  {
    hasAccess: true,
    label: "HIPAA-HITECH compliant and 256 bit encrypted",
  },
  {
    hasAccess: true,
    label: "Multi-tier quality assurance",
  },
  {
    hasAccess: true,
    label: "Per case fees, No fixed costs",
  },
  {
    hasAccess: true,
    label: "Dedicated team for you",
  },
  {
    hasAccess: true,
    label: "No additional fees for rush cases",
  },
];

const Login = ({ role = "client" }) => {
  const history = useHistory();
  const { setToken } = useUser();
  console.log(role);

  /**
   * @description Function to set the login token after successfull login
   * @param {*} token
   */
  const onLoginSuccess = (token) => {
    setToken(token);
    history.push("/cases");
  };

  return (
    <div className="relative min-h-screen overflow-y-auto">
      <a href="https://medzlegal.co" target="_blank" rel="noreferrer">
        <img
          src={Images.medzStatic}
          alt="medz-logo"
          className="h-24 w-auto absolute top-4 left-5 z-50"
        />
      </a>
      <div
        className="absolute z-10 w-full h-full flex justify-center items-center bg-cover object-center p-4"
        style={{
          backgroundImage: `url(${Images.loginBg})`,
        }}
      >
        <div
          className={`p-5 shadow rounded ${
            role === "client" ? "max-w-screen-md" : "max-w-md"
          } w-full bg-white`}
        >
          {/* <--Logo image --> */}
          <div className="flex justify-center">
            <div className="w-full" style={{ maxWidth: 230 }}>
              <img src={Images.companyLogo} alt="company-logo" />
            </div>
          </div>
          <div className="py-4 flex space-x-4">
            {/* <!-- content --> */}
            {role === "client" && (
              <div className="flex flex-1 py-4">
                <div>
                  <div className="text-xl">
                    <span>Register now for</span>{" "}
                    <span className="text-green-800">Free</span>
                  </div>
                  <div className="flex flex-col space-y-3 py-2">
                    {options?.map((option, idx) => (
                      <div key={idx} className="flex items-center text-sm">
                        <div className="mr-0.5">
                          <CheckIcon className="h-4 text-green-900" />
                        </div>
                        <div>{option?.label}</div>
                      </div>
                    ))}
                  </div>
                  <div className="my-5">
                    <Button
                      variant="primary-filled"
                      className="px-4 py-2 w-full font-bold"
                      onClick={() => history.push("/signup")}
                    >
                      Create Your Account
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {/* <!-- Login form--> */}
            <div className="flex flex-1 pt-4">
              <LoginForm
                onForgetPassword={() => {
                  if (role === "client") {
                    history.push("/forgot-password");
                  } else {
                    history.push("/support/forgot-password");
                  }
                }}
                onLoginSuccess={onLoginSuccess}
                role={role}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
