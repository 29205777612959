import React, { useState } from "react";
import Button from "../../Common/Button/Button";
import FormFields from "../../Common/FormFields/FormFields";

const ReactModal = ({ state }) => {
  const [value, setValue] = useState("");
  return (
    <div>
      <div className="text-center text-xl font-bold">Add Estimation</div>
      <div className="py-2">
        <div className="flex flex-col space-y-4">
          <FormFields
            type="textArea"
            name="reject reason"
            value={value}
            label={"Reject Reason"}
            onChange={(e) => setValue(e?.target?.value)}
          />
        </div>
        <div className="pt-8 flex flex-1 w-full items-end ">
          <Button
            type="submit"
            variant="primary-filled"
            disabled={!value?.trim()}
            onClick={() => state.callBack(value?.trim())}
            className="px-4 py-2 w-full font-bold"
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReactModal;
