import React from "react";
import { useHistory } from "react-router";
import { Images } from "../../../assets/image_helper";
import SignupForm from "../../Internal/SignupForm";

const Signup = () => {
  const history = useHistory();
  return (
    <div className="relative min-h-screen overflow-y-auto">
      <div
        className="absolute z-10 w-full h-full flex justify-center items-center bg-cover object-center p-4"
        style={{
          backgroundImage: `url(${Images.loginBg})`,
        }}
      >
        <div className="p-5 shadow rounded max-w-md w-full bg-white overflow-auto max-h-full">
          {/* <--Logo image --> */}
          <div className="flex justify-center">
            <div className="w-full" style={{ maxWidth: 230 }}>
              <img src={Images.companyLogo} alt="company-logo" />
            </div>
          </div>
          <div className="py-4 flex space-x-4">
            {/* <!-- Login form--> */}
            <div className="flex flex-1 pt-4">
              <SignupForm
                onSuccess={() => history.push("/pending-email-verification")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
