import { useToastStateContext } from "../../../context/ToastContext";
import Toast from "./Toast";

export default function ToastContainer() {
  const { toasts } = useToastStateContext();
  return (
    <div className="absolute w-full top-4 right-10 z-50">
      {toasts &&
        toasts.map((toast) => (
          <Toast
            id={toast.id}
            key={toast.id}
            type={toast.type}
            message={toast.message}
          />
        ))}
    </div>
  );
}
