import React from "react";
import { useHistory } from "react-router";
import { Images } from "../../../assets/image_helper";
import useLoader from "../../../hooks/useLoader";
import { useToast } from "../../../hooks/useToast";
import { forgetPassword } from "../../../services/authServices";
import { getErrorMessage } from "../../../_helper/functions";
import ForgotPasswordForm from "../../Internal/ForgotPasswordForm";

const ForgotPassword = ({ role = "client" }) => {
  const history = useHistory();
  const { setLoader } = useLoader();
  const toast = useToast();

  const onSuccess = async (values) => {
    try {
      setLoader({ state: true, message: "Loading..." });
      const payload = {
        email: values.email,
        role,
      };
      const result = await forgetPassword(payload);
      if (result?.message) {
        toast("success", result?.message);
      }
    } catch (err) {
      const msg = getErrorMessage(err);
      toast("error", msg);
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <div className="relative min-h-screen overflow-y-auto">
      <div
        className="absolute z-10 w-full h-full flex justify-center items-center bg-cover object-center p-4"
        style={{
          backgroundImage: `url(${Images.loginBg})`,
        }}
      >
        <div className="p-5 shadow rounded max-w-md w-full bg-white">
          {/* <--Logo image --> */}
          <div className="flex justify-center">
            <div className="w-full" style={{ maxWidth: 230 }}>
              <img src={Images.companyLogo} alt="company-logo" />
            </div>
          </div>
          <div className="py-4 flex space-x-4">
            {/* <!-- Login form--> */}
            <div className="flex flex-1 pt-4">
              <ForgotPasswordForm
                onSuccess={onSuccess}
                onBacktoLogin={() => {
                  if (role === "client") {
                    history.push("/");
                  } else {
                    history.push("/support/login");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
