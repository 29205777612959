import API from "../api/manager";
import config from "../api/config";

const getSignedUrl = (path, file) => {
  const key = path ? `${path}/${file?.name}` : file?.name;
  return API({
    method: "POST",
    url: config.urls.attachement.signedUrl,
    data: {
      key,
    },
  });
};

export { getSignedUrl };
