import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce/lib";
import SearchInput from "../../Common/SearchInput/SearchInput";
import VerifyUserList from "../../Internal/VerifyUserList";
import queryString from "query-string";
import { getUsers, verifyAccount } from "../../../services/userServices";
import NoResult from "../../Common/NoResult";
import Pagination from "../../Common/Pagination";
import { useToast } from "../../../hooks/useToast";
import useLoader from "../../../hooks/useLoader";
import { getErrorMessage } from "../../../_helper/functions";

const VerifyUser = () => {
  const [users, setUser] = useState([]);
  const [currentpage, setCurrentPage] = useState(1);
  const [pageDetails, setPageDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const [text] = useDebounce(value, 1000);
  const toast = useToast();
  const { setLoader } = useLoader();

  useEffect(() => {
    getAllUnverifiedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentpage, text]);

  async function getAllUnverifiedUser() {
    setLoading(true);
    try {
      let params = {
        page: currentpage,
        per_page: 10,
      };
      if (text) {
        params.term = text;
      }
      const stringParams = `?${queryString.stringify(params)}`;
      const result = await getUsers(stringParams);
      if (result?.response && result?.response?.users?.length) {
        setUser(result?.response?.users);
      }
      if (result?.response?.pagination_details) {
        setPageDetails(result?.response?.pagination_details);
      }
    } catch (err) {
      console.log(err);
      setUser([]);
      setPageDetails({});
    } finally {
      setLoading(false);
    }
  }

  const performAction = async (data, status) => {
    try {
      setLoader({ state: true, message: "Perform Action" });
      const payload = {
        user_id: data?.id,
        email: data?.email,
        action_required: status,
      };
      const result = await verifyAccount(payload);
      if (result) {
        toast("success", result?.message);
        getAllUnverifiedUser();
      }
    } catch (err) {
      const message = getErrorMessage(err);
      toast("error", message);
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <div>
      <Pagination
        pagination={{
          currentpage: currentpage,
          totalPage: pageDetails?.total_pages,
          handlePageClick: ({ selected }) => {
            setCurrentPage(selected + 1);
          },
        }}
      >
        {/* actiotn bar */}
        <div className="flex justify-between space-x-4 items-center">
          <div className="text-2xl font-bold uppercase">Verify Users</div>
          <div className="flex flex-1 justify-end">
            <SearchInput {...{ value, setValue }} />
          </div>
        </div>
        <div className="mt-5 flex flex-col space-y-4">
          {loading ? (
            <>
              {[1, 2, 3, 4, 5, 6]?.map((el, index) => (
                <div key={index}>
                  <VerifyUserList loading={true} />
                </div>
              ))}
            </>
          ) : (
            <>
              <>
                {users?.length ? (
                  <>
                    {users?.map((el, index) => (
                      <div key={index}>
                        <VerifyUserList
                          data={el}
                          onApprove={(status) => performAction(el, status)}
                          onReject={() => performAction(el, "reject_account")}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="flex justify-center my-10">
                    <div className="max-w-xl w-full">
                      <NoResult message="No Users Found!" />
                    </div>
                  </div>
                )}
              </>
            </>
          )}
        </div>
      </Pagination>
    </div>
  );
};

export default VerifyUser;
